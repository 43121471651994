import { NgModule } from '@angular/core';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { BatchExpiredDatePipe } from './batch-expired-date/batch-expired-date.pipe';
import { SubmissionDatePipe } from './submission-date/submission-date.pipe';


@NgModule({
  declarations: [BatchExpiredDatePipe, SubmissionDatePipe],
  imports: [
    CommonModule
  ],
  exports: [BatchExpiredDatePipe, SubmissionDatePipe],
  providers: [UpperCasePipe, SubmissionDatePipe]
})
export class PipesModule { }
