import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import { globalConfig } from '../globalConfig';
import { EmailService } from '../shared/services/email.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit {
  form: FormGroup;
  passwordInput = Array.from({ length: 6 }, () => null);
  validEmail: boolean | null = null;
  userEmail: string;
  regSuccess = false;
  submitLoad = false; /* Submit btn loading */
  loadSendData = false; /* Loading icon after otp enter */

  @ViewChild('otherInput', { static: false }) otherInput: ElementRef;
  @ViewChild('emailVerificationModal', { static: true })
  emailModal: TemplateRef<any>;

  @ViewChildren('radioBtn', { read: ElementRef })
  radioBtns: QueryList<ElementRef>;

  get todayDate() {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  get isExperienceInAnalysisValid() {
    if (
      this.form.get('experienceInAnalysis.months').touched &&
      this.form.get('experienceInAnalysis.years').touched
    ) {
      return (
        this.form.get('experienceInAnalysis.months').valid &&
        this.form.get('experienceInAnalysis.years').valid
      );
    }
    return true;
  }

  constructor(
    private renderer: Renderer2,
    private afs: AngularFirestore,
    private router: Router,
    private gcf: globalConfig,
    private emailService: EmailService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.form = new FormGroup(
      {
        facilityName: new FormControl('', Validators.required),
        shippingAddress: new FormControl('', Validators.required),
        facilityPhoneNo: new FormControl('', Validators.required),
        testingMethod: new FormControl('', Validators.required),
        systemNameForAutomated: new FormControl(''),
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
        ]),
        registeredFacility: new FormControl('', Validators.required),
        experienceInAnalysis: new FormGroup({
          months: new FormControl('', Validators.required),
          years: new FormControl('', Validators.required),
        }),
        trainedProfessionals: new FormControl('', Validators.required),
        performRegularQcCheck: new FormControl('', Validators.required),
        qcCheckInterval: new FormControl('', Validators.required),
        facilityGSTNumber: new FormControl('', Validators.required),
        transactionId: new FormControl('', Validators.required),
        facilitySignature: new FormControl('', Validators.required),
        submissionDate: new FormControl('', Validators.required),
      },
      { updateOn: 'submit' }
    );
  }

  inputValueChange() {
    this.radioBtns.forEach((eachRadio) => {
      this.renderer.setProperty(eachRadio.nativeElement, 'checked', false);
    });
  }

  radioValueChange() {
    this.renderer.setProperty(this.otherInput.nativeElement, 'value', '');
  }

  onlyNumberInput(event: KeyboardEvent) {
    const key = event.key;
    return (
      /[0-9]/.test(key) ||
      key === 'Backspace' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight'
    );
  }

  isControlValid(controlName: string) {
    if (this.form.get(controlName).touched) {
      return this.form.get(controlName).valid;
    }
    return true;
  }

  onDateFocus(element: Element) {
    this.renderer.setProperty(element, 'type', 'date');
  }

  onDateFocusOut(element: Element) {
    if ((element as HTMLInputElement).value === '') {
      this.renderer.setProperty(element, 'type', 'text');
    }
  }

  closeModal() {
    this.modalService.dismissAll();

    if (this.regSuccess) {
      this.router.navigate(['/']);
    }
  }

  onlyNumber(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'Backspace' || key === 'ArrowRight' || key === 'ArrowLeft')
      return true;
    return /[0-9]/.test(key);
  }

  otpKeyUp(event: KeyboardEvent, index: number) {
    // Return if uploading user data and sending reg success email...
    if (this.loadSendData) return;
    this.validEmail = null;

    const inputNodes = (
      event.target as HTMLInputElement
    ).parentElement.querySelectorAll('.otp-input');
    const value = (event.target as HTMLInputElement).value;
    if (event.key === 'Backspace') {
      // Reset back to null value in password input array if some value is deleted...
      if (value.length === 0) this.passwordInput[index] = null;
      // Focus input fields backwards...
      if (value.length === 0 && index > 0) {
        (inputNodes[index - 1] as HTMLInputElement).focus();
      }
    } else {
      this.passwordInput[index] = value;
      // If all the input numbers are entered then check for password match...
      if (!this.passwordInput.includes(null)) {
        this.validEmail = this.emailService.checkPassword(
          this.passwordInput.join('')
        );

        // If the password is correct than save the registration data and show the
        // success message...
        if (this.validEmail) {
          this.loadSendData = true;
          this.submitUserData()
            .then((_) => {
              const labName = this.form.get('facilityName').value;
              const regDate = moment(
                this.form.get('submissionDate').value,
                'YYYY-MM-DD'
              ).format('DD/MM/YYYY');

              // Send reg success mail...
              return this.emailService
                .sendMail({
                  emailto: this.userEmail,
                  labName,
                  regDate,
                  type: 'regComplete',
                })
                .toPromise();
            })
            .then((_) => {
              this.loadSendData = false;
              this.regSuccess = true;
            })
            .catch((error) => {
              console.log(error);
              this.loadSendData = false;
            });
        }
      }
      // Focus input fields forwards...
      if (value.length === 1 && index < 5) {
        (inputNodes[index + 1] as HTMLInputElement).focus();
      }
    }
  }

  sendAgainVerificationMail() {
    this.emailService
      .sendMail({ emailto: this.userEmail, type: 'verification' })
      .subscribe();
  }

  onSubmit() {
    // Changed the state to touched in all formControls so the orange
    // border validation runs...
    this.form.markAllAsTouched();

    // If form not valid than return...
    if (!this.form.valid) return;

    this.submitLoad = true;

    this.userEmail = this.form.get('email').value as string;
    this.emailService
      .sendMail({ emailto: this.userEmail, type: 'verification' })
      .subscribe(
        (_) => {
          this.submitLoad = false;
          this.loadSendData = false;
          this.passwordInput = Array.from({ length: 6 }, () => null);
          this.validEmail = null;
          this.regSuccess = false;

          this.modalService.open(this.emailModal, {
            backdrop: 'static',
            centered: true,
          });
        },
        (error) => {
          this.submitLoad = false;
          console.log(error);
        }
      );
  }

  async submitUserData() {
    // Changing few data before saving(basically coverting to boolean and date)...
    const dataToBeSaved = { ...this.form.value };
    if (dataToBeSaved.registeredFacility === 'false') {
      dataToBeSaved.registeredFacility = false;
    } else if (dataToBeSaved.registeredFacility === 'true') {
      dataToBeSaved.registeredFacility = true;
    }

    if (dataToBeSaved.performRegularQcCheck === 'false') {
      dataToBeSaved.performRegularQcCheck = false;
    } else if (dataToBeSaved.performRegularQcCheck === 'true') {
      dataToBeSaved.performRegularQcCheck = true;
    }

    dataToBeSaved.experienceInAnalysis.months =
      +dataToBeSaved.experienceInAnalysis.months;
    dataToBeSaved.experienceInAnalysis.years =
      +dataToBeSaved.experienceInAnalysis.years;
    dataToBeSaved.trainedProfessionals = +dataToBeSaved.trainedProfessionals;

    dataToBeSaved.submissionDate = moment(
      dataToBeSaved.submissionDate,
      'YYYY-MM-DD'
    ).toDate();
    dataToBeSaved.status = 'pending';
    // dataToBeSaved.role = 2;

    // Adding timeStamp...
    dataToBeSaved.createdAt = this.gcf.timestamp;

    // Delete the transaction id(Will be saved separately)...
    // const transactionId = dataToBeSaved.transactionId;
    // delete dataToBeSaved.transactionId;

    console.log(dataToBeSaved);
    // Save to database...
    const result = await this.afs
      .collection('Registrations')
      .add(dataToBeSaved);
    console.log(result);
    // const userId = result.id;
    // await this.afs.collection('Transactions').add({
    //   userID: userId,
    //   transactionId,
    //   status: 'pending',
    //   createdAt: this.gcf.timestamp,
    // });
  }
}
