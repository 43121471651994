import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { testLevelTestNameEnum } from 'src/app/shared/interface/global.interface';
import { globalConfig } from '../../../globalConfig';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  isSideNav: boolean = true;
  showCollapsedSidebar = false;
  sidebarSizeObs$ = new Subject<string>();

  @ViewChildren('sidebarText', {read: ElementRef}) sidebarText: QueryList<ElementRef>;

  navItems: string[] = [
    "iPRO Introduction",
    "Motility Challenge",
    "Morphology Challenge 1",
    "Morphology Challenge 2",
    "Concentration Challenge",
    "Review / Submit Results"
  ]

  constructor(
    private _router: Router,
    private _gCf: globalConfig,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // Initial page load should the sidebar show as opened or closed...
    if (window.innerWidth < 1100) {
      this.isSideNav = false;
      this.showCollapsedSidebar = true;
      document.body.classList.add('sidebar-closed');
      document.body.classList.add('content-expand');
    }

    // Listening to window resize to close or show the sidebar...
    window.onresize = (event) => {
      const browserWidth = window.innerWidth;
      if (browserWidth < 1100) {
        if (!this.showCollapsedSidebar) {
          this.showCollapsedSidebar = true;
          this.sidebarSizeObs$.next('min');
        }
      } else {
        if (this.showCollapsedSidebar) {
          this.showCollapsedSidebar = false;
          this.sidebarSizeObs$.next('max');
        }
      }
    }

    this.sidebarSizeObs$.subscribe(result => {
      if (result && result === 'min') {
        this.isSideNav = false;
        document.body.classList.add('sidebar-closed');
        document.body.classList.add('content-expand');
      }

      if (result && result === 'max') {
        this.isSideNav = true;

        // Related to showing the sidebar text with animation...
        document.body.classList.remove('sidebar-closed');
        document.body.classList.remove('content-expand');
      }
    })
  }

  setsideNav(){
    this.isSideNav = !this.isSideNav;
    if (!this.isSideNav) {
      document.body.classList.add('sidebar-closed');
    } else {
      document.body.classList.remove('sidebar-closed');
    }
  }

  get getsideNav(){
    return this.isSideNav;
  }


  stepperClass(num: number){

    // console.log("-----this._gCf.gettestLevel-----", this._gCf.gettestLevel);
    // console.log("-----this._gCf.getcurrentTest-------", this._gCf.getcurrentTest);
    // console.log("-----num-----------", num);
    // console.log(testLevelTestNameEnum["0"+num]);
    // console.log(this._gCf.getskippedTestLevels); 
    
    if(!this._gCf.getisUserExistinCurrentBatch || this._gCf.getisBatchExpired){
      return "disabled-stepper";
    }else{

      switch(true){
        case num == this._gCf.getcurrentTest:
          return "active-stepper";
        case num <= this._gCf.gettestLevel:
          if(this._gCf.getskippedTestLevels.includes(testLevelTestNameEnum["0"+num])) return "skipped-stepper";
          return "completed-stepper";
        default: 
        return "disabled-stepper";
      }

    }

  }

  stepperNavigation(num){

    if(num <= this._gCf.gettestLevel){
      
      this._gCf.setcurrentTest = num;

      switch(this._gCf.getcurrentTest){
        case 1:
          this._router.navigate(['/proficiency/intro']);
          break;

        case 2:
          this._router.navigate(['/proficiency/motility']);
          break;

        case 3:
          this._router.navigate(['/proficiency/morphology-challenge/1']);
          break;

        case 4:
          this._router.navigate(['/proficiency/morphology-challenge/2']);
          break;

        case 5:
          this._router.navigate(['/proficiency/concentration']);
          break;

        case 6:
          this._router.navigate(['/proficiency/finish']);
          break;

        default:
          this._router.navigate(['/proficiency/intro']);
      }

    }
    
  }

}
