import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { environment } from '../../environments/environment';
// import { GlobalUser } from '../../environments/environment';
// import { NgClass } from '@angular/common';
import { globalConfig } from '../globalConfig';
import { Router } from '@angular/router';
import { version } from 'package.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('splashVideo', { static: false }) splashVideo: ElementRef;
  // data2: string = GlobalUser.UserName;
  currentVersion: string = "";

  isVideoEnded: boolean = false;

  constructor(
    public gcf:globalConfig,
    private _router: Router,
  ) { 
    this.currentVersion = version;
  }

  ngOnInit(): void{
    document.body.className =  "home";
  }

  ngAfterViewInit(): void{
    this.playSplashVideo();
    this.videoEndedEvent();
  }

  playSplashVideo(): void{
    const video = this.splashVideo.nativeElement as HTMLVideoElement;
    video.muted = true;
    video.oncanplay = () => video.play();
  }

  videoEndedEvent(): void{
    this.splashVideo.nativeElement.onended = () => {
      this.isVideoEnded = true;
      setTimeout(() => {
        this.gcf.setisSplashVideoShown = true;  
      }, 1900);
      
    };
  }

  getStarted(): void{
    if(this.gcf.currentUser){
      this._router.navigate(['/proficiency/intro']);
    } else {
      this._router.navigate(['/signin']);
    }
  }

}
