import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidateMotilityInputsService {

  constructor() { } 

  $triggerMotilityInputsValid = new Subject();
  $areMotilityInputsValid = new Subject();

  triggerValidateMotlityInputs() {
    this.$triggerMotilityInputsValid.next();
  }

  observeTrigger() {
    return this.$triggerMotilityInputsValid.asObservable();
  }

  validateMotlityInputs(data: boolean) {
    this.$areMotilityInputsValid.next(data);
  }

  observeInput() {
    return this.$areMotilityInputsValid.asObservable();
  }

}
