import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'submissionDatePipe'
})
export class SubmissionDatePipe implements PipeTransform {

  monthName: string[] = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];

  transform(value: any, ...args: any[]): any {
    
    if(!value) return null;

    const d: Date = value.toDate();

    let date = this.isSmall(d.getDate());
    let month = this.isSmall(d.getMonth());
    let year = this.isSmall(d.getFullYear());

    return `${this.monthName[month]} ${date}, ${year}`;

  }

  isSmall(num: any){
    if(num < 0) num = "0"+num;
    return num
  }

}
