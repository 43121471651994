import { Input } from '@angular/core';
import {Injectable } from '@angular/core';

@Injectable()
export class globalAlert {

  @Input()
  public alerts: Array<IAlert> = [];

  resetAlerts = () => this.alerts = [];

  public addSuccess(msg: string,action: string) {
    this.resetAlerts();
    this.alerts.push({
      type: 'success',
      message: msg,
      head:'Success! ',
      action: action
    });
  }

  // public addInfo(msg: string, alert: IAlert) {
  //   this.alerts.push({
  //     type: 'info',
  //     message: msg
  //   });
  // }

  // public addWarning(msg: string, alert: IAlert) {
  //   this.alerts.push({
  //     type: 'warning',
  //     message: msg
  //   });
  // }

  public addDanger(msg: string,action: string) {
    this.resetAlerts();
    this.alerts.push({
      type: 'danger',
      message: msg,
      head:'Error! ',
      action: action
    });
  }

  close(alert: any) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

}

interface IAlert {
  type: string;
  message: string;
  head : string;
  action: string;
}