

export class MotilityCounting {

  id?: string;
  data: {
    totalSpermCount: number;
    motileCount: number;
    immotileCount: number;
    nonProgCount: number;
    slowProgCount: number;
    rapidProgCount: number;
    replicateNumber: number;
    videoID: string;
    videoIndex: number;

    progressiveCount: number;
  }

  constructor(data) {

    this.id = data.videoID,
    this.data = {
      totalSpermCount : data.totalSpermCount,
      motileCount : data.motileCount,
      immotileCount : data.immotileCount,
      nonProgCount : data.nonProgCount,
      slowProgCount : data.slowProgCount,
      rapidProgCount : data.rapidProgCount,
      replicateNumber : data.replicateNumber,
      videoID : data.videoID,
      videoIndex: data.videoIndex,
      progressiveCount : data.progressiveCount,
    }

  }
  
  get getnewOrReset(){
    return this;
  }


}



export class MotilityCountingArray{

  dataArray = [];

  constructor() {}

  addNewData(data){
    const whoData = new MotilityCounting(data);
    
    let findIndex = this.dataArray.findIndex(val => val.id == data.videoID);
    
    if(findIndex === -1){
      this.dataArray.push(
        whoData
      );      
    } else {
      this.dataArray[findIndex] = whoData;
    }
    
  }

  get getdataArray(){
    return this.dataArray;
  }

  set setdataArray(data){

    if(data.length > 0){
      data.forEach(val => {
        this.addNewData({"id": val.id, ...val.data});
      });
    } else {

      this.dataArray = [];
    }

  }

  getCurrentVideoData(index: number){
    return this.dataArray[index];
  }


  totalSpermCounted(): number{

    const totalSpermCount = this.getdataArray.reduce( (total, val) =>  total+val.data["totalSpermCount"], 0);
    return totalSpermCount;

    // const accumulator = {
    //   totalSpermCount: 0,
    //   immotileCount: 0,
    //   nonProgCount: 0,
    //   slowProgCount: 0
    // };

    // const res = this.getdataArray.reduce(
    //   ({totalSpermCount, immotileCount, nonProgCount, slowProgCount}, val) => {
    //     return {
    //       totalSpermCount : totalSpermCount+val.data["totalSpermCount"],
    //       immotileCount : immotileCount+val.data["immotileCount"],
    //       nonProgCount : nonProgCount+val.data["nonProgCount"],
    //       slowProgCount : slowProgCount+val.data["slowProgCount"],
    //     }
    // }, accumulator);

    // return res.totalSpermCount + res.immotileCount + res.nonProgCount + res.slowProgCount;

    // return this.dataArray[index] ? this.dataArray[index].data.totalSpermCount : 0;
  }



  // totalCount(key) {
  //   const res = this.getdataArray.reduce(
  //     (count, val) => {
  //       return count += val.data[key]
  //   }, 0);

  //   return res;
  // }



  /********************************************************************* */


  //totalSpermCount
  gettotalSpermCount(index) {
    return this.dataArray[index] ? this.dataArray[index].data.totalSpermCount : 0;
  }

  //motileCount
  getmotileCount(index) {
    return this.dataArray[index] ? this.dataArray[index].data.motileCount : 0;
  }

  //immotileCount
  getimmotileCount(index) {
    return this.dataArray[index] ? this.dataArray[index].data.immotileCount : 0;
  }

  //nonProgCount
  getnonProgCount(index) {
    return this.dataArray[index] ? this.dataArray[index].data.nonProgCount : 0;
  }

  //slowProgCount
  getslowProgCount(index) {
    return this.dataArray[index] ? this.dataArray[index].data.slowProgCount : 0;
  }

  //slowProgCount
  getrapidProgCount(index) {
    return this.dataArray[index] ? this.dataArray[index].data.slowProgCount : 0;
  }


  getReplicatetotalCount(replicateNumber: number, key: string): number{
    
    // return new Promise((res, rej) => {
      let count = this.dataArray.filter( val => val.data.replicateNumber == replicateNumber).reduce(
        (total, val) => { 
          return total + val.data[key];
      },0);

      return count;
    // });
  }


  getreplicatePercentage(replicateNumber, key){
    const count =  this.getReplicatetotalCount(replicateNumber, key);
    const total =  this.getReplicatetotalCount(replicateNumber, "totalSpermCount");
    if(count != 0) return Math.round((count/total) * 100);
    return 0;
  }

  setCounts(index, key, count){
    this.dataArray[index].data[key] = count;
  }

  resetData(data){
    this.dataArray = [];
    this.addNewData(data);
  }

  clearAll(index, data){
    this.dataArray[index] = new MotilityCounting(data);
  }

  wholeNumber(num: number){
    let num1 = num.toString();
    if(num1.includes(".")){
      return Number(num1.split(".")[0]);
    }else{
      return num;
    }
  }

  //For Popup
  get getReenterResultsWHO46(): Boolean{

    let counts = this.getdataArray.filter((val) => {
      return (val.data["totalSpermCount"] < (val.data["immotileCount"] + val.data["nonProgCount"] + val.data["slowProgCount"]))
    });

    return counts.length > 0;

  }

  get getReenterResultsWHO5(): Boolean{

    let counts = this.getdataArray.filter((val) => {
      return (val.data["totalSpermCount"] < (val.data["immotileCount"] + val.data["nonProgCount"]))
    });

    return counts.length > 0;

  }

}