import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'batchExpiredDatePipe'
})
export class BatchExpiredDatePipe implements PipeTransform {

  constructor(
    private upperCasePipe: UpperCasePipe
  ){

  }

  monthName: string[] = [
    "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"
  ];

  transform(value: any, ...args: any[]): any {
    
    if(!value) return null;

    const d: Date = value.toDate();

    let date = this.isSmall(d.getDate());
    let month = this.isSmall(d.getMonth());
    let year = this.isSmall(d.getFullYear());

    return `${date}-${this.upperCasePipe.transform(this.monthName[month])}-${year}`

  }

  isSmall(num: any){
    if(num < 0) num = "0"+num;
    return num
  }

}
