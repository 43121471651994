import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { globalConfig } from 'src/app/globalConfig';
import { PopupService } from 'src/app/shared/components/popup/popup.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { MotilityCountingArray } from '../modalClass/motility-counting.model';

import pdfMake from "pdfmake/build/pdfmake";  
// import pdfFonts from "pdfmake/build/vfs_fonts"; 

import pdfFonts from "../../../assets/fonts/cutomfont.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs; 

import { SubmissionDatePipe } from 'src/app/shared/pipes/submission-date/submission-date.pipe';
import { ConcentrationTest, MorphologyUserTestData, MotilityUserTestData, UserInfo, UserTestInfo } from 'src/app/shared/interface/global.interface';
import { EmailService } from 'src/app/shared/services/email.service';
import { EmailInput } from 'src/app/shared/interface/email.interface';


// interface MotilityCount{  
//   motilityCount : number,
//   progressiveCount: number,
//   slowProgressiveCount: number,
//   rapidProgressiveCount: number,
//   nonProgressiveCount: number,
//   immotileCount: number
// }

interface MotilityPercentage{  
  motilityPercentage : number,
  progressivePercentage?: number,
  slowProgressivePercentage?: number,
  rapidProgressivePercentage?: number,
  nonProgressivePercentage: number,
  immotilePercentage: number
}


interface MorphologyCount{  
  normalCount : number,
  abnormalCount: number
}

interface MorphologyPercentage{  
  normalPercentage : number,
  abnormalPercentage: number
}


@Component({
  selector: 'app-final-results',
  templateUrl: './final-results.component.html',
  styleUrls: ['./final-results.component.scss']
})
export class FinalResultsComponent implements OnInit {

  motilityCountingData = new MotilityCountingArray();

  morphology = { 
    "pap": "Papanicolaou",
    "diff": "Diff-Quik",
    "pre": "Prestained Slide"
  };

  userTest: UserTestInfo;
  userInfo: UserInfo;
  concentrationTest: ConcentrationTest;

  motilityPercentage: MotilityPercentage = {
    motilityPercentage: 0,
    progressivePercentage: 0,
    slowProgressivePercentage: 0,
    rapidProgressivePercentage: 0,
    nonProgressivePercentage: 0,
    immotilePercentage: 0,
  }

  motilityReplicateIndex: number;

  morphologyPercentage_1: MorphologyPercentage = {
    "normalPercentage": 0,
    "abnormalPercentage": 0
  };
  
  morphologyPercentage_2: MorphologyPercentage = {
    "normalPercentage": 0,
    "abnormalPercentage": 0
  };

  observeCloseModal: Observable<any>| undefined;
  modalSubscribe: Subscription;

  constructor(
    public _gCf: globalConfig,
    private _httpService: HttpService,
    private popupService: PopupService,
    private _commonService: CommonService,
    private _emailService: EmailService,
    private router: Router,
    private submissionDatePipe: SubmissionDatePipe
  ) { 

    this.observeCloseModal = this.popupService.observeCloseModal();
  }

  async ngOnInit(): Promise<void>{

    this._gCf.spinner(false);

    this._commonService.updateTestLevel(6);
    let req = {
      "testID": this._gCf.gettestID
    };
    this.userTest  = await this._httpService.getUserTestDataByTestID(req);

    this.userInfo = await this._httpService.getUserInfo(this._gCf.currentUser.id.toString());
    
    let promiseArray = [];
    
    if(!this.userTest.data.skippedTestLevels.includes("motilityTest")){
      const motilityTest = this.getMotilityTest();
      promiseArray.push(motilityTest)
    }
    
    if(!this.userTest.data.skippedTestLevels.includes("morphologyTest1")){
      const morphologyTest1 = this.getMorphologyTest1();
      promiseArray.push(morphologyTest1)
    }

    if(!this.userTest.data.skippedTestLevels.includes("morphologyTest2")){
      const morphologyTest2 = this.getMorphologyTest2();
      promiseArray.push(morphologyTest2)
    }

    Promise.all(promiseArray).then(async (res: [any]) => {
      
      let newRes = {};
      res.forEach( val => {
        Object.assign(newRes, val);
      });      
      
      if(newRes["motilityTest"]){
        const motilityRes: MotilityUserTestData[] = newRes["motilityTest"];
        
        if(motilityRes && motilityRes.length > 0){
          const data = motilityRes.sort((a,b) => (a.data.videoIndex > b.data.videoIndex) ? 1 : ((b.data.videoIndex > a.data.videoIndex) ? -1 : 0));
          this.motilityCountingData.setdataArray = data;
          this.motilityReplicateIndex = data.findIndex(val => val.data.replicateNumber == 2);
  
          this.motilityFinalResultsCalc();
  
        }

      }

      if(newRes["morphologyTest1"]){
        this.morphologyPercentage_1 = await this.morphologyCalculation(newRes["morphologyTest1"]);
      }


      if(newRes["morphologyTest2"]){
        this.morphologyPercentage_2 = await this.morphologyCalculation(newRes["morphologyTest2"]);
      }
      
      this.concentrationTest = this.userTest.data.concentrationTest;

    });
    

  }

  getMotilityTest(){
    return new Promise(async (resolve, reject) => {
      const res = await this._httpService.getUsetMotilityTest(this._gCf.gettestID,undefined, "motilityTest");
      resolve({"motilityTest": res});
    });
  }


  getMorphologyTest1(){
    return new Promise(async (resolve, reject) => {
      const res = await this._httpService.getUserMorphologyTestData(this._gCf.gettestID, undefined, "morphologyTest1");
      resolve({"morphologyTest1": res});
    });
  }


  getMorphologyTest2(){
    return new Promise(async (resolve, reject) => {
      const res = await this._httpService.getUserMorphologyTestData(this._gCf.gettestID, undefined, "morphologyTest2");
      resolve({"morphologyTest2": res});
    });
  }
  
  
  morphologyCalculation(data) : Promise<MorphologyPercentage>{

    return new Promise(async (resolve, reject) => {

      const morphologyTest1: MorphologyUserTestData[] = data;

      // console.log(morphologyTest1);

      if(morphologyTest1 && morphologyTest1.length > 0){

        let morphologyTest1Replicate = morphologyTest1.findIndex(val => val.data.replicateNum == 2);

        let count1: MorphologyCount = await this.morphologyCounting(morphologyTest1, 1);
        
        const morphologyPercentage1 = this.morphologyPercentageCalculation(count1);

        if(morphologyTest1Replicate != -1){
          let count2: MorphologyCount = await this.morphologyCounting(morphologyTest1, 2);
          
          const morphologyPercentage2 = this.morphologyPercentageCalculation(count2);
          
          const p1 = this.percentageRoundOf(morphologyPercentage1.normalPercentage , morphologyPercentage2.normalPercentage);
          // const p2 = this.percentageRoundOf(morphologyPercentage1.abnormalPercentage , morphologyPercentage2.abnormalPercentage);

          resolve({
            "normalPercentage": p1,
            "abnormalPercentage": Math.round((1 - (p1/100)) * 100),
          });

        }else{

          const p1 = this.percentageRoundOf(morphologyPercentage1.normalPercentage);
          // const p2 = this.percentageRoundOf(morphologyPercentage1.abnormalPercentage);

          resolve({
            "normalPercentage": p1,
            "abnormalPercentage": Math.round((1 - (p1/100)) * 100),
          });

        }

      }

    });

  }


  skippedTestDesign(testName: string){
    if(this.userTest){
      if(this.userTest.data.skippedTestLevels.includes(testName)){
        return "incomplete-test";
      }
      return "";
    }
    return "";
  }

  get isAnySkippedTest(){
    if(this.userTest){
      if(this.userTest.data.skippedTestLevels.length > 0){
        return true;
      }
      return false;
    }
    return true;
  }

  percentageRoundOf(pA, pB?){

    if(pB == undefined){
      return Math.round(pA * 100);
    }
    return Math.round(((pA+pB)/2) * 100);
  }

  morphologyCounting(morphologyTest, replicateNum): Promise<MorphologyCount>{

    return new Promise((resolve, reject) => {

      let count = morphologyTest.filter( val => val.data.replicateNum == replicateNum )
        .reduce( ({normalCount, abnormalCount}, value) => {
          return {
            normalCount : normalCount+value.data["normalCount"],
            abnormalCount : abnormalCount+value.data["abnormalCount"],
          };
      },{normalCount : 0, abnormalCount: 0});

      resolve(count);
    });
  }


  morphologyPercentageCalculation(morphologyCount: MorphologyCount): MorphologyPercentage{

    let totalCount = (morphologyCount.normalCount + morphologyCount.abnormalCount);

    let percentage1 = morphologyCount.normalCount/ totalCount || 0 ;

    let percentage2 = morphologyCount.abnormalCount/ totalCount || 0;


    return {
      "normalPercentage": percentage1,
      "abnormalPercentage": percentage2,
    };

  }



  //Percentage
  getReplicatemotilePercentage(replicateNumber){
    if(this.getReplicateimmotilePercentage(replicateNumber) != 0){
      return 100 - this.getReplicateimmotilePercentage(replicateNumber);
    }
    return 100;
  }

  getReplicaterapidProgPercentage(replicateNumber){
    return this.getReplicatemotilePercentage(replicateNumber) - ( this.getReplicatenonProgPercentage(replicateNumber) + this.getReplicateslowProgPercentage(replicateNumber));
  }

  getReplicateslowProgPercentage(replicateNumber){
    return this.motilityCountingData.getreplicatePercentage(replicateNumber, "slowProgCount");
  }

  getReplicatenonProgPercentage(replicateNumber){
    return this.motilityCountingData.getreplicatePercentage(replicateNumber, "nonProgCount");
  }

  getReplicateimmotilePercentage(replicateNumber){
    return this.motilityCountingData.getreplicatePercentage(replicateNumber, "immotileCount");
  }

  getReplicateprogPercentage(replicateNumber){
    return this.getReplicatemotilePercentage(replicateNumber) - this.getReplicatenonProgPercentage(replicateNumber);
  }


  get isreplicateAdded(): boolean{
    return this.motilityReplicateIndex === -1 ? false : true;
  }

  get getWhoCtriteriaType(): string{
    if(this._gCf.testConfiguration.whoCriteria == "WHO 4" || this._gCf.testConfiguration.whoCriteria == "WHO 6"){
      return "who46"
    }
    return "who5";
  }

  motilityFinalResultsCalc(){

    let listsdata: MotilityPercentage | undefined;

    // console.log("----motilityFinalResultsCalc----");
    

    let functions = [
      this.getReplicatemotilePercentage(1),
      this.getReplicateprogPercentage(1),
      this.getReplicatenonProgPercentage(1),

      this.getReplicateslowProgPercentage(1),
      this.getReplicaterapidProgPercentage(1),

      this.getReplicateimmotilePercentage(1),
    ];

    if(this.isreplicateAdded){
      functions.push(

        this.getReplicatemotilePercentage(2),
        this.getReplicateprogPercentage(2),
        this.getReplicatenonProgPercentage(2),

        this.getReplicateslowProgPercentage(2),
        this.getReplicaterapidProgPercentage(2),

        this.getReplicateimmotilePercentage(2),

      )
    }

    Promise.all(functions).then( (res: any) => {

      // console.log(res);
      

      if(!this.isreplicateAdded){

        if(this.getWhoCtriteriaType == "who46"){

          this.motilityPercentage = {
            motilityPercentage: res[0],
            nonProgressivePercentage: res[2],
            slowProgressivePercentage: res[3],
            rapidProgressivePercentage: res[4],
            immotilePercentage: res[5]
          };
    
        }else if(this.getWhoCtriteriaType == "who5"){
          
          this.motilityPercentage = {
            motilityPercentage: res[0],
            progressivePercentage: res[1],
            nonProgressivePercentage: res[2],
            immotilePercentage: res[5]
          }
        }

      } else if(this.isreplicateAdded){      

        if(this.getWhoCtriteriaType == "who46"){

          let avgslowProgressivePercentage = Math.round((res[3]+res[9])/2); //Average Slow Progressive Percentage
          let avgnonProgressivePercentage = Math.round((res[2]+res[8])/2); //Average Non Progressive Percentage

          let immotilePercentage = this.wholeNumber((res[5]+res[11])/2); // Average immotile percentage

          // if((immotilePercentage + avgslowProgressivePercentage + avgnonProgressivePercentage) > 1){
          //   immotilePercentage = 1 - avgslowProgressivePercentage - avgnonProgressivePercentage;
          // }

          let motiliePercentage = this.wholeNumber(100 - immotilePercentage); //100 - immotilePercentage
          
          let rapidProgressivePercentage = motiliePercentage - (avgslowProgressivePercentage + avgnonProgressivePercentage) // Motile - (Avg slow + Avg Non Prog)

          this.motilityPercentage = {
            motilityPercentage: motiliePercentage, 
            slowProgressivePercentage: avgslowProgressivePercentage,
            rapidProgressivePercentage: rapidProgressivePercentage,
            nonProgressivePercentage: avgnonProgressivePercentage,
            immotilePercentage: immotilePercentage
          }
    
        }else if(this.getWhoCtriteriaType == "who5"){

          let immotilePercentage = this.wholeNumber((res[5]+res[11])/2); 
          
          this.motilityPercentage = {
            motilityPercentage: 100 - immotilePercentage,  //100 - immotilePercentage
            progressivePercentage: Math.round((res[1]+res[7])/2),
            nonProgressivePercentage: Math.round((res[2]+res[8])/2),
            immotilePercentage: immotilePercentage
          }

        }

      }


      // console.log("--------motilityPercentage--------",this.motilityPercentage);
      

    });
  
  }

  wholeNumber(num: number){
    let num1 = num.toString();
    if(num1.includes(".")){
      return Number(num1.split(".")[0]);
    }else{
      return num;
    }
  }


  back(){
    this.router.navigate(['/proficiency/concentration']);
    // this.location.back();
  }



  async saveAndSubmit(){

    this._commonService.updateTestLevel(7);

    this.challengeCompletedPopup();

    const {data} = await this._httpService.saveAndSubmit(this._gCf.gettestID);
    this.userTest.data.submissionDate = data.submissionDate;


    let emailInput: EmailInput = {
      type: 'submitted',
      emailto: this.userInfo.data.email,
      labName: this.userInfo.data.facilityName,
    };

    this._emailService.sendMail(emailInput).subscribe( (res) => {
      console.log(res);
    },(error) => {
      console.log(error);
    });

    const key = await this.modalSubscription();
    if(key === "exit-ipro"){
      this.exitIPRO();
    } else if( key === "print-results" ){
      this.print();
    }

    this.modalUnsubscriptoin();

  }


  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
    
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
    
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
    
        var dataURL = canvas.toDataURL("image/png");
    
        resolve(dataURL);
      };
    
      img.onerror = error => {
        reject(error);
      };
    
      img.src = url;
    });
  }

  async print() {

    const whoCriteria = this._gCf.testConfiguration.whoCriteria;
    const morphCriteria = this._gCf.testConfiguration.morphologyCriteria;
    const concentrationDevice = this._gCf.testConfiguration.concentrationDevice;

    const motilePercentage = this.motilityPercentage["motilityPercentage"];
    const progressivePercentage = this.motilityPercentage["progressivePercentage"];
    const immotilePercentage = this.motilityPercentage["immotilePercentage"];
    const nonProgPecentage = this.motilityPercentage["nonProgressivePercentage"];

    const rapidProgPercentage = this.motilityPercentage["rapidProgressivePercentage"];
    const slowProgPercentage = this.motilityPercentage["slowProgressivePercentage"];

    const sample_1_normal = this.morphologyPercentage_1["normalPercentage"];
    const sample_2_normal = this.morphologyPercentage_2["normalPercentage"];

    const sample_1_abnormal = this.morphologyPercentage_1["abnormalPercentage"];
    const sample_2_abnormal = this.morphologyPercentage_2["abnormalPercentage"];

    const count1 = this._gCf.decimalTransform(this.concentrationTest.count1);
    const count2 = this._gCf.decimalTransform(this.concentrationTest.count2);

    let submitedDate = this.submissionDatePipe.transform(this.userTest.data.submissionDate);

    let motilityData;

    if(this.getWhoCtriteriaType == 'who5'){
      motilityData = [
        ['Motility:', motilePercentage+'%',],
        ['Progressive:', progressivePercentage+'%',],
        ['Non-progressive:', nonProgPecentage+'%',],
        ['Immotile:', immotilePercentage+'%'],
      ]
    }else {
      motilityData = [
        ['Motility:', motilePercentage+'%',],
        ['Rapid Progressive:', rapidProgPercentage+'%',],
        ['Slow Progressive:', slowProgPercentage+'%',],
        ['Non-progressive:', nonProgPecentage+'%',],
        ['Immotile:', immotilePercentage+'%'],
      ]
    }

    let docDefinition = {
      content: [
        {
          alignment: 'center',
          columns: [
            {
              text: 'Batch Number: '+ this._gCf.testConfiguration.batchNumber
            },
            {
              text: this.userInfo.data.facilityName,
            },
            {
              text: this.userInfo.data.address,
            }
          ],
          style: 'mediumFont'
        },

        {
          alignment: 'center',
          type: 'none',
          style: 'mediumFont',
          margin: [130, 0],
          ol: [
            {
              image: await this.getBase64ImageFromURL('assets/images/iPro%20Logo.png'),
              width: 120,
              height: 50,
              margin: [0, 15, 0, 0]
            },
            {
              text: "iPRO Proficiency Challenge Test Results",
            },
            {
              text: "Submitted "+ submitedDate,
              margin: [0, 0, 0, 30]
            },



            {
              text: "MOTILITY",
              style: 'largeFont',
            },
            {
              text: "Criteria: "+ whoCriteria +"th",
              margin: [0, 0, 0, 20]
            },
            {
              alignment: '',
              margin: [50, 0, 0, 0],
              table: {
                headerRows: 1,
                body: motilityData
              },
              layout: 'noBorders'
            },


            {
              text: "MORPHOLOGY",
              style: 'largeFont',
              margin: [0, 20, 0, 0]
            },
            {
              text: "Staining method: "+ morphCriteria,
              margin: [0, 0, 0, 20]
            },
            {
              alignment: '',
              margin: [50, 0, 0, 20],
              table: {
                headerRows: 1,
                body: [
                  ['SAMPLE 1', ''],
                  ['Normal forms:', sample_1_normal+'%',],
                  ['Abnormal forms:', sample_1_abnormal+'%',],
                ]
              },
              layout: 'noBorders'
            },
            {
              alignment: '',
              margin: [50, 0, 0, 0],
              table: {
                headerRows: 1,
                body: [
                  ['SAMPLE 2', ''],
                  ['Normal forms:', sample_2_normal+'%',],
                  ['Abnormal forms:', sample_2_abnormal+'%',],
                ]
              },
              layout: 'noBorders'
            },



            {
              text: "CONCENTRATION",
              style: 'largeFont',
              margin: [0, 20, 0, 0]
            },
            {
              text: "Assessment method: "+ concentrationDevice,
              margin: [0, 0, 0, 20]
            },

            {
              alignment: '',
              margin: [50, 0, 0, 0],
              table: {
                widths: [95, 100],
                headerRows: 1,
                body: [
                  ['Sample 1:', count1+' M/mL',],
                  ['Sample 2:', count2+' M/mL',],
                ]
              },
              layout: 'noBorders'
            },


          ],
        },
        {
          text: "Please note: This form indicates the submitted results for the iPRO Test. Analyzed results and scores are not present.",
          style: "smallFont",
          margin: [0, 90, 0, 0]
        }

      ],
      styles: {

        space: {
          margin: [0, 10, 0, 0],
        },

        smallFont: {
          fontSize: 10,
        },
        mediumFont: {
          fontSize: 13,
        },
        largeFont: {
          fontSize: 15,
        },

      },
      defaultStyle: {
        columnGap: 20,
        font: 'Segoe_UI'
      }

    }

    pdfMake.fonts = {
      Segoe_UI: {
        normal: 'Segoe_UI.woff',
      },
    };

    pdfMake.createPdf(docDefinition).download(this.userInfo.data.firstName+' final results .pdf');
  }


  exitIPRO():void {
    this._gCf.resetAllData();
    this.router.navigate(['/']);
  }


  modalSubscription(){

    return new Promise((resolve, reject) => {
      this.modalSubscribe  = this.observeCloseModal.pipe(take(1)).subscribe(res => {
        // console.log(res);
        resolve(res.text);
      });

    });

  }



  modalUnsubscriptoin(){
    this.modalSubscribe.unsubscribe();
  }
  


  challengeCompletedPopup(){
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "CHALLENGE COMPLETED",
        showCloseIcon: false,
        icon: {
          name: "done",
          color: "#F39E00",
          size: "bigSize"
        },
        content_1: {
          text: "CONGRATULATIONS!",
          style: {'margin': 0}
        },
        content_2: {
          text: "YOU HAVE COMPLETED THE iPRO PROFICIENCY CHALLENGE",
          style: {}
        },
        content_3: "YOU WILL RECEIVE A FULL REPORT WHEN YOUR RESULTS ARE ANALYSED",
        buttons: [
          {
            key: "exit-ipro",
            text: "EXIT iPRO",
            class: "btn-primary"
          },
          {
            key: "print-results",
            text: "PRINT RESULTS",
            class: "btn-primary"
          },
        ],
      }
    );

    this._gCf.popupRemoveUpperCase();
  }

}
