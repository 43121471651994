import { Component, OnInit,ViewChild } from '@angular/core';
import { MovingDirection, WizardComponent } from 'angular-archwizard';
import { globalConfig } from './../globalConfig';


import { ActivatedRoute, Router } from '@angular/router';
import { slideInAnimation } from '../animation';

@Component({
  selector: 'app-proficiency',
  templateUrl: './proficiency.component.html',
  styleUrls: ['./proficiency.component.scss'],
  animations: [slideInAnimation],
})
export class ProficiencyComponent implements OnInit {

  // @ViewChild(WizardComponent,{ static: true })
  // public wizard: WizardComponent;

  
  // stepLinks = [
  //   '/proficiency/intro',
  //   '/proficiency/motility',
  //   '/proficiency/morphology',
  //   '/proficiency/concentration',
  //   '/proficiency/finish',
  // ];

  constructor(private router: Router, public _globalConfig:globalConfig) { 
    //this.IsValidBatchNumber = _httpService.getBatchNumberStatus();
  }
  
  // public counter1 = 0;
  // public counter2 = 0;
  // public counter3 = 0;

  ngOnInit(){}

  getRouteAnimationData(outlet) {
    if ((outlet.activatedRoute as ActivatedRoute).snapshot.params['challangeId']) {
      // For morphology challange 1 and 2 instead of returning the state
      // return the challangeId...
      return (outlet.activatedRoute as ActivatedRoute).snapshot.params['challangeId'];
    }
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
  }

//   increaseCounter1(direction: MovingDirection): void {
//     this.counter1 += 1;
//   }

//   increaseCounter2(direction: MovingDirection): void {
//     this.counter2 += 1;
//   }

//   increaseCounter3(direction: MovingDirection): void {
//     this.counter3 += 1;
//   }

  
//   previousLink(){
//     //const index = this.stepLinks.indexOf(this.router.url);
//     //let currentUrl = index-1;
//     //this.router.navigate([this.stepLinks[currentUrl]]);
//  }

//  introFun(){

//   this.router.navigate([this.stepLinks[0]]);
//  }

//  motileFun(){

//   this.router.navigate([this.stepLinks[1]]);
//  }

//  morphFun(){

//   this.router.navigate([this.stepLinks[2]]);
//  }

//  concentrationFun(){

//   this.router.navigate([this.stepLinks[3]]);
//  }

//  finishFun(){

//   this.router.navigate([this.stepLinks[4]]);
//  }

//  finishTest(){

//   this._globalConfig.userStatus = 'InActive';
//   localStorage.removeItem(this._globalConfig.accessToken);
//   this._router.navigate(['/signin']);

//  }
 
}
