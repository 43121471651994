import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OpenModelInterface } from '../../interface/popup.iterface';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor() { 
    this.observeModal();
  }

  $PopupModal = new Subject();
  $ClosePopupModal = new Subject();

  openModal(data: OpenModelInterface) {
    this.$PopupModal.next({...data});
  }

  observeModal() {
    return this.$PopupModal.asObservable();
  }

  closeModal(data: string){
    this.$ClosePopupModal.next({text: data});
  }

  observeCloseModal() : Observable<any>{
    return this.$ClosePopupModal.asObservable();
  }

}
