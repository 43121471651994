import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate,
} from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  // Slide in from right side animation...
  transition(
    'one => two ,one => 2, one => 1, one => four, one => five, two => 1, two => 2, two => four, two => five, 1 => four, 1 => five, 1 => 2, 2 => four, 2 => five, four => five',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(
        ':enter',
        [style({ left: '100%' }), style({ transform: 'scale(0.9)' })],
        { optional: true }
      ),
      query(
        ':leave',
        [
          animate('200ms linear', style({ transform: 'scale(0.9)' })),
          animateChild(),
        ],
        { optional: true }
      ),
      group([
        query(
          ':leave',
          [animate('300ms ease-in-out', style({ left: '-100%' }))],
          { optional: true }
        ),
        query(':enter', [
          animate('300ms ease-in-out', style({ left: '0%' })),
          animate('200ms linear', style({ transform: 'scale(1)' })),
        ]),
      ]),
    ]
  ),

  // Slide in from left side animation...
  transition(
    'five => one, five => two, five => 1, five => 2, five => four, four => 2, four => 1, four => two, four => one,2 => 1, 2 => two, 2 => one, 1 => two, 1 => one, two => one',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(
        ':enter',
        [style({ left: '-100%' }), style({ transform: 'scale(0.9)' })],
        { optional: true }
      ),
      query(
        ':leave',
        [
          animate('200ms linear', style({ transform: 'scale(0.9)' })),
          animateChild(),
        ],
        { optional: true }
      ),
      group([
        query(':leave', [animate('300ms ease-out', style({ left: '100%' }))], {
          optional: true,
        }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' })),
          animate('200ms linear', style({ transform: 'scale(1)' })),
        ]),
      ]),
    ]
  ),
]);
