import { Component, OnInit } from '@angular/core';
import { GlobalUser } from '../../environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { globalAlert } from './../globalAlert';
import { Router } from '@angular/router';
import { globalConfig } from '../globalConfig';
import { HttpService } from '../shared/services/http.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  data2 = GlobalUser.UserName; 
  isContactformSubmited:boolean = false;

  constructor(
    private fb: FormBuilder,
    public _globalAlert: globalAlert,
    public _globalConfig:globalConfig,
    private router: Router,
    private _httpService: HttpService

    ) { }

  ngOnInit() {
    document.body.className =  "contact-us";
  }

  //----------------------Registration form validation------------------------------
  contactForm = this.fb.group({

    contactFirstName: [null, Validators.compose([Validators.required ,Validators.minLength(3) ,Validators.maxLength(20),Validators.pattern('^[a-zA-Z]+$')])],
    contactLastName: [null, Validators.compose([Validators.required ,Validators.minLength(3) ,Validators.maxLength(20),Validators.pattern('^[a-zA-Z]+$')])],
    contactEmail: [null, Validators.compose([Validators.required,Validators.email])],
    contactPhoneNumber:[null, Validators.compose([Validators.required, Validators.minLength(10) ,Validators.maxLength(12),Validators.pattern('^[0-9]+$')])],
    contactFacilityName:[null,Validators.compose([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.minLength(3), Validators.maxLength(50)])],
    contactMessage:[null,Validators.compose([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(200)])]
  });



  //---------------------Registration form validation messages------------------------
  get contactFormvalidate() {  
    
    let f = this.contactForm.controls;

    return [
      {'firstName':f.contactFirstName.dirty && f.contactFirstName.invalid ||f.contactFirstName.invalid && f.contactFirstName.touched,
        'firstNameErrMsg': [
          { type: 'required', message: 'First Name is Required' },
          { type: 'pattern', message: 'First Name must contain only letters' },
          { type: 'minlength', message: 'Please enter a minimum of 3 characters.'},
          { type: 'maxlength', message: 'Maximum 20 characters Acceptable'}
        ],
      },
      {'lastName': f.contactLastName.dirty && f.contactLastName.invalid ||f.contactLastName.invalid && f.contactLastName.touched,
        'lastNameErrMsg': [
          { type: 'required', message: 'Last Name is Required' },
          { type: 'pattern', message: 'Last Name must contain only letters' },
          { type: 'minlength', message: 'Please enter a minimum of 3 characters.'},
          { type: 'maxlength', message: 'Maximum 20 characters Acceptable'}
        ],
      },
      {'email':f.contactEmail.dirty && f.contactEmail.invalid ||f.contactEmail.invalid && f.contactEmail.touched,
        'emailErrMsg': [
          { type: 'required', message: 'Email is Required' },
          { type: 'email', message: 'Please enter a valid email address' },

        ],
      },
      {'phoneNumber':f.contactPhoneNumber.dirty && f.contactPhoneNumber.invalid ||f.contactPhoneNumber.invalid && f.contactPhoneNumber.touched,
        'phoneNumberErrMsg': [
          { type: 'required', message: 'Phone Number is Required' },
          { type: 'pattern', message: 'Invalid Phone Number' },
          { type: 'minlength', message: 'Please enter 10-12 characters'},
          { type: 'maxlength', message: 'Please enter 10-12 characters'}
        ],
      },
      {'contactFacilityName':f.contactFacilityName.dirty && f.contactFacilityName.invalid ||f.contactFacilityName.invalid && f.contactFacilityName.touched,
        'facilityNameErrMsg': [
          { type: 'required', message: 'Facility Name is Required' },
          { type: 'minlength', message: 'Please enter a minimum of 3 characters.'},
          { type: 'maxlength', message: 'Maximum 50 characters Acceptable'}
        ],
      },
      {'contactMessage':f.contactMessage.dirty && f.contactMessage.invalid ||f.contactMessage.invalid && f.contactMessage.touched,
        'textAreaErrMsg': [
          { type: 'required', message: 'Message is Required' },
          { type: 'maxlength', message: 'Only 200 characters are allowed.'}
        ],
      },
    ];
  }

  contactFormSubmit(){

    if(this.contactForm.valid){

      let data = {
        'firstName': this.contactForm.value.contactFirstName,
        'lastName':this.contactForm.value.contactLastName,
        'email':this.contactForm.value.contactEmail,
        'phoneNumber':this.contactForm.value.contactPhoneNumber,
        'facilityName':this.contactForm.value.contactFacilityName,
        'message':this.contactForm.value.contactMessage

      };
      
      this._globalConfig.spinner(true);

      this._httpService.sendContactMsg(data).then((res:any)=>{

        this._globalConfig.spinner(false);

        this.isContactformSubmited = true;
        
        this.contactForm.reset();

      });
    }

  }

}
