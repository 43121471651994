/* Since routing animation won't work if we reuse the same component (For this case Morphology).
So creating a custom reUse strategy so instead of using the same morphology component for
morphology 1 and 2 it will generate new component for both morphology 1 and 2 challange */

import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { MorphologyComponent } from './proficiency/morphology/morphology.component';

export class CustomRouteUse implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // Only don't re-use component if it's morphology component...
    if (curr.component === MorphologyComponent) {
      return false;
    }
    return future.routeConfig === curr.routeConfig;
  }
}
