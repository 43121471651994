import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupComponent } from './popup/popup.component';
import { PopupService } from './popup/popup.service';
import { StepperComponent } from './stepper/stepper.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LoadingComponent } from './loading.component';



@NgModule({
  declarations: [PopupComponent, StepperComponent, LoadingComponent],
  imports: [
    CommonModule,
    NgScrollbarModule
  ],
  providers: [PopupService],
  exports: [PopupComponent, StepperComponent, LoadingComponent]
})
export class ComponentsModule { }
