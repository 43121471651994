import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProficiencyComponent } from './proficiency/proficiency.component';
import { ContactComponent } from './contact/contact.component';
import { SigninComponent } from './signin/signin.component';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { IntroComponent } from './proficiency/intro/intro.component';
import{ globalConfig } from './globalConfig';

import{ globalAlert } from './globalAlert';
import { JwtModule } from "@auth0/angular-jwt";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MotilityCountingComponent } from './proficiency/motility-counting/motility-counting.component';
import { FinalResultsComponent } from './proficiency/final-results/final-results.component';
import { MorphologyComponent } from './proficiency/morphology/morphology.component';
import { ConcentrationComponent } from './proficiency/concentration/concentration.component';
import { SharedModule } from './shared/shared.module';
import { AboutProficiencyTestComponent } from './about-proficiency-test/about-proficiency-test.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RegistrationFormComponent } from './registration-form/registration-form.component';

//import { CarouselModule } from 'ngx-bootstrap/carousel';

//import { ProficiencyModuleModule } from './proficiency/proficiency-module.module'; 

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProficiencyComponent,
    ContactComponent,
    SigninComponent,
   IntroComponent,
   MotilityCountingComponent,
   FinalResultsComponent,
   MorphologyComponent,
   ConcentrationComponent,
   AboutProficiencyTestComponent,
   RegistrationFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    HttpClientModule,
    NgbModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    //ProficiencyModuleModule,

    SharedModule,
    
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["example.com"],
        blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
    NgScrollbarModule,
    //CarouselModule.forRoot()
  ],
  providers: [
    globalConfig,globalAlert, {provide: LocationStrategy, useClass: HashLocationStrategy},
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  

}
