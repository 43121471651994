import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

import 'firebase/storage';
import "firebase/auth";
import "firebase/firestore";
import { globalConfig } from 'src/app/globalConfig';



@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // IsValidBatchNumber:boolean = false;
  // TuenStartTestOn:boolean = false;
  EnteredBatchNumber:string = '';

  //WHO:string = "WHO Criteria";
  //CONCSTD:string = "Concentration assesment method";

  //Collection Path
  batchCollection: string = "Batches";
  userCollection: string = "Users";
  userActivityCollection: string = "UserActivity";
  usertestCollection: string = "UserTests"

  //Subcollection Path
  batchUsersCollection: string = "batchUsers";

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private _gCf: globalConfig
  ) { }

  async sendContactMsg(data, collectionPath="ContactMessages"){
    return await this.addCollectionItam(collectionPath, data);
  }

  async signIn(data){
    
    try{
      var auth = await this.afAuth.auth.signInWithEmailAndPassword(data.email, data.password);
      return auth.user.uid;
    } catch(error){
      console.log(error);
      throw Error("Unable to Login...");
    }
  }

  async updateLastLoggedIn(userID: string){

    const data = {
      lastLoggedIn    : this._gCf.timestamp,
    }

    return await this.updateCollectionData(this.userCollection, userID, data);

  }

  async createLoginActivity(userID: string){

    const data = {
      userID  : userID,
      date    : this._gCf.timestamp,
      type    : "login"
    }
    return await this.addCollectionItam(this.userActivityCollection, data);
  }

  async getCurrentUser(id: string){
    let qA = [
      { key: 'authID', condition: '==', value: id}
    ];
    return await this.getCollectionListFilter(this.userCollection, qA);
  }

  async getUserInfo(id: string){
    console.log('-------getUserInfo---', id);
    
    return await this.getCollectionData(this.userCollection, id);
  }

  async isUserExistinCurrentBatch (userID: string): Promise<boolean>{

    let qA = [
      { key: 'userID', condition: '==', value: userID }
    ];

    const user = await this.getSubCollectionFilter(this.batchCollection, "", this.batchUsersCollection, qA);

    if(user.length > 0){
      return true;
    }
    return false;
  }


  async getActiveBatchData(collectionpath:string = this.batchCollection){

    let qA = [
      { key: 'status', condition: '==', value: "active" },
    ];
    let batchData = await this.getCollectionListFilter(collectionpath, qA);

    if(batchData.length > 0){

      batchData = batchData[0];

      let motilityData = await this.getSubCollectionList(collectionpath, batchData.id, "motilityTestFiles");
      // let morphologyData = await this.getSubCollectionList(collectionpath, batchData.id, "morphologyTestFiles");

      let morphologyData_1 = await this.getSubCollectionList(collectionpath, batchData.id, "morphologyTestFiles_1");
      let morphologyData_2 = await this.getSubCollectionList(collectionpath, batchData.id, "morphologyTestFiles_2");
      
      motilityData.forEach(async element => {
        let fileName = element.data.fileName;
        let fileUrl = `Batches/${batchData.id}/Motility/${fileName}`;
        element.data.fileName = await this.getFileUrl(fileUrl);
      });

      // morphologyData.forEach(async element => {
      //   let type = element.data.type;
      //   let fileName = element.data.fileName;
      //   let fileUrl = `Batches/${batchData.id}/Morphology/${type.charAt(0).toUpperCase()}${type.slice(1)}/${fileName}`;
      //   element.data.fileName = await this.getFileUrl(fileUrl);
      // });

      morphologyData_1.forEach(async element => {
        let type = element.data.type;
        let fileName = element.data.fileName;
        let fileUrl = `Batches/${batchData.id}/Morphology_1/${type.charAt(0).toUpperCase()}${type.slice(1)}/${fileName}`;
        element.data.fileName = await this.getFileUrl(fileUrl);
      });

      morphologyData_2.forEach(async element => {
        let type = element.data.type;
        let fileName = element.data.fileName;
        let fileUrl = `Batches/${batchData.id}/Morphology_2/${type.charAt(0).toUpperCase()}${type.slice(1)}/${fileName}`;
        element.data.fileName = await this.getFileUrl(fileUrl);
      });

      batchData["data"]["morphologyTestFiles_1"] = morphologyData_1;
      batchData["data"]["morphologyTestFiles_2"] = morphologyData_2;
      // batchData["data"]["morphologyTestFiles"] = morphologyData;
      batchData["data"]["motilityTestFiles"] = motilityData;

      return batchData;
    }

    return {};
  }


  async saveIntroData(data){
    // console.log("------------saveIntroData-----------", data);
    // console.log("------------saveIntroData-----------", this.usertestCollection);
    data = {
      ...data,
      createdAt: this._gCf.timestamp
    }
    return await this.addCollectionItam(this.usertestCollection, data);
  }

  async updateIntroData(docId, data){
    return await this.updateCollectionData(this.usertestCollection, docId, data);
  }


  async getUserCurrectTestData(req, collectionPath=this.usertestCollection){

    let qA = [
      { key: 'userID', condition: '==', value: req.userID },
      { key: 'testConfiguration.batchNumber', condition: '==', value: req.batchNumber }
    ];
    let userTestData = await this.getCollectionListFilter(collectionPath, qA);

    if(userTestData[0]) return userTestData[0];
    return null;

  }


  async getUserLastTestData(req, collectionPath=this.usertestCollection){

    let qA = [
      { key: 'userID', condition: '==', value: req.userID },
    ];
    let userTestData = await this.getCollectionListFilter(collectionPath, qA);

    if(userTestData[0]) return userTestData[0];
    return null;

  }

  


  async getUserTestDataByTestID(req, collectionPath=this.usertestCollection){
   console.log('------getUserTestDataByTestID----', req);
   
    let userTestData = await this.getCollectionData(collectionPath, req.testID);
    return userTestData;
  }


  async getConcentrationTestData(testID, collectionPath=this.usertestCollection){
    console.log('------getConcentrationTestData----', testID);
    let userTestData = await this.getCollectionData(collectionPath, testID);

    return userTestData;
  }



  async createUserTestData(data, collectionPath=this.usertestCollection){
    return await this.addCollectionItam(collectionPath, data);
  }


  async updateUserTestData(testID, testData, collectionPath=this.usertestCollection){

    // console.log("------------updateUserTestData-----------", testID);
    // console.log("------------updateUserTestData-----------", testData);
    // console.log("------------updateUserTestData-----------", collectionPath);
    
    let data = {
      ...testData,
      "updatedAt": this._gCf.timestamp
    }

    return await this.updateCollectionData(collectionPath, testID, data);
  }

  async updateUserTest(docid, subCollectionPath, data){

    // console.log("------------updateUserTest-----------", docid);
    // console.log("------------updateUserTest-----------", subCollectionPath);
    // console.log("------------updateUserTest-----------", data);
    

    return new Promise((resolve, reject) => {
      
      let promises = [];

      data.forEach(async (doc) => {

        let docId = doc.id;
        let data = doc.data;
        delete data.id;
        
        promises.push(this.updateSubCollectionData(docid, subCollectionPath, docId, data));

      });

      Promise.all(promises).then(res => {
        // console.log(res);
        resolve(res);
      });

    });
    
  }

  async deleteUserTestDataSingle(collectionID, collectionPath=this.usertestCollection){

    await this.deleteSubCollection(collectionPath, collectionID, "motilityTest");

    await this.deleteSubCollection(collectionPath, collectionID, "morphologyTest1");

    await this.deleteSubCollection(collectionPath, collectionID, "morphologyTest2");

    await this.deleteCollectionItem(collectionPath, collectionID);
    
    return true;
  }

  async resetCount(collectionID, subCollectionPath){
    await this.deleteSubCollection(this.usertestCollection, collectionID, subCollectionPath);
    this.updateCollectionData(this.usertestCollection, collectionID, {"currentVideoIndex": 0});
  }


  async getUserMotilityTestData(testID, collectionPath=this.usertestCollection, subCollectionPath="motilityTest"){
    
    console.log('------getUserMotilityTestData----', testID);

    let userTestData = await this.getCollectionData(collectionPath, testID);

    let obj = JSON.parse(JSON.stringify(userTestData));
    
    // console.log("--------userTestData-------", userTestData);
    
    if(obj) {

      // obj = JSON.parse(JSON.stringify(userTestData));

      let userMotilityTestData =  await this.getSubCollectionList(collectionPath, testID, subCollectionPath);

      obj["data"]["motilityTest"]= userMotilityTestData;

      // console.log("--------obj-------", obj);

      return obj;

    } else {

      return {};
    }
    
    
  }


  async getUsetMotilityTest(testID, collectionPath=this.usertestCollection, subCollectionPath="motilityTest"){
    return await this.getSubCollectionList(collectionPath, testID, subCollectionPath);
  }


  async getUserMorphologyTestData(testID, collectionPath=this.usertestCollection, subCollectionPath){
    
    return await this.getSubCollectionList(collectionPath, testID, subCollectionPath);
    
  }



  async saveAndSubmit(docId){
    let data = {
      "submissionDate": this._gCf.timestamp
    };
    return await this.updateCollectionData(this.usertestCollection, docId, data);
  }

  



  // setBatchNumberStatus(IsValid : boolean) {
  //   this.IsValidBatchNumber = IsValid;
  // }

  // getBatchNumberStatus() {
  //   return this.IsValidBatchNumber;
  // }

  // setStartButtonStatus(IsStart: boolean) {
  //   this.TuenStartTestOn = IsStart;
  // }

  // getStartButtonStatus() {
  //   return this.TuenStartTestOn;
  // }


  // ====================================================================================================
  // Global and Generic functions
  // ====================================================================================================
  // public getCollectionList(collectionpath) {

  //   // query => {property, value}   i.e. {property: "age"}
  //   const markers: any = [];

  //   return new Promise<any>((res, rej) => {
  //     this.afs.collection(collectionpath).get().subscribe(snapshot => {
  //       snapshot.forEach(async doc => {
         
  //         // doc.ref.collection("motilityTestFiles").get().then((res) => console.log(res));

  //         // querySnapshot.forEach((document) => {
  //         let morphologyTestFiles = await doc.ref.collection("morphologyTestFiles").get();
  //         console.log(morphologyTestFiles);

  //         let motilityTestFiles = await doc.ref.collection("motilityTestFiles").get();
  //         console.log(motilityTestFiles);

  //         markers.push({ id: doc.id, data: doc.data(), morphologyTestFiles: morphologyTestFiles, motilityTestFiles: motilityTestFiles });

  //         // });

  //       });
  //       //return markers;
  //       res(markers);
  //       console.log('success')
  //     })
  //   })
  // }



  public getCollectionListFilter(collectionpath, queryArray) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      // this.afs.collection(collectionpath , ref => ref.where(key, condition, value)).get().subscribe(snapshot => {
      this.afs.collection(collectionpath , (ref: firebase.firestore.CollectionReference) => { 

        let reference: any = ref;
        queryArray.forEach((val: { key: string ; condition ; value: any; }) => {
          reference = reference.where(val.key, val.condition, val.value);
        });

        reference.orderBy("timestamp", "desc")
        return reference;

      }).get().subscribe((snapshot: any) => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });
        });
      //return markers;
        res(markers);
        console.log('success')
      })
    })
  }



  // Filter and OrderBy
  public getCollectionListFilterOrderBy(collectionpath, queryArray) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      // this.afs.collection(collectionpath , ref => ref.where(key, condition, value)).get().subscribe(snapshot => {
      this.afs.collection(collectionpath , (ref: firebase.firestore.CollectionReference) => { 

        let reference: any = ref;
        queryArray.forEach((val: { key: string ; condition ; value: any; }) => {
          reference = reference.where(val.key, val.condition, val.value);
        });

        reference.orderBy("updatedAt", "desc");
        reference.limit(1);
        return reference;

      }).get().subscribe((snapshot: any) => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });
        });
      //return markers;
        res(markers);
        console.log('success')
      })
    })
  }


  public getCollectionList(collectionpath) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).get().subscribe(snapshot => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });

        });
        //return markers;
        res(markers);
        console.log('success')
      })
    })
  }


  public getCollectionData(collectionpath, docid) {
    
    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(docid).snapshotChanges().subscribe((doc) => {
        if (doc.payload) {
          // console.log("--------------------doc.data()---------------", doc.payload.data());
          
          res({ id: doc.payload.id, data: doc.payload.data() });
        }
        else {
          console.log("No such document!");
          res({ id: undefined, data: undefined });
        }
      });
    });

  }

  public addCollectionItam(collectionpath, data) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).add(data)
        .then((doc) => {
          // console.log({ "id": doc.id, "data": data });
          res(doc);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });


  }

  public updateCollectionData(collectionpath, Itemid, itemData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).set(itemData, { merge: true })
        .then((docRef) => {
          // console.log({ "collectionPath": collectionpath, "id": Itemid, "data": itemData });
          res({ id: Itemid, data: itemData });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }



  public updateCollectionDataByQuery(collectionpath, queryArray, itemData){

    return new Promise<void>((resolve, reject) => {

      let doc = this.afs.collection(collectionpath, (ref: firebase.firestore.CollectionReference) => { 

        let reference: any = ref;
        queryArray.forEach((val: { key: string ; condition ; value: any; }) => {
          reference = reference.where(val.key, val.condition, val.value);
        })
        return reference;

      });

      doc.get().subscribe((querySnapshot: any) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update(itemData);
        });
        resolve();
      },error => {
        reject(error);
      });      
    });
  }


  public deleteCollectionItem(collectionpath, Itemid) {
    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).delete()
        .then((docRef) => {
          // console.log({ "collectionPath": collectionpath, "id": Itemid });
          res(docRef);
        })
        .catch((error) => {
          console.error("Error deleting document: ", error);
          rej(error);
        });
    });

  }

  /*************************************** Sub Collection ************************************/
  public getSubCollectionList(collectionpath, doc_id, subCollectionpath) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(doc_id).collection(subCollectionpath).get().subscribe(snapshot => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });

        });
        //return markers;
        res(markers);
        // console.log('success')
      })
    })
  }

  public getSubCollectionFilter(collectionpath: string, doc_id: string, subCollectionpath: string, queryArray) {

    const markers: any = [];

    let qA = [
      { key: 'status', condition: '==', value: "active" },
    ];
    
    return new Promise<any>(async (res, rej) => {

      let batchData = await this.getCollectionListFilter(collectionpath, qA);
      
      const batchNumber = batchData[0].id;
        
      this.afs.collection(collectionpath).doc(batchNumber).collection(subCollectionpath , (ref: firebase.firestore.CollectionReference) => { 

        let reference: any = ref;
        queryArray.forEach((val: { key: string ; condition ; value: any; }) => {
          reference = reference.where(val.key, val.condition, val.value);
        })
        return reference;

      }).get().subscribe((snapshot: any) => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });
        });
        res(markers);
        console.log('success')
      })
    })


    // return new Promise<any>((res, rej) => {
    //   this.afs.collection(collectionpath).doc(doc_id).collection(subCollectionpath ,(ref: firebase.firestore.CollectionReference) => { 

    //     let reference: any = ref;
    //     queryArray.forEach((val: { key: string ; condition ; value: any; }) => {
    //       reference = reference.where(val.key, val.condition, val.value);
    //     })
    //     return reference;

    //   }).get().subscribe((snapshot: any) => {
    //     console.log("------snapshot----", snapshot);
        
    //     snapshot.forEach(doc => {
    //       markers.push({ id: doc.id, data: doc.data() });
    //     });
    //   })
    // })
  }



  public updateSubCollectionData(collectionID, subCollectionPath, docId, data, collectionpath=this.usertestCollection) {

    // console.log("-------collectionpath-----",collectionpath);
    // console.log("------collectionID-----",collectionID);
    // console.log("------subCollectionPath----",subCollectionPath);
    // console.log("----data----",data);
    // console.log("-----docId----",docId);

    return new Promise<any>(async (res, rej) => {

      this.afs.collection(collectionpath).doc(collectionID).collection(subCollectionPath).doc(docId).set(data, { merge: true }).then((docRef) => {
        res({ id: collectionID, data: data });
      }).catch( err => {
        rej(err);
      });
     
    });
  }


  public deleteSubCollection(collectionpath: string, docid:string, subCollectionPath:string) {

    this.afs.collection(collectionpath)
      .doc(docid)
      .collection(subCollectionPath)
      .get()
      .toPromise()
      .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });
    });

  }


  /*
  public deleteFile(videoName, path: string = 'MediafromTests/Videos/') {

    return new Promise<any>((res, rej) => {
      this.st.ref(path + videoName).delete().then(() => {

        console.log("file was deleted")
      })
        .catch((err) => {
          console.log("error ", err)
        })

    })

    console.log("file to delete", path + videoName)
    //
  }
  */

  public getTotalNumber(collection) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collection).get()
        .subscribe(async function (querySnapshot) {
          let totalRequests = querySnapshot.docs.length;
          res({ number: totalRequests });
        });
    });

  }

  private uploadFile(file, ref, filetype: string = 'Image') {

    return new Promise<any>((res, rej) => {
      if (file.type.includes(filetype)) {
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            res(url);
          }).catch(err => {
            rej(err);
          });
        });
      } else {
        rej("error");
      }

    });

  }

  public getFileUrl(fileName) {
    return new Promise<any>((res, rej) => {
      var ref = firebase.storage().ref().child(`${fileName}`);
      ref.getDownloadURL().then((url) => {
        res(url);
      }).catch(err => {
        rej(err);
      });
    });
  }

  // ====================================================================================================
  // End of Global and Generic functions
  // ====================================================================================================




}
