import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { version } from 'package.json';

@Component({
  selector: 'app-about-proficiency-test',
  templateUrl: './about-proficiency-test.component.html',
  styleUrls: ['./about-proficiency-test.component.scss']
})
export class AboutProficiencyTestComponent implements OnInit {


  currentVersion = "";

  constructor(
    private _router: Router,
  ) { 
    this.currentVersion = version;
  }

  ngOnInit() {
    /***** Intro background image *****/
    document.body.className = "intro";
  }

}
