import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { OpenModelInterface } from '../../interface/popup.iterface';
import { PopupService } from './popup.service';
// import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnDestroy{

  @ViewChild('popupModal', { static: false}) popupModal: ElementRef;

  showCloseIcon: boolean = false;
  heading: string;
  content_1: {
    text: any;
    style: object;
  }
  content_2: {
    text: string;
    style: object;
  }
  content_3: string;
  lists: any;
  lists2: any;
  icon;
  buttons = [];

  observeModelSub;

  constructor(
    private modalService: NgbModal,
    private popupService: PopupService
  ) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };

    let options = {ariaLabelledBy: 'modal-basic-title', centered: true};

    this.observeModelSub = this.popupService.observeModal().subscribe((res: OpenModelInterface) => {
      
      if(res.showCloseIcon){
        this.showCloseIcon = res.showCloseIcon;
      }

      this.heading = res.heading;
      this.content_1 = res.content_1;
      this.content_2 = res.content_2;
      this.content_3 = res.content_3;
      this.lists = res.lists;
      this.lists2 = res.lists2;
      this.icon = res.icon;
      this.buttons = res.buttons;

      if(res.isNobackdrop){
        Object.assign(options, ngbModalOptions);
      }
      this.modalService.open(this.popupModal, options);

    });

  }

  modalClose(txt){
    this.modalService.dismissAll();
    this.popupService.closeModal(txt);
  }


  ngOnDestroy(){
    this.observeModelSub.unsbscribe();
  }

}
