import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { globalConfig } from 'src/app/globalConfig';

@Injectable()
export class isBatchExpiredGuardService implements CanActivate {
  constructor(public _gCf: globalConfig) {}
  canActivate(): boolean {  
    return !this._gCf.getisBatchExpired;
  }
}