import {Injectable } from '@angular/core';
import {FirestoreTimestamp, TestConfiguration } from './shared/interface/global.interface';
import * as firebase from 'firebase/app';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class globalConfig {

  constructor(private _decimalPipoe: DecimalPipe){}

  private isSplashVideoShown:boolean = false;

  currentUser: any = null;
  
  testID: string = null;

  testLevel: number = 0
  currentTest: number = 0;
  private skippedTestLevels: string[] = [];

  // 'true' if user exist in the current batch else 'false'
  private isUserExistinCurrentBatch: boolean = false;
  private isBatchExpired: boolean = false;

  showSpinner:boolean = false;

  testConfiguration: TestConfiguration;

  firebaseFileUrl: string = "https://firebasestorage.googleapis.com/v0/b/i-pro-7ecfe.appspot.com/o/iPROInstructionDocs%2F";

  productInsert: string = this.firebaseFileUrl+"iPRO%20INSERT%2028_NOV_2022_FINAL.pdf?alt=media&token=68c627c7-153c-42a1-baf1-02f9946108e0";


  motility4File = {
    instruction: this.firebaseFileUrl+"iPRO%20instructions%20-%20Motility%20WHO%204_21_FEB_23_JG.pdf?alt=media&token=296f5131-6fb1-42e7-987b-b36fab431c42",
  };

  motility5File = {
    instruction: this.firebaseFileUrl+"iPRO%20instructions%20-%20Motility%20WHO%205_21_FEB_23_JG1.pdf?alt=media&token=1b3386b3-bd42-4760-bf73-ee738c332863"
  };

  motility6File = {
    instruction: this.firebaseFileUrl+"iPRO%20instructions%20-%20Motility%20WHO%206_21_FEB_23_JG.pdf?alt=media&token=c2916985-4b86-4836-9c22-4afbf64bbc0e"
  };

  morphFile = this.firebaseFileUrl+"iPRO%20Instructions_Morphology_18_OCT_22_LT.pdf?alt=media&token=edede194-198d-4cda-87a2-947f88d697b3";

  concFile = this.firebaseFileUrl+"iPRO%20Instructions_Concentration_WHO%204th_5th_6th_10_AUG_22_LT.pdf?alt=media&token=f1ebe4e0-4629-4d07-8f06-f69fa76b5541";



  spinner(status: boolean){
    this.showSpinner = status;
  }

  get getisSplashVideoShown(): boolean{
    return this.isSplashVideoShown;
  }

  set setisSplashVideoShown(val: boolean){
    this.isSplashVideoShown = val;
  }


  // Server Data
  morphologySystemData_1: any[] = [];
  morphologySystemData_2: any[] = [];
  motilitySystemData:any[] = [];

  get getmotilitySystemData(){
    return this.motilitySystemData;
  }
  set setmotilitySystemData(data){
    this.motilitySystemData = data;
  }

  // User Data
  get getcurrentUser(){
    return this.currentUser;
  }

  // Test Data
  get gettestID(): string{
    return this.testID;
  }
  set settestID(testID: string){
    this.testID = testID;
  }

  get getcurrentTest(){
    return this.currentTest;
  }
  set setcurrentTest(currentTest){
    this.currentTest = currentTest;
  }

  get getskippedTestLevels(){
    return this.skippedTestLevels;
  }
  set setskippedTestLevels(strArray: string[]){
    this.skippedTestLevels = strArray;
  }

  set addskippedTestLevels(str: string){
    if(!this.skippedTestLevels.includes(str)) this.skippedTestLevels.push(str);
  }
  set removeskippedTestLevels(str: string){
    let index = this.skippedTestLevels.findIndex(val => val == str);
    if(index != -1) this.skippedTestLevels.splice(index, 1);
  }

  get gettestLevel(){
    return this.testLevel;
  }
  set settestLevel(testLevel){
    this.testLevel = testLevel;
  }


  get gettestConfiguration(){
    return this.testConfiguration;
  }
  set settestConfiguration(testConfiguration: TestConfiguration){
    this.testConfiguration = testConfiguration;
  }

  get canUpdateTestLevel(){
    return this.gettestLevel < this.getcurrentTest;
  }

  
  get getisUserExistinCurrentBatch(): boolean{
    return this.isUserExistinCurrentBatch;
  }

  set setisUserExistinCurrentBatch(data: boolean){
    this.isUserExistinCurrentBatch = data;
  }


  get getisBatchExpired(): boolean{
    return this.isBatchExpired;
  }

  set setisBatchExpired(data: boolean){
    this.isBatchExpired = data;
  }


  get timestamp() : any { return firebase.firestore.Timestamp.now()};


  // Concentration Decimal Type
  private decimalFormat: string = '1.1-2'; // output 0 => 0.0
  get getDecimalFormat(): string{
   return this.decimalFormat; 
  }
  decimalTransform(num: number){
    // If the value is 'null' set '0'
    if(!num) num = 0;
    return this._decimalPipoe.transform(num, this.getDecimalFormat);
  }


  resetUserData(): void{
    this.currentUser = null;
  }

  resetTestData(): void{
    this.setcurrentTest = 1;
    this.settestLevel = 0;
    this.setskippedTestLevels = [];
    this.settestID = null;
  }


  resetBatchData(): void{
    this.motilitySystemData = [];
    this.morphologySystemData_1 = [];
    this.morphologySystemData_2 = [];
  }


  resetAllData(): void{
    this.resetUserData();
    this.resetTestData();
    this.resetBatchData();
  }

  popupRemoveUpperCase(): void{
    var element = document.getElementById("popup");
    element.classList.remove("popup");
  }

}