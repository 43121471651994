import { Component, OnInit } from '@angular/core';
import { globalConfig } from './../../globalConfig';
import { Router } from '@angular/router';
import { IntroTestDataRes, SystemConfiguration } from '../../shared/interface/global.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PopupService } from 'src/app/shared/components/popup/popup.service';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { DomSanitizer } from '@angular/platform-browser';

// import * as admin from 'firebase-admin';
// import Timestamp = admin.firestore.Timestamp;

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit{

  activeBatchNumber: string;
  batchEndDate: any;

  allowSelectionChanges: boolean = false;

  isDataloaded: boolean = false;

  whoCriteria = [
    { key: "WHO 4", value: "WHO 4"},
    { key: "WHO 5", value: "WHO 5"},
    { key: "WHO 6", value: "WHO 6"},
  ];

  morphologyCriteria = [
    { key: "Papanicolaou", value: "Papanicolaou" },
    { key: "Diff-Quik", value: "Diff-Quik" },
    { key: "Pre-Stained Slide", value: "Pre-Stained Slide" },
  ];

  concentrationDevices = [
    { key: "SQA Device", value: "SQA Device" },
    { key: "Makler", value: "Makler" },
    { key: "Neubauer", value: "Neubauer" }
  ];

  introFormGroup = new FormGroup({
    batchNumber: new FormControl(null, [Validators.required]),
    whoCriteria: new FormControl("", [Validators.required]),
    morphologyCriteria: new FormControl("", [Validators.required]),
    concentrationDevice: new FormControl("", [Validators.required])
  });

  observeCloseModal: Observable<any>| undefined;

  constructor(
    public _gCf: globalConfig,
    private _router: Router,
    private _httpService: HttpService,
    public popupService: PopupService,
    private sanitizer: DomSanitizer
  ) {
    this._gCf.setcurrentTest = 1;
    this.observeCloseModal = this.popupService.observeCloseModal();
  }
  
  ngOnInit(): void {

    this._gCf.spinner(false);

    /***** Intro background image *****/
    // document.body.className = "intro";
    document.body.classList.add('intro');

    this.loadData();

    if(!this._gCf.getisUserExistinCurrentBatch){
      this.userNotExisttheBatchPopup();
    }

  }


  get _getIntroFormSubmitBtnStatus(): boolean {    
    return (this.introFormGroup.invalid || !this.validateEnteredBatchNumber || this.restrictNavigation);
  }

  get restrictNavigation(): boolean{
    return !this._gCf.getisUserExistinCurrentBatch || this._gCf.timestamp > this.batchEndDate;
  }


  // Check whether the batch data loaded or not
  async canExit() :  Promise<Observable<boolean> | boolean> {
    if(this._gCf.motilitySystemData && this._gCf.morphologySystemData_1 && this._gCf.morphologySystemData_2){
      return true;
    }
    return false;
  }



  async loadData(): Promise<void>{

    this._gCf.spinner(true);

    let data: { userID: string, batchNumber: string } = await this.getActivebatchData();

    if(this._gCf.getisUserExistinCurrentBatch){
      // Get current test
      this.getUserCurrectTestData(data);
    }else{ 
      // Disable form
      this.disableIntroForm();
      // Get last test
      // await this.getUserLastTestData(data);
    }

    this.isDataloaded = true;
    this._gCf.spinner(false);
    
  }


  getActivebatchData(): Promise<{ userID: string, batchNumber: string }>{

    return new Promise((resolve, reject) => {

      this._httpService.getActiveBatchData().then(async res1 => {

        if (res1) {

          this.activeBatchNumber = res1.id;

          this.batchEndDate = res1.data.endDate;

          // this._gCf.morphologySystemData = res1.data.morphologyTestFiles;

          this._gCf.morphologySystemData_1 = res1.data.morphologyTestFiles_1;
          this._gCf.morphologySystemData_2 = res1.data.morphologyTestFiles_2;

          this._gCf.setmotilitySystemData = res1.data.motilityTestFiles.sort((a, b) => (a.data.videoIndex > b.data.videoIndex) ? 1 : ((b.data.videoIndex > a.data.videoIndex) ? -1 : 0));

          // If Batch time ended
          if(this._gCf.timestamp > this.batchEndDate){   
            
            this.disableIntroForm();
            
            this._gCf.setisBatchExpired = true;
            
          }else{
            this._gCf.setisBatchExpired = false;
          }

          if(this._gCf.getisUserExistinCurrentBatch && this._gCf.timestamp > this.batchEndDate){
            this.submissionDeadlineEndedPopup();
            await this.modalSubscription();
          }

          return resolve({
            "userID": this._gCf.currentUser.id,
            "batchNumber": res1.id
          });

        } else {
          this._gCf.spinner(false);
          reject();
        }
      }, (err: any) => {
        this._gCf.spinner(false);
        reject();
      });

    });

  }


  getUserCurrectTestData(getUserTestData): Promise<void>{

    return new Promise((resolve, reject) => {
      /***** Get user test Data By 'UserId and BatchNumber' *****/
      this._httpService.getUserCurrectTestData(getUserTestData).then(async (res2: IntroTestDataRes) => {

        // If test found
        if (res2) {

          const {id, data} = res2;

          this._gCf.settestID = id;

          this._gCf.settestConfiguration = data.testConfiguration;
          
          this._gCf.settestLevel = data.testLevel;

          // Set Skipped levels
          if(data.skippedTestLevels){
            this._gCf.setskippedTestLevels = data.skippedTestLevels;
          }
          
          // Load Intro form data
          this.introFormGroup.patchValue({
            ...this._gCf.gettestConfiguration
          });   
          

        } else{
          // No test found
          this._gCf.settestLevel = 0;
        }

        resolve();

      });

    });
  }


  // getUserLastTestData(getUserTestData): Promise<void>{

  //   return new Promise((resolve, reject) => {
  //     /***** Get user test Data By 'UserId and BatchNumber' *****/
  //     this._httpService.getUserLastTestData({'userID':getUserTestData.userID}).then(async (res2: IntroTestDataRes) => {

  //       if (res2) {

  //         const {id, data} = res2;

  //         this._gCf.settestID = id;

  //         this._gCf.settestConfiguration = data.testConfiguration;
          
  //         // Load Into form data
  //         this.introFormGroup.patchValue({
  //           ...this._gCf.gettestConfiguration
  //         });            

  //       } 

  //       resolve();

  //     });

  //   });

  // }


  // Disable Batch if user not found in the batch, batch expired
  disableIntroForm(): void{
    this.introFormGroup.disable();
  }



  get validateEnteredBatchNumber(): boolean{ 
    // if(!this.activeBatchNumber) return false;
    let batchNumberControl = this.introFormGroup.get("batchNumber");

    if(batchNumberControl.touched || batchNumberControl.dirty){
      if(batchNumberControl.value != this.activeBatchNumber){
        return false;
      }else{
        return true;
      }
    }else{
      return true;
    }
  }

  get isValidbatchNumber(): boolean{
    let batchNumberControl = this.introFormGroup.get("batchNumber");
    return batchNumberControl.value == this.activeBatchNumber;
  }

  

  async onSelectChange(event: any, typeChangeName: string): Promise<void>{

    let newValue = event.srcElement.value;
    
    if( this._gCf.gettestLevel > 0 && !this.allowSelectionChanges){
      
      this.changeSettingsPopup();

      const text = await this.modalSubscription();

      if(text == "do-not-change-settings" || text == "close"){

        setTimeout(() => {

          switch(typeChangeName){
            case "whoCriteria":
              this.introFormGroup.patchValue({
                "whoCriteria" : this._gCf.gettestConfiguration[typeChangeName]
              });
              break;

            case "morphologyCriteria":
              this.introFormGroup.patchValue({
                "morphologyCriteria" : this._gCf.gettestConfiguration[typeChangeName]
              });
              break;

            case "concentrationDevice":
              this.introFormGroup.patchValue({
                "concentrationDevice" : this._gCf.gettestConfiguration[typeChangeName]
              });
              break;

          }
          
        }, 10);

      } else if( text == "change-settings"){
        this.allowSelectionChanges = true;

        this._gCf.spinner(true);

        this._httpService.deleteUserTestDataSingle(this._gCf.gettestID).then(res => {
          
          this._gCf.resetTestData();
          this.introFormGroup.patchValue({
            whoCriteria: '',
            morphologyCriteria: '',
            concentrationDevice: ''
          });

          switch(typeChangeName){

            case "whoCriteria":
              this.introFormGroup.patchValue({whoCriteria: newValue});
              break;

            case "morphologyCriteria":
              this.introFormGroup.patchValue({morphologyCriteria: newValue});
              break;

            case "concentrationDevice":
              this.introFormGroup.patchValue({concentrationDevice: newValue});
              break;
          }

          this._gCf.spinner(false);

        });
        
      }
    
    }

  }



  async startTesting(): Promise<void>{

    if(!this._gCf.getisUserExistinCurrentBatch || this._gCf.getisBatchExpired){
      return;
    }

    const systemConfiguration: SystemConfiguration = await this._getSystemConfiguration;

    const data = {
      userID: this._gCf.currentUser.id,
      testConfiguration: this.introFormGroup.value,
      systemConfiguration: systemConfiguration,
    }

    this._gCf.settestConfiguration = this.introFormGroup.value;

    if(this._gCf.gettestID === null){

      this.confirmInformationPopup();

      const text = await this.modalSubscription();
      
      if(text == "confirm"){
        this.saveIntroData(data);
      }

    } else {

      this._gCf.spinner(false);
      this._router.navigate(["/proficiency/motility"]);
    }
    
  }

  saveIntroData(data): void{

    console.log('-----saveIntroData-------');
    console.log('-------------this._gCf.canUpdateTestLevel----------', this._gCf.canUpdateTestLevel);
    
    console.log("--------gettestLevel-------",this._gCf.gettestLevel);
    console.log("-------getcurrentTest------",this._gCf.getcurrentTest);

    if(this._gCf.canUpdateTestLevel){
      Object.assign(data, {"testLevel": 1});
      console.log('-----INSIDE IF-------');
    } else{
      console.log('-----INSIDE ELSE-------');
      this._router.navigate(["/proficiency/motility"]);
      return;
    }
    
    this._gCf.spinner(true);
    this._httpService.saveIntroData(data).then(res => {

      console.log('-----http saveIntroData-------');
      
      console.log(res);

      const { id } = res;
      this._gCf.settestLevel = 1;
      this._gCf.settestID = id;
      this._gCf.spinner(false);

      this._router.navigate(["/proficiency/motility"]);
    }).catch(err => {
      this._gCf.spinner(false);
      alert("Something wrong");
    });

  }

  


  modalSubscription():Promise<string>{
    return new Promise((resolve, reject) => {
      const obs = this.observeCloseModal.subscribe((res: { text: string}) => {
        obs.unsubscribe();
        resolve(res.text);
      })
    });
  }


  get _getSystemConfiguration(): SystemConfiguration{

    var operatingSystem = "";
    if (navigator.appVersion.indexOf("Win") != -1) operatingSystem =
      "Windows OS";
    if (navigator.appVersion.indexOf("Mac") != -1) operatingSystem =
      "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) operatingSystem =
      "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") != -1) operatingSystem =
      "Linux OS";

    //console.log(operatingSystem);

    let ua = navigator.userAgent;

    let x = ua.indexOf("MSIE");
    let y = 0;
    let browserName = "";
    let version = "";

    if (x == -1) {
      x = ua.indexOf("Edge");
      y = 0;

      if (x == -1) {
        x = ua.indexOf("Firefox");
        y = 0;

        if (x == -1) {
          x = ua.indexOf("Chrome");
          y = 6;


          if (x == -1) {
            x = ua.indexOf("Opera");
            y = 5;


            if (x == -1) {
              x = ua.indexOf("Safari");
              y = 0;

              if (x != -1) {
                x = ua.indexOf("Version");
                y = 7;
                browserName = "Safari";

              } else {
                browserName = "";
              }
            } else {
              browserName = "Opera";
            }
          } else {
            browserName = "Chrome";
          }
        } else {

          browserName = "Firefox";
        }

      } else {
        browserName = "Edge";
      }

    } else {
      browserName = "IE";
    }

    if (x != -1) {

      if (y != 0) {
        y++;
        ua = ua.substring(x + y);
        x = ua.indexOf(" ");
        var x2 = ua.indexOf("(");
        if (x2 > 0 && x2 < x) x = x2;
        x2 = ua.indexOf(";");
        if (x2 > 0 && x2 < x) x = x2;
        if (x == -1) document.write("Error");

        version = ua.substring(0, x);
      }
    }

    return {
      "browserModel": browserName,
      "browserVersion": version,
      "operatingSystem": operatingSystem,
      "screenWidth": screen.width,
      "screenHeight": screen.height,
    }

  }


  // User Not Exist the Batch Popup
  userNotExisttheBatchPopup(): void{

    this.popupService.openModal(
      { 
        isNobackdrop: true,
        showCloseIcon: true,
        heading: "NO ACTIVE CHALLENGE",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text:  this.sanitizer.bypassSecurityTrustHtml(`iPRO is waiting for the next challenge. If you are a registered iPRO user, we will contact you
            via email when it is released. If you want to register for the next challenge,
            <a href="/#/registration" routerLink="/registration" target="_blank" style="cursor:pointer; color: rgb(60, 149, 236); text-decoration: underline rgb(60, 149, 236);">please follow this link</a>.
          `),
          style: {'margin': 0}
        },
        buttons: [
          {
            key: "close",
            text: "CLOSE",
            class: "btn-primary"
          }
        ]
      }
    );

    this._gCf.popupRemoveUpperCase();

  }


  
  // Change Settings Popup
  changeSettingsPopup(): void{

    this.popupService.openModal(
      { 
        isNobackdrop: true,
        showCloseIcon: true,
        heading: "CHANGE SETTINGS",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "CHANGING THE SETTINGS WILL RESET ALL COUNTS AND REMOVE ALL RESULTS.",
          style: {}
        },
        content_2: {
          text: "WOULD YOU LIKE TO CONTINUE?",
          style: {}
        },
        buttons: [
          {
            key: "change-settings",
            text: "CHANGE SETTINGS",
            class: "btn-primary"
          },
          {
            key: "do-not-change-settings",
            text: "DO NOT CHANGE SETTINGS",
            class: "btn-primary"
          }
        ]
      }
    );

  }


  // Criteria selection confirm Popup
  confirmInformationPopup(): void{

    this.popupService.openModal(
      {
        showCloseIcon: true,
        heading: "CONFIRM INFORMATION",
        content_1: { text: "Please confirm the semen analysis practices in your lab:", style: {}},
        content_2: { text: "", style: {}},
        lists2: [
          { name: "WHO CRITERIA", value: this.introFormGroup.get("whoCriteria").value},
          { name: "CONCENTRATION", value: this.introFormGroup.get("concentrationDevice").value },
          { name: "MORPHOLOGY", value: this.introFormGroup.get("morphologyCriteria").value },
          
        ],
        buttons: [
          {
            key: "re-select",
            text: "RE-SELECT",
            class: "btn-primary"
          },
          {
            key: "confirm",
            text: "CONFIRM",
            class: "btn-primary"
          }
        ]
      }

    );

    this._gCf.popupRemoveUpperCase();

  }


  // Submission Deadline Ended Popup
  submissionDeadlineEndedPopup(): void{

    this.popupService.openModal(
      { 
        isNobackdrop: true,
        showCloseIcon: true,
        heading: "SUBMISSION DEADLINE PASSED",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "THE SUBMISSION DEADLINE FOR THIS BATCH NUMBER HAS PASSED",
          style: {}
        },
        buttons: [
          {
            key: "close",
            text: "CLOSE",
            class: "btn-primary"
          }
        ]
      }
    );

    this._gCf.popupRemoveUpperCase();

  }



}