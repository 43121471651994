import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ResizeObserver from 'resize-observer-polyfill';
import { fromEvent, Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';

import { globalConfig } from 'src/app/globalConfig';
import { HttpService } from 'src/app/shared/services/http.service';
import { PopupService } from 'src/app/shared/components/popup/popup.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CanComponentDeactivate } from 'src/app/shared/guard/route/loading/loading-guard.service';

interface MorphData {
  fileName: string;
  imageIndex: number;
  normalSpermCount: number;
  abnormalSpermCount: number;
  replicateNum: number;
  imageId: string;
  stateChanged: boolean;
}

interface UserMorphData {
  imageIndex: number;
  normalCount: number;
  abnormalCount: number;
  replicateNum: number;
}

interface ClickHistory {
  type: string;
  currentCount: number;
  maxCount: number;
}

interface TotalCount {
  replicate1: {
    totalCount: number;
    normalSpermCount: number;
    abnormalSpermCount: number;
  };
  replicate2: {
    totalCount: number;
    normalSpermCount: number;
    abnormalSpermCount: number;
  };
}

@Component({
  selector: 'app-morphology',
  templateUrl: './morphology.component.html',
  styleUrls: ['./morphology.component.scss'],
})
export class MorphologyComponent
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  imageSizeObserver: ResizeObserver;
  containerSizeObserver: ResizeObserver;
  challengeId = 1;
  morphologyCriteria: string;
  isClickCounting = true;
  imageElemSize: { width: number; height: number };
  isShowingGrid = false;
  morphImages: MorphData[];
  activeMorphImage: MorphData;
  activeImageIndex: number; /* Current selected images imageIndex */
  popupClickSubsciption: Subscription;
  isSecondReplicate = false;
  mainImageLoading = false;
  showExceedLimitReplicate1 = true;
  showExceedLimitReplicate2 = true;
  isZoomImage = false;
  IMAGE_REF_WIDTH = 1280;
  IMAGE_REF_HEIGHT = 1024;
  inputHistory: ClickHistory[] = [];
  // How far morphology image is counted by the user(hold the highest imageIndex)...
  highestMorphCountIndex = 0;
  // Will hold the overall sperm counts...
  totalSpermCount: TotalCount = {
    replicate1: {
      totalCount: 0,
      normalSpermCount: 0,
      abnormalSpermCount: 0,
    },
    replicate2: {
      totalCount: 0,
      normalSpermCount: 0,
      abnormalSpermCount: 0,
    },
  };

  // Zoom related variables...
  pointerEventSubsciption: Subscription;
  ZOOM_LEVEL = 2;
  CONTENT_BOX_WIDTH: number;
  CONTENT_BOX_HEIGHT: number;
  ZOOM_BOX_WIDTH: number;
  ZOOM_BOX_HEIGHT: number;

  @ViewChild('image', { static: false }) image: ElementRef;
  @ViewChild('canvasGrid', { static: false }) canvasGrid: ElementRef;
  @ViewChild('imageSlider', { static: false }) imageSlider: ElementRef;
  @ViewChild('zoomLens', { static: false }) zoomLens: ElementRef;
  @ViewChild('videoControls', { static: false }) videoControls: ElementRef;
  @ViewChild('mainContainer', { static: false }) mainContainer: ElementRef;
  @ViewChild('mainSection', { static: false }) mainSection: ElementRef;
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  @ViewChild('imgSliderContainer', { static: false })
  imageSliderContainer: ElementRef;
  @ViewChild('thumbnailImgContainer', { static: false })
  imgThumbnailContainer: ElementRef;
  @ViewChild('mainImageLoadingWrapper', { static: false })
  mainImageLoadingWrapper: ElementRef;

  @ViewChildren('activeImageBorder', { read: ElementRef })
  imageBorders: QueryList<ElementRef>;
  @ViewChildren('sliderImage', { read: ElementRef })
  sliderImages: QueryList<ElementRef>;

  get disableUndo() {
    if (!this.activeMorphImage) return true;
    if (this.inputHistory.length > 0) {
      for (let i = 0; i < this.inputHistory.length; i++) {
        if (this.inputHistory[i].currentCount !== 0) return false;
      }
    }
    return true;
  }

  get disableRedo() {
    if (!this.activeMorphImage) return true;
    if (this.inputHistory.length > 0) {
      for (let i = this.inputHistory.length - 1; i >= 0; i--) {
        if (this.inputHistory[i].currentCount < this.inputHistory[i].maxCount) {
          return false;
        }
      }
    }
    return true;
  }

  get disableClearAll() {
    if (!this.activeMorphImage) return true;
    return (
      this.activeMorphImage.normalSpermCount === 0 &&
      this.activeMorphImage.abnormalSpermCount === 0
    );
  }

  get disableNextBtn() {
    if (
      !this.morphImages ||
      (this.morphImages && this.morphImages.length === 0) ||
      this.activeImageIndex ===
        this.morphImages[this.morphImages.length - 1].imageIndex
    )
      return true;
    return false;
  }

  get disableReplicateBtn() {
    return (
      this.isSecondReplicate ||
      !this.morphImages ||
      (this.morphImages &&
        this.highestMorphCountIndex ===
          this.morphImages[this.morphImages.length - 1].imageIndex) ||
      this.totalSpermCount.replicate1.totalCount === 0
    );
  }

  get disableResetBtn() {
    return (
      this.totalSpermCount.replicate1.totalCount +
        this.totalSpermCount.replicate2.totalCount ===
      0
    );
  }

  get totalSperm() {
    return (
      this.totalSpermCount.replicate1.totalCount +
      this.totalSpermCount.replicate2.totalCount
    );
  }

  get totalNormal() {
    return (
      this.totalSpermCount.replicate1.normalSpermCount +
      this.totalSpermCount.replicate2.normalSpermCount
    );
  }

  get totalAbnormal() {
    return (
      this.totalSpermCount.replicate1.abnormalSpermCount +
      this.totalSpermCount.replicate2.abnormalSpermCount
    );
  }

  get morphologyFileLink() {
    return this.gcf.morphFile;
  }

  get isOutsideWhoRange() {
    // Replicate1 normal sperm percentage...
    const replicate1NormalSpermPercentage = Math.round(
      (this.totalSpermCount.replicate1.normalSpermCount /
        this.totalSpermCount.replicate1.totalCount) *
        100
    );

    // Replicate2 normal sperm percentage...
    const replicate2NormalSpermPercentage = Math.round(
      (this.totalSpermCount.replicate2.normalSpermCount /
        this.totalSpermCount.replicate2.totalCount) *
        100
    );

    // Average normal sperm percentage...
    const averageNormalSpermPercentage = Math.round(
      (replicate1NormalSpermPercentage + replicate2NormalSpermPercentage) / 2
    );

    // Minimum limit...
    let minLimit: number;
    if (
      averageNormalSpermPercentage -
        1.96 *
          Math.sqrt(
            (averageNormalSpermPercentage *
              (100 - averageNormalSpermPercentage)) /
              (this.totalSpermCount.replicate1.normalSpermCount +
                this.totalSpermCount.replicate1.abnormalSpermCount +
                this.totalSpermCount.replicate2.normalSpermCount +
                this.totalSpermCount.replicate2.abnormalSpermCount)
          ) <
      0
    ) {
      minLimit = 0;
    } else {
      minLimit = Math.round(
        averageNormalSpermPercentage -
          1.96 *
            Math.sqrt(
              (averageNormalSpermPercentage *
                (100 - averageNormalSpermPercentage)) /
                (this.totalSpermCount.replicate1.normalSpermCount +
                  this.totalSpermCount.replicate1.abnormalSpermCount +
                  this.totalSpermCount.replicate2.normalSpermCount +
                  this.totalSpermCount.replicate2.abnormalSpermCount)
            )
      );
    }

    // Maximum limit...
    const maxLimit = Math.round(
      averageNormalSpermPercentage +
        1.96 *
          Math.sqrt(
            (averageNormalSpermPercentage *
              (100 - averageNormalSpermPercentage)) /
              (this.totalSpermCount.replicate1.normalSpermCount +
                this.totalSpermCount.replicate1.abnormalSpermCount +
                this.totalSpermCount.replicate2.normalSpermCount +
                this.totalSpermCount.replicate2.abnormalSpermCount)
          )
    );

    return (
      Math.abs(
        replicate1NormalSpermPercentage - replicate2NormalSpermPercentage
      ) >
      maxLimit - minLimit
    );
  }

  constructor(
    private renderer: Renderer2,
    private gcf: globalConfig,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    private httpService: HttpService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.gcf.spinner(false);
    this.mainImageLoading = true;
    // Loading test configuration...
    this.morphologyCriteria = this.gcf.testConfiguration.morphologyCriteria;

    // Loading different images depending on the challengeId and updating testLevel...
    this.route.params.subscribe((params) => {
      this.challengeId = +params.challangeId;
      this.getAllImageDatas();
      if (this.challengeId === 1) {
        this.commonService.updateTestLevel(3);
      } else {
        this.commonService.updateTestLevel(4);
      }
    });

    // Listening for popups key press...
    this.popupClickSubsciption = this.popupService.$ClosePopupModal.subscribe(
      async (result: { text: string }) => {
        console.log(result);
        switch (result.text) {
          // case 'addReplicate':
          //   this.addReplicate();
          //   break;
          case 'resetYes':
            this.resetAll();
            break;
          case 'clearYes':
            this.clearAll();
            break;
          case 'replicateYes':
            this.addReplicate();
            break;
          case 'saveYes-skipped':
            await this.saveAndContinue('add');
            break;
          case 'saveYes':
            await this.saveAndContinue('remove');
            break;
          case 'no-thanks':
            await this.saveAndContinue('remove');
            break;
          case 'reviewSave':
            this.saveAndContinue('remove');
            break;
          case 'ok-limitCross1':
            this.showExceedLimitReplicate1 = false;
            break;
          case 'ok-limitCross2':
            this.showExceedLimitReplicate2 = false;
            break;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    const imageWrapperWidth = (this.image.nativeElement as HTMLElement)
      .clientWidth;
    this.setImageElementHeight(imageWrapperWidth);

    // Resizing image placeholder height...
    this.imageSizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      this.setImageElementHeight(width);
      // Redraw grid on image size change...
      if (this.isShowingGrid) {
        this.drawGrid();
      }
      // Change zoom settings if zoom is opened...
      if (this.isZoomImage) {
        this.setDefaultZoomSettings();
      }
    });
    this.imageSizeObserver.observe(this.image.nativeElement);

    // Dynamically setting whole container height and width to prevent scrolling...
    this.calculateContainerHeightAndWidth();

    const calculateContainerWithDebounce = this.debounceContainerCalculation();

    this.containerSizeObserver = new ResizeObserver((entries) => {
      calculateContainerWithDebounce();
    });

    this.containerSizeObserver.observe(this.mainSection.nativeElement);
  }

  async canExit(): Promise<boolean> {
    if (
      !this.morphImages ||
      (this.morphImages && this.morphImages.length === 0)
    )
      return true;
    // Check if have to update skipped test or not...
    let result = 'add';
    for (const eachImage of this.morphImages) {
      if (eachImage.abnormalSpermCount + eachImage.normalSpermCount > 0) {
        result = 'remove'; /* Remove from skipped steps list(Data counted) */
        break;
      }
    }
    const currTest = this.challengeId === 1 ? 3 : 4;
    this.commonService.skippedTestfn(result, currTest);

    await this.saveToDatbase();
    return true;
  }

  calculateContainerHeightAndWidth() {
    const MIN_THRESHOLD_WIDTH = 750;
    // Resetting the width before calculate...
    this.renderer.removeStyle(this.mainContainer.nativeElement, 'width');
    this.renderer.removeStyle(this.mainContainer.nativeElement, 'margin');
    this.renderer.removeClass(
      this.mainContainer.nativeElement,
      'section-main-bg'
    );

    // Don't calculate if width less than 750 since it's the min width...
    if (
      (this.mainContainer.nativeElement as HTMLElement).clientWidth <
      MIN_THRESHOLD_WIDTH
    ) {
      this.renderer.setStyle(this.mainContainer.nativeElement, 'margin', '0');
      // If page has both vertical and horizontal scrolling it will create problem for
      // the background image(So adding background image in the container itself)...
      // In min-width the section height will be 470px...
      if ((this.mainSection.nativeElement as HTMLElement).clientHeight < 470) {
        this.renderer.addClass(
          this.mainContainer.nativeElement,
          'section-main-bg'
        );
      } else {
        this.renderer.removeClass(
          this.mainContainer.nativeElement,
          'section-main-bg'
        );
      }
      return;
    }

    const imgHeight =
      ((this.image.nativeElement as HTMLElement).clientWidth *
        this.IMAGE_REF_HEIGHT) /
      this.IMAGE_REF_WIDTH;
    const videoControlsHeight = (
      this.videoControls.nativeElement as HTMLElement
    ).clientHeight;
    const imgSliderHeight = (
      this.imgThumbnailContainer.nativeElement as HTMLElement
    ).clientHeight;
    const padding = 20; // top, bottom 10px
    const totalHeight =
      imgHeight + videoControlsHeight + imgSliderHeight + padding + 4;

    const maxHeight = window.innerHeight - 37; // header height 37px

    // If container height exceeds...hence resulting to scroll...
    if (totalHeight > maxHeight) {
      const expectedImgHeight =
        maxHeight - 20 - imgSliderHeight - videoControlsHeight - 4;
      const expectedImgWidth =
        (this.IMAGE_REF_WIDTH / this.IMAGE_REF_HEIGHT) * expectedImgHeight;
      const mainContainerWidth = Math.round(expectedImgWidth * 2);
      // If it's more than min-width(750px) - margin(2 * 10px)...
      if (mainContainerWidth > MIN_THRESHOLD_WIDTH - 20) {
        this.renderer.setStyle(
          this.mainContainer.nativeElement,
          'width',
          `${mainContainerWidth}px`
        );
        this.renderer.setStyle(
          this.mainContainer.nativeElement,
          'margin',
          '10px auto'
        );
      } else {
        // Else if it's less than min-width...
        this.renderer.setStyle(
          this.mainContainer.nativeElement,
          'width',
          `${
            MIN_THRESHOLD_WIDTH + 25
          }px` /* Adding 750 + 25px(for margin 20px and borders 5px, 
                increasing the width little bit so height also increase fill the whole page height) */
        );
        this.renderer.setStyle(
          this.mainContainer.nativeElement,
          'margin',
          '0 auto'
        );
      }
    }
  }

  // Calculate container height and width with 200ms debouncing...
  debounceContainerCalculation() {
    let timeOut: ReturnType<typeof setTimeout>;
    const containerCalculation =
      this.calculateContainerHeightAndWidth.bind(this);
    return function calculate() {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(containerCalculation, 200);
    };
  }

  getAllImageDatas() {
    // Loading all the related images and merging admin images with user inputed images...
    const totalImageDatas =
      this.challengeId === 1
        ? this.gcf.morphologySystemData_1
        : this.gcf.morphologySystemData_2;
    if (!totalImageDatas || (totalImageDatas && totalImageDatas.length === 0))
      return;

    // Since morphologyCriteria using long form and type is using short form...
    let morphType: string;
    switch (this.morphologyCriteria) {
      case 'Papanicolau':
        morphType = 'pap';
        break;
      case 'Diff-Quik':
        morphType = 'diff';
        break;
      case 'Pre-Stained Slide':
        morphType = 'pre';
        break;
      default:
        morphType = 'pap';
    }

    // Filtering the perticular images...
    const mappedTotalImageDatas = totalImageDatas
      .filter((eachItem) => {
        return eachItem.data.type === morphType;
      })
      .map((eachData) => {
        return {
          fileName: eachData.data.fileName,
          imageIndex: +eachData.data.imageIndex /* For safety */,
          imageId: eachData.id,
          normalSpermCount: 0,
          abnormalSpermCount: 0,
          stateChanged: false,
          replicateNum: 1,
        };
      });

    // Retreive the user counted image data's from database...
    this.httpService
      .getUserMorphologyTestData(
        this.gcf.testID,
        undefined,
        `morphologyTest${this.challengeId}`
      )
      .then(
        (
          result: {
            id: string;
            data: UserMorphData;
          }[]
        ) => {
          console.log(result);
          if (result.length > 0) {
            for (const [
              index,
              eachAdminImage,
            ] of mappedTotalImageDatas.entries()) {
              for (const eachUserImage of result) {
                // Set highest morph image count index that user counted by far...
                if (
                  eachUserImage.data.imageIndex > this.highestMorphCountIndex
                ) {
                  this.highestMorphCountIndex = eachUserImage.data.imageIndex;
                }

                if (eachAdminImage.imageId === eachUserImage.id) {
                  mappedTotalImageDatas[index] = {
                    ...eachAdminImage,
                    normalSpermCount: eachUserImage.data.normalCount,
                    abnormalSpermCount: eachUserImage.data.abnormalCount,
                    replicateNum: eachUserImage.data.replicateNum,
                  };
                }
              }
            }
          }

          // Sorting the morphology images...
          const sortedMorphImages = mappedTotalImageDatas.sort(
            (a, b) => a.imageIndex - b.imageIndex
          );

          // Update the replicate2 in the rest of the images if there is any data present with
          // replicateNum 2...
          let replicate2startImageIndex = null;
          sortedMorphImages.forEach((eachImage, index) => {
            if (replicate2startImageIndex === null) {
              if (eachImage.replicateNum === 2) {
                replicate2startImageIndex = index;
                this.isSecondReplicate = true;
              }
            } else {
              if (eachImage.imageIndex > replicate2startImageIndex) {
                sortedMorphImages[index].replicateNum = 2;
              }
            }
          });

          this.morphImages = sortedMorphImages;

          // Calculate the total count...
          this.calculateCount();

          // Set the current active morph image to the last image that was counted...
          let itemIndex = this.morphImages.findIndex(
            (item) => item.imageIndex === this.highestMorphCountIndex
          );
          // If by any chance unable to find what was the last item that was calculated,
          // than set it to the first item of the morph images...
          if (itemIndex === -1) {
            itemIndex = 0;
            this.highestMorphCountIndex = this.morphImages[0].imageIndex;
          }
          this.activeMorphImage = this.morphImages[itemIndex];
          this.activeImageIndex = this.highestMorphCountIndex;
          this.activeMorphImage.stateChanged = true;

          this.cdr.detectChanges();

          // Adding active border on the active image from image slider...
          if (this.sliderImages.length > 0) {
            fromEvent(
              this.sliderImages.toArray()[itemIndex].nativeElement,
              'load'
            ).subscribe((_) => {
              this.renderer.setStyle(
                this.imageBorders.toArray()[itemIndex].nativeElement,
                'display',
                'block'
              );

              // Scroll the image slider to make the active image in view if not...
              // Adding timeout to finish the routing animations scale and slide-in transition first...
              setTimeout(() => {
                this.calculateSliderScroll(
                  this.imageBorders.toArray()[itemIndex].nativeElement
                );
              }, 400);
            });
          }
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  // For setting the image placeholder height proportional to its width...
  setImageElementHeight(width: number) {
    // Setting image slider width...
    this.renderer.setStyle(
      this.imageSliderContainer.nativeElement,
      'width',
      `${width}px`
    );

    const imageWrapperHeight = Math.round(
      width * (this.IMAGE_REF_HEIGHT / this.IMAGE_REF_WIDTH)
    );
    this.imageElemSize = { width, height: imageWrapperHeight };
    this.renderer.setStyle(
      this.image.nativeElement,
      'height',
      `${imageWrapperHeight}px`
    );

    // Setting main image loading wrapper height...
    if (this.mainImageLoadingWrapper) {
      this.renderer.setStyle(
        this.mainImageLoadingWrapper.nativeElement,
        'height',
        `${imageWrapperHeight}px`
      );
    }
  }

  activateCounter(counterType: 'tallyCounter' | 'labelCounter') {
    if (!this.activeMorphImage) return;
    // Do nothing if click on the already activated counter type...
    if (
      (counterType === 'tallyCounter' && this.isClickCounting) ||
      (counterType === 'labelCounter' && !this.isClickCounting)
    )
      return;

    this.isClickCounting = counterType === 'tallyCounter';
    this.inputHistory = [];
  }

  showSeperateLine(currentItemIndex: number) {
    if (!this.morphImages) return false;
    if (
      this.morphImages[currentItemIndex] &&
      this.morphImages[currentItemIndex + 1]
    ) {
      return (
        this.morphImages[currentItemIndex].replicateNum !==
        this.morphImages[currentItemIndex + 1].replicateNum
      );
    }
    return false;
  }

  drawGrid() {
    if (this.canvasGrid) {
      this.canvasGrid.nativeElement.width = this.imageElemSize.width;
      this.canvasGrid.nativeElement.height = this.imageElemSize.height;
      const ctx = (
        this.canvasGrid.nativeElement as HTMLCanvasElement
      ).getContext('2d');
      const eachGridBoxWidth = +(this.imageElemSize.width / 5).toFixed();
      const eachGridBoxHeight = +(this.imageElemSize.height / 4).toFixed();
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 2;
      let columnCounting = 0;
      let rowCounting = 0;

      // Drawing Lines in Rows
      for (
        let i = eachGridBoxHeight;
        i < this.imageElemSize.height;
        i += eachGridBoxHeight
      ) {
        rowCounting += 1;
        if (rowCounting < 4) {
          ctx.moveTo(0, i);
          ctx.lineTo(this.imageElemSize.width, i);
          ctx.stroke();
        }
      }

      // Drawing Lines in Columns
      for (
        let i = eachGridBoxWidth;
        i < this.imageElemSize.width;
        i += eachGridBoxWidth
      ) {
        columnCounting += 1;
        if (columnCounting < 5) {
          ctx.moveTo(i, 0);
          ctx.lineTo(i, this.imageElemSize.height);
          ctx.stroke();
        }
      }
    }
  }

  showGrid() {
    this.isShowingGrid = !this.isShowingGrid;
    // Close zoom if it was turned on...
    if (this.isZoomImage) {
      this.zoomImage();
    }

    if (this.isShowingGrid) {
      this.cdr.detectChanges();
      this.drawGrid();
    }
  }

  onClickImage(elem: Element, index: number) {
    // Do nothing if we clicked the already activated image...
    if (this.activeMorphImage.imageIndex === this.morphImages[index].imageIndex)
      return;
    // Do nothing if clicked image index is higher than highest image index that was
    // counted before or if replicate2 activated and clicking on replicate1...
    if (this.morphImages[index].imageIndex > this.highestMorphCountIndex)
      return;
    if (this.isSecondReplicate && this.morphImages[index].replicateNum === 1)
      return;

    // Clear zoom and grid...
    this.clearZoomAndGrid();

    this.imageBorders.forEach((eachElem) => {
      this.renderer.removeStyle(eachElem.nativeElement, 'display');
    });
    this.renderer.setStyle(elem, 'display', 'block');
    this.activeMorphImage = this.morphImages[index];
    this.activeMorphImage.stateChanged = true;
    this.activeImageIndex = this.morphImages[index].imageIndex;
    // Show image loading...
    this.mainImageLoading = true;

    // Reset the input history...
    this.inputHistory = [];
  }

  getDisableImage(index: number) {
    if (this.morphImages[index].imageIndex > this.highestMorphCountIndex)
      return true;
    if (this.isSecondReplicate && this.morphImages[index].replicateNum === 1)
      return true;
    return false;
  }

  increaseCount(countType: string) {
    if (!this.isClickCounting || !this.activeMorphImage) return;
    // Show maximum 3 digit popup if value is more than 999...
    if (+this.activeMorphImage[countType] + 1 > 999) {
      this.popupService.$PopupModal.next({
        isNobackdrop: true,
        heading: 'Maximum Digits',
        icon: {
          name: 'error',
          color: 'red',
        },
        content_1: {
          text: 'Please enter a maximum of 3 digits or less',
          style: {},
        },
        buttons: [
          {
            text: 'OK',
            key: 'digitOk',
            class: 'btn-primary',
          },
        ],
      });
      return;
    }

    this.activeMorphImage[countType] = +this.activeMorphImage[countType] + 1;

    // Increase the count on the input history...
    if (this.inputHistory.length > 0) {
      // If last click history's type is same as current countType than just increase
      // the count otherwise create new object and push to input history...
      const lastObj = this.inputHistory[this.inputHistory.length - 1];
      if (lastObj.type === countType) {
        lastObj.currentCount += 1;
        lastObj.maxCount = lastObj.currentCount;
      } else {
        this.inputHistory.push({
          type: countType,
          currentCount: 1,
          maxCount: 1,
        });
      }
    } else {
      this.inputHistory.push({ type: countType, currentCount: 1, maxCount: 1 });
    }

    // Calculate the total counts...
    this.calculateCount();
  }

  checkCountLimitExceeded() {
    // Check for count reached 200(Don't check replicate1's 200 if second replicate added)
    // and different message for replicate1 and replicate2...
    if (
      (this.totalSpermCount.replicate1.totalCount >= 200 &&
        !this.isSecondReplicate &&
        this.showExceedLimitReplicate1) ||
      (this.totalSpermCount.replicate2.totalCount >= 200 &&
        this.showExceedLimitReplicate2)
    ) {
      this.popupService.$PopupModal.next({
        isNobackdrop: true,
        heading: this.isSecondReplicate ? 'Counted Cells' : 'Add Replicate',
        icon: {
          name: 'done',
          color: '#f39e00',
        },
        // Messages for replicate number 1...
        ...(!this.isSecondReplicate && {
          content_1: {
            text: 'You have counted 200 sperm cells',
            style: {},
          },
          content_2: {
            text: 'For replicate #1',
            style: {},
          },
          content_3:
            'Click "ADD REPLICATE #2" to assess another 200 sperm cells per who guidelines',
        }),
        // Messages for replicate number 2...
        ...(this.isSecondReplicate && {
          content_1: { text: 'You have counted 200 sperm cells', style: {} },
        }),
        buttons: [
          {
            text: 'OK',
            key: `ok-limitCross${this.isSecondReplicate ? '2' : '1'}`,
            class: 'btn-primary',
          },
        ],
      });
      return true;
    }
    return false;
  }

  onClickNextField() {
    if (!this.morphImages || this.morphImages.length === 0) return;
    // Clear zoom and grid...
    this.clearZoomAndGrid();

    // Current active image index...
    const currentImageIndex = this.morphImages.findIndex(
      (eachItem) => eachItem.imageIndex === this.activeMorphImage.imageIndex
    );

    if (currentImageIndex < this.morphImages.length - 1) {
      // Check for count limit exceeded...
      if (this.checkCountLimitExceeded()) return;

      this.activeMorphImage = this.morphImages[currentImageIndex + 1];
      this.activeMorphImage.stateChanged = true;
      this.activeImageIndex = this.activeMorphImage.imageIndex;
      // Show the image loading...
      this.mainImageLoading = true;

      // Inrease the highest image count index...
      if (this.activeMorphImage.imageIndex > this.highestMorphCountIndex) {
        this.highestMorphCountIndex = this.activeMorphImage.imageIndex;
      }

      // Activating the next items border...
      this.imageBorders.forEach((eachElem) => {
        this.renderer.removeStyle(eachElem.nativeElement, 'display');
      });
      this.renderer.setStyle(
        this.imageBorders.toArray()[currentImageIndex + 1].nativeElement,
        'display',
        'block'
      );
      // Scroll the image slider to make the active image in view if not...
      this.calculateSliderScroll(
        this.imageBorders.toArray()[currentImageIndex + 1].nativeElement
      );

      // Reset the input history...
      this.inputHistory = [];
    }
  }

  onClickReplicate() {
    if (this.disableReplicateBtn) return;
    this.popupService.$PopupModal.next({
      isNobackdrop: true,
      heading: 'Replicate #1 Results',
      icon: {
        name: 'error',
        color: 'red',
      },
      content_1: {
        text: 'When you add replicate #2, the results for replicate #1 can not be edited',
        style: {},
      },
      content_2: { text: 'Would you like to continue?', style: {} },
      buttons: [
        {
          text: 'YES',
          key: 'replicateYes',
          class: 'btn-primary',
        },
        {
          text: 'NO',
          key: 'replicateNo',
          class: 'btn-primary',
        },
      ],
    });
  }

  addReplicate() {
    if (this.disableReplicateBtn) return;
    this.isSecondReplicate = true;

    // Changing the replicate number of the images that are later of the current
    // image index...
    this.morphImages = this.morphImages.map((eachItem) => {
      if (eachItem.imageIndex > this.highestMorphCountIndex) {
        return { ...eachItem, replicateNum: 2 };
      } else {
        return { ...eachItem };
      }
    });

    // Activating the next field...
    this.cdr.detectChanges();
    // Since replicate will be always created after the highest morph image that
    // is counted...
    this.activeMorphImage = this.morphImages.find(
      (item) => item.imageIndex === this.highestMorphCountIndex
    );
    this.onClickNextField();
  }

  onUndo() {
    if (this.inputHistory.length === 0) return;

    // Try to decrease the count from the last item of array...
    for (let i = this.inputHistory.length - 1; i >= 0; i--) {
      if (this.inputHistory[i].currentCount > 0) {
        this.inputHistory[i].currentCount -= 1;
        this.activeMorphImage[this.inputHistory[i].type] -= 1;

        // Calculate total counts...
        this.calculateCount();
        break;
      }
    }
  }

  onRedo() {
    if (this.inputHistory.length === 0) return;

    // Try to increase the count from the first item of the array...
    for (const item of this.inputHistory) {
      if (item.currentCount < item.maxCount) {
        item.currentCount += 1;
        this.activeMorphImage[item.type] += 1;

        // Calculate the total counts...
        this.calculateCount();
        break;
      }
    }
  }

  onClearAll() {
    if (!this.activeMorphImage) return;
    this.popupService.$PopupModal.next({
      isNobackdrop: true,
      heading: 'Clear All',
      icon: {
        name: 'error',
        color: 'red',
      },
      content_1: {
        text: 'Do you want to use clear all to remove all of the results in the current field of view (FOV)?',
        style: {},
      },
      buttons: [
        {
          text: 'YES',
          key: 'clearYes',
          class: 'btn-primary',
        },
        {
          text: 'NO',
          key: 'clearNo',
          class: 'btn-primary',
        },
      ],
    });
  }

  clearAll() {
    if (!this.activeMorphImage) return;
    if (
      this.activeMorphImage.normalSpermCount === 0 &&
      this.activeMorphImage.abnormalSpermCount === 0
    )
      return;

    // Reset the counts of activeMorphImage...
    this.activeMorphImage.normalSpermCount = 0;
    this.activeMorphImage.abnormalSpermCount = 0;
    this.inputHistory = [];

    this.calculateCount();
  }

  onResetAll() {
    if (!this.morphImages || this.morphImages.length === 0) return;
    // Show the reset popup...
    this.popupService.$PopupModal.next({
      isNobackdrop: true,
      heading: 'Reset Count',
      icon: {
        name: 'error',
        color: 'red',
      },
      content_1: {
        text: 'Do you want to use reset to delete all of your morphology results?',
        style: {},
      },
      buttons: [
        {
          text: 'YES',
          key: 'resetYes',
          class: 'btn-primary',
        },
        {
          text: 'NO',
          key: 'resetNo',
          class: 'btn-primary',
        },
      ],
    });
  }

  resetAll() {
    if (!this.morphImages || this.morphImages.length === 0) return;

    this.httpService
      .resetCount(this.gcf.testID, `morphologyTest${this.challengeId}`)
      .then((_) => {
        this.morphImages.forEach((eachImage) => {
          eachImage.normalSpermCount = 0;
          eachImage.abnormalSpermCount = 0;
          eachImage.replicateNum = 1;
          eachImage.stateChanged = false;
        });

        this.inputHistory = [];
        this.isSecondReplicate = false;
        this.activeMorphImage = this.morphImages[0];
        this.highestMorphCountIndex = this.activeMorphImage.imageIndex;
        this.activeImageIndex = this.activeMorphImage.imageIndex;
        this.activeMorphImage.stateChanged = true;
        // Scroll back to at the beginning of the image slider...
        this.scrollbarRef.scrollXTo(0, 300).subscribe();
        // Activating the border...
        this.imageBorders.forEach((eachElem) => {
          this.renderer.removeStyle(eachElem.nativeElement, 'display');
        });
        this.renderer.setStyle(
          this.imageBorders.toArray()[0].nativeElement,
          'display',
          'block'
        );

        // Run the calculation...
        this.calculateCount();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onInputFocus(element: Element) {
    const value = (element as HTMLInputElement).value;
    if (+value === 0) {
      this.renderer.setProperty(element, 'value', '');
    }
  }

  onInputFocusOut(
    type: 'normalSpermCount' | 'abnormalSpermCount',
    element: Element,
    event: Event
  ) {
    const value = +(event.target as HTMLInputElement).value;
    if (value === 0) {
      this.renderer.setProperty(element, 'value', '0');
    }
    this.activeMorphImage[type] = value;

    // Update Count...
    this.calculateCount();
    this.cdr.detectChanges();
  }

  onKeyDown(value: string, event: Event) {
    const key = (event as KeyboardEvent).key;
    if (key === 'Backspace' || key === 'ArrowRight' || key === 'ArrowLeft')
      return true;
    if (!/[0-9]/.test(key)) return false;
    if (value.length > 2) {
      // Show Max 3 digit popup...
      this.popupService.$PopupModal.next({
        isNobackdrop: true,
        heading: 'Maximum Digits',
        icon: {
          name: 'error',
          color: 'red',
        },
        content_1: {
          text: 'Please enter a maximum of 3 digits or less',
          style: {},
        },
        buttons: [
          {
            text: 'OK',
            key: 'digitOk',
            class: 'btn-primary',
          },
        ],
      });
      return false;
    }
    return true;
  }

  // Run Calculation on Enter key press...
  onKeyUp(
    type: 'normalSpermCount' | 'abnormalSpermCount',
    element: Element,
    event: Event
  ) {
    const key = (event as KeyboardEvent).key;
    if (key === 'Enter') {
      this.onInputFocusOut(type, element, event);
    }
  }

  onInput(event: Event) {
    const pattern = /^[0-9]*$/;
    let value = (event.target as HTMLInputElement).value;
    if (!pattern.test(value)) {
      value = value.replace(/[^0-9]/g, '');
    }
  }

  clearZoomAndGrid() {
    // Reset grid...
    if (this.isShowingGrid) {
      this.isShowingGrid = false;
    }

    // Reset Zoom...
    if (this.isZoomImage) {
      this.zoomImage();
    }
  }

  calculateSliderScroll(activeImgElem: Element) {
    // Active Image with blue border...
    const imgElem = activeImgElem.getBoundingClientRect();
    // Image Slider Container...
    const wrapperElem =
      this.imageSliderContainer.nativeElement.getBoundingClientRect();
    // Active image's right side distance from the slider container...
    const IMG_DIST_FROM_WRAPPER = imgElem.right - wrapperElem.left;
    let padding = 8; // Css padding applied to the box...
    if (screen.width >= 1900) {
      padding = 12;
    } else if (screen.width >= 1700) {
      padding = 10;
    }
    // If active image's right is more than the container's width...
    if (IMG_DIST_FROM_WRAPPER + padding > wrapperElem.width) {
      const toBeScroll =
        IMG_DIST_FROM_WRAPPER -
        wrapperElem.width +
        padding +
        this.scrollbarRef.view.scrollLeft; // How much the slider were already scrolled
      this.scrollbarRef.scrollXTo(toBeScroll, 300).subscribe();
    } else if (IMG_DIST_FROM_WRAPPER - padding < imgElem.width) {
      // If active image's right is less than that image elem's width ( Mean
      // active image is far left from the image slider view area )...
      const toBeScroll =
        this.scrollbarRef.view.scrollLeft -
        wrapperElem.width +
        IMG_DIST_FROM_WRAPPER +
        padding;
      this.scrollbarRef.scrollXTo(toBeScroll, 300).subscribe();
    }
  }

  setDefaultZoomSettings() {
    // Setting the content box's (the portion of the image that will be reflected on the zoom
    // box) heigth and width (set it to 10%)...
    this.CONTENT_BOX_WIDTH = Math.round(this.imageElemSize.width * (10 / 100));
    this.CONTENT_BOX_HEIGHT = Math.round(
      this.imageElemSize.height * (10 / 100)
    );

    // Setting zoom box's height width based on the zoom level...
    this.ZOOM_BOX_WIDTH = this.CONTENT_BOX_WIDTH * this.ZOOM_LEVEL;
    this.ZOOM_BOX_HEIGHT = this.CONTENT_BOX_HEIGHT * this.ZOOM_LEVEL;

    // Initial Zoom len's position...
    this.renderer.setStyle(this.zoomLens.nativeElement, 'top', '0');
    this.renderer.setStyle(this.zoomLens.nativeElement, 'left', '0');
    // Set zoom len's height and width...
    this.renderer.setStyle(
      this.zoomLens.nativeElement,
      'width',
      `${this.ZOOM_BOX_WIDTH}px`
    );
    this.renderer.setStyle(
      this.zoomLens.nativeElement,
      'height',
      `${this.ZOOM_BOX_HEIGHT}px`
    );

    // Set the background-image, set its size and position...
    this.renderer.setStyle(
      this.zoomLens.nativeElement,
      'background-image',
      `url("${this.activeMorphImage.fileName}")`
    );
    this.renderer.setStyle(
      this.zoomLens.nativeElement,
      'background-size',
      `${this.imageElemSize.width * this.ZOOM_LEVEL}px ${
        this.imageElemSize.height * this.ZOOM_LEVEL
      }px`
    );
    this.renderer.setStyle(
      this.zoomLens.nativeElement,
      'background-position',
      '0px 0px'
    );
  }

  // Get the mouse cursor position...
  getCursorPosition(event: MouseEvent) {
    // Cursor position from the viewport...
    const MOUSE_X_VIEWPORT = event.clientX + window.pageXOffset;
    const MOUSE_Y_VIEWPORT = event.clientY + window.pageYOffset;

    // Image Container position from the viewport...
    const CONTAINER = (
      this.image.nativeElement as HTMLElement
    ).getBoundingClientRect();

    // Cursor position from the Image Container...
    const MOUSE_X = MOUSE_X_VIEWPORT - (CONTAINER.left + window.pageXOffset);
    const MOUSE_Y = MOUSE_Y_VIEWPORT - (CONTAINER.top + window.pageYOffset);

    return { mouseX: MOUSE_X, mouseY: MOUSE_Y };
  }

  zoomImage() {
    this.isZoomImage = !this.isZoomImage;
    if (this.isZoomImage) {
      // If grid is on than turn it off...
      if (this.isShowingGrid) {
        this.isShowingGrid = false;
      }

      this.cdr.detectChanges();

      // Set the default zoom settings(size, position etc)...
      this.setDefaultZoomSettings();

      // Mouse move event to move the zoom lens...
      this.pointerEventSubsciption = fromEvent(
        this.image.nativeElement,
        'mousemove'
      ).subscribe((event: MouseEvent) => {
        const { mouseX, mouseY } = this.getCursorPosition(event);
        // Zoom box's X, Y position from the image container...
        const ZOOM_BOX_X = Math.round(mouseX - this.ZOOM_BOX_WIDTH / 2);
        const ZOOM_BOX_Y = Math.round(mouseY - this.ZOOM_BOX_HEIGHT / 2);

        // Content box's X, Y position from the image container...
        const CONTENT_BOX_X = Math.round(mouseX - this.CONTENT_BOX_WIDTH / 2);
        const CONTENT_BOX_Y = Math.round(mouseY - this.CONTENT_BOX_HEIGHT / 2);

        // Move the zoom box based upon X axis limit(how far it can be moved)...
        if (
          ZOOM_BOX_X >= 0 &&
          ZOOM_BOX_X <= this.imageElemSize.width - this.ZOOM_BOX_WIDTH
        ) {
          this.renderer.setStyle(
            this.zoomLens.nativeElement,
            'left',
            `${ZOOM_BOX_X}px`
          );
        } else if (ZOOM_BOX_X < 0) {
          this.renderer.setStyle(this.zoomLens.nativeElement, 'left', '0');
        } else if (
          ZOOM_BOX_X >
          this.imageElemSize.width - this.ZOOM_BOX_WIDTH
        ) {
          this.renderer.setStyle(
            this.zoomLens.nativeElement,
            'left',
            `${this.imageElemSize.width - this.ZOOM_BOX_WIDTH}px`
          );
        }

        // Move the zoom box based upon Y axis limit(how far it can be moved)...
        if (
          ZOOM_BOX_Y >= 0 &&
          ZOOM_BOX_Y <= this.imageElemSize.height - this.ZOOM_BOX_HEIGHT
        ) {
          this.renderer.setStyle(
            this.zoomLens.nativeElement,
            'top',
            `${ZOOM_BOX_Y}px`
          );
        } else if (ZOOM_BOX_Y < 0) {
          this.renderer.setStyle(this.zoomLens.nativeElement, 'top', '0');
        } else if (
          ZOOM_BOX_Y >
          this.imageElemSize.height - this.ZOOM_BOX_HEIGHT
        ) {
          this.renderer.setStyle(
            this.zoomLens.nativeElement,
            'top',
            `${this.imageElemSize.height - this.ZOOM_BOX_HEIGHT}px`
          );
        }

        // Change background image's position based upon the content box's
        // X and Y position...
        if (
          CONTENT_BOX_X >= 0 &&
          CONTENT_BOX_X <= this.imageElemSize.width - this.CONTENT_BOX_WIDTH
        ) {
          this.renderer.setStyle(
            this.zoomLens.nativeElement,
            'background-position-x',
            `-${CONTENT_BOX_X * this.ZOOM_LEVEL}px`
          );
        }
        if (
          CONTENT_BOX_Y >= 0 &&
          CONTENT_BOX_Y <= this.imageElemSize.height - this.CONTENT_BOX_HEIGHT
        ) {
          this.renderer.setStyle(
            this.zoomLens.nativeElement,
            'background-position-y',
            `-${CONTENT_BOX_Y * this.ZOOM_LEVEL}px`
          );
        }
      });
    } else {
      if (this.pointerEventSubsciption) {
        this.pointerEventSubsciption.unsubscribe();
      }
    }
  }

  calculateCount() {
    this.totalSpermCount = {
      replicate1: {
        totalCount: 0,
        normalSpermCount: 0,
        abnormalSpermCount: 0,
      },
      replicate2: {
        totalCount: 0,
        normalSpermCount: 0,
        abnormalSpermCount: 0,
      },
    };
    for (const eachImage of this.morphImages) {
      if (eachImage.normalSpermCount > 0 || eachImage.abnormalSpermCount > 0) {
        this.totalSpermCount[`replicate${eachImage.replicateNum}`].totalCount +=
          +eachImage.normalSpermCount + +eachImage.abnormalSpermCount;
        this.totalSpermCount[
          `replicate${eachImage.replicateNum}`
        ].normalSpermCount += +eachImage.normalSpermCount;
        this.totalSpermCount[
          `replicate${eachImage.replicateNum}`
        ].abnormalSpermCount += +eachImage.abnormalSpermCount;
      }
    }
  }

  mainImageLoaded() {
    if (this.mainImageLoading) {
      this.mainImageLoading = false;
    }
  }

  onSmallImageLoaded(
    loadingElement: Element,
    replicateNoElem: Element,
    imageElem: Element
  ) {
    this.renderer.setStyle(loadingElement, 'display', 'none');
    this.renderer.setStyle(imageElem, 'display', 'block');
    if (this.isSecondReplicate) {
      this.renderer.setStyle(replicateNoElem, 'display', 'block');
    }
  }

  onClickSaveAndContinue() {
    if (!this.morphImages || this.morphImages.length === 0) return;
    // First calculate the count...
    this.calculateCount();

    if (
      this.totalSpermCount.replicate1.totalCount === 0 &&
      this.totalSpermCount.replicate2.totalCount === 0
    ) {
      this.popupService.$PopupModal.next({
        isNobackdrop: true,
        heading: 'Missing Results',
        icon: {
          name: 'error',
          color: 'red',
        },
        content_1: {
          text: 'Do you want to continue without entering any results?',
          style: {},
        },
        content_2: {
          text: 'Note: Results must be entered by the submission deadline.',
          style: {},
        },
        buttons: [
          {
            text: 'YES',
            key: 'saveYes-skipped',
            class: 'btn-primary',
          },
          {
            text: 'NO',
            key: 'saveNo',
            class: 'btn-primary',
          },
        ],
      });
    } else if (this.totalSpermCount.replicate2.totalCount === 0) {
      this.popupService.$PopupModal.next({
        isNobackdrop: true,
        heading: 'Missing Replicate #2',
        icon: {
          name: 'error',
          color: 'red',
        },
        content_1: {
          text: 'Replicate #2 results were not entered. Do you want to continue?',
          style: {},
        },
        buttons: [
          {
            text: 'YES',
            key: 'saveYes',
            class: 'btn-primary',
          },
          {
            text: 'NO',
            key: 'saveNo',
            class: 'btn-primary',
          },
        ],
      });
    } else if (this.isOutsideWhoRange) {
      this.popupService.$PopupModal.next({
        isNobackdrop: true,
        heading: 'OUT OF RANGE',
        icon: {
          name: 'error',
          color: 'red',
        },
        content_1: {
          text: 'The difference between the two replicates is out of the WHO acceptable range',
          style: {},
        },
        content_2: { text: 'Please redo your assessment', style: {} },
        buttons: [
          {
            text: 'REDO',
            key: 'redo',
            class: 'btn-primary',
          },
          {
            text: 'NO THANKS',
            key: 'no-thanks',
            class: 'btn-primary',
          },
        ],
      });
    } else {
      this.saveAndContinue('remove');
    }
  }

  async saveToDatbase() {
    if (!this.morphImages || this.morphImages.length === 0) return;
    // Look of the images that's states are changed...
    const filteredImages = this.morphImages.filter(
      (eachImage) => eachImage.stateChanged === true
    );

    // Save the data to database...
    const dataToSave: { id: string; data: UserMorphData }[] =
      filteredImages.map((eachImage) => {
        return {
          id: eachImage.imageId,
          data: {
            imageIndex: eachImage.imageIndex,
            normalCount: eachImage.normalSpermCount,
            abnormalCount: eachImage.abnormalSpermCount,
            replicateNum: eachImage.replicateNum,
          },
        };
      });

    return this.httpService.updateUserTest(
      this.gcf.gettestID,
      `morphologyTest${this.challengeId}`,
      dataToSave
    );
  }

  async saveAndContinue(skippedTest: 'add' | 'remove') {
    try {
      await this.saveToDatbase();
    } catch (error) {
      console.log(error);
    }
    // Update the test skipped or not before changing route(because the name depends
    // on current route)...
    this.commonService.skippedTestfn(skippedTest);
    if (this.challengeId === 1) {
      this.router.navigate(['/proficiency/morphology-challenge/2']);
    }
    if (this.challengeId === 2) {
      this.router.navigate(['/proficiency/concentration']);
    }
  }

  async onBack() {
    if (this.morphImages || (this.morphImages && this.morphImages.length > 0)) {
      try {
        await this.saveToDatbase();
      } catch (error) {
        console.log(error);
      }
    }
    // Check if have to update skipped test or not...
    let result = 'add';
    for (const eachImage of this.morphImages) {
      if (eachImage.abnormalSpermCount + eachImage.normalSpermCount > 0) {
        result = 'remove'; /* Remove from skipped steps list(Data counted) */
        break;
      }
    }
    this.commonService.skippedTestfn(result);
    if (this.challengeId === 1) {
      this.router.navigate(['/proficiency/motility']);
    }
    if (this.challengeId === 2) {
      this.router.navigate(['/proficiency/morphology-challenge/1']);
    }
  }

  ngOnDestroy(): void {
    this.imageSizeObserver.disconnect();
    this.containerSizeObserver.disconnect();
    this.popupClickSubsciption.unsubscribe();
    if (this.pointerEventSubsciption) {
      this.pointerEventSubsciption.unsubscribe();
    }
  }
}
