// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  UserKey: 0,

  firebase: {
    
    apiKey: "AIzaSyCOp5MU02pxfeZ-UlpzabA96fK3W6vwsvI",
    authDomain: "i-pro-7ecfe.firebaseapp.com",
    projectId: "i-pro-7ecfe",
    storageBucket: "i-pro-7ecfe.appspot.com",
    messagingSenderId: "837555923881",
    appId: "1:837555923881:web:bccca912b1a4d7f9e232b8",
    measurementId: "${config.measurementId}"

  }

};

export const GlobalUser = {
  UserName: ""
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
