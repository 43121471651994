import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { globalConfig } from 'src/app/globalConfig';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, public _gCf: globalConfig) {}
  canActivate(): boolean {
    if (this._gCf.currentUser == null) {
      this.router.navigate(['signin']);
      return false;
    }
    return true;

  }
}