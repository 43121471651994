import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { globalConfig } from 'src/app/globalConfig';

export interface CanComponentDeactivate {
  canExit: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingGuardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {

  // component: Object;
  // route: ActivatedRouteSnapshot;

  constructor(private _gCf: globalConfig) {}

  canActivate(): boolean {
    
    if(this._gCf.showSpinner){
      return false;
    }else{
      return true;
    }
  }

  canDeactivate(
    component: CanComponentDeactivate, 
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot) {
      return component.canExit ? component.canExit() : true;
  }


}
