import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { globalConfig } from 'src/app/globalConfig';
import { HttpService } from 'src/app/shared/services/http.service';
import { PopupService } from 'src/app/shared/components/popup/popup.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-concentration',
  templateUrl: './concentration.component.html',
  styleUrls: ['./concentration.component.scss'],
})
export class ConcentrationComponent implements OnInit, OnDestroy {
  popupClickSubsciption: Subscription;
  form: FormGroup;
  concentrationData: { count1: number; count2: number };
  deviceName: string;
  currentInputElem: HTMLInputElement;

  get concFileLink() {
    return this.gcf.concFile;
  }

  get disableSubmit() {
    // Don't disable the submit button if the form made disabled. So user can't
    // change the already saved value and go to the next page...
    if (this.form.disabled) return !this.concentrationData;

    return !this.form.valid;
  }

  constructor(
    private gcf: globalConfig,
    private router: Router,
    private httpService: HttpService,
    private popupService: PopupService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.deviceName = this.gcf.testConfiguration.concentrationDevice;
    this.gcf.spinner(false);
    this.commonService.updateTestLevel(5);

    this.form = new FormGroup(
      {
        sample1: new FormControl('', [
          Validators.required,
          this.decimalPointValidator,
        ]),
        sample2: new FormControl('', [
          Validators.required,
          this.decimalPointValidator,
        ]),
      },
      { updateOn: 'blur' }
    );

    // Disable the form until the concentration data fetched...
    this.form.disable();

    this.httpService
      .getConcentrationTestData(this.gcf.testID)
      .then(
        (result: {
          id: string;
          data: { concentrationTest: { count1: number; count2: number } };
        }) => {
          if (result.data.concentrationTest) {
            console.log(result.data.concentrationTest);
            this.concentrationData = result.data.concentrationTest;
            this.form.setValue({
              sample1: Number(result.data.concentrationTest.count1).toFixed(1),
              sample2: Number(result.data.concentrationTest.count2).toFixed(1),
            });
            // Disable the form if it has values already...
            this.form.disable();
          } else {
            // Else if no data present in the database than enable the form...
            this.form.enable();
          }
        }
      )
      .catch((error) => {
        console.log(error);
      });

    // Listen for popup click events...
    this.popupClickSubsciption = this.popupService.$ClosePopupModal.subscribe(
      (result: { text: string }) => {
        switch (result.text) {
          case 'reviewSave':
            this.onSaveAndContinue();
            break;
          case 'ok':
            if (this.currentInputElem) {
              this.currentInputElem.focus();
            }
        }
      }
    );
  }

  decimalPointValidator(control: AbstractControl): ValidationErrors | null {
    if (
      !(control.value as string).includes('.') ||
      (control.value as string)[(control.value as string).length - 1] === '.'
    )
      return { decimalError: true };
    return null;
  }

  showDecimalError() {
    if (this.form.get('sample1').value !== '') {
      if (this.form.get('sample1').errors !== null) {
        if (this.form.get('sample1').errors.decimalError) {
          return true;
        }
      }
    }
    if (this.form.get('sample2').value !== '') {
      if (this.form.get('sample2').errors !== null) {
        if (this.form.get('sample2').errors.decimalError) {
          return true;
        }
      }
    }
    return false;
  }

  showRedBorder(controlName: string) {
    if (this.form.get(controlName).value !== '') {
      if (this.form.get(controlName).errors !== null)
        return this.form.get(controlName).errors.decimalError;
    }
    return false;
  }

  roundNumberValue(controlName: string) {
    // Do nothing if the field is disabled...
    if (this.form.get(controlName).disabled) return;

    const value = this.form.get(controlName).value;

    // Don't run the check if value is null or empty...
    if (value !== null && value !== '') {
      const valueArr = (value as string).split('');
      let updatedValue: string;
      // If the value is like '.'...
      if (valueArr.length === 1 && valueArr[0] === '.') {
        updatedValue = '0.0';
      }
      // If the value is like '.8'...
      if (valueArr.length > 1 && valueArr[0] === '.') {
        valueArr.unshift('0');
        updatedValue = valueArr.join('');
      }
      // If the value is like '7.'...
      if (valueArr.length > 1 && valueArr[valueArr.length - 1] === '.') {
        valueArr.push('0');
        updatedValue = valueArr.join('');
      }

      if (updatedValue) {
        this.form.get(controlName).setValue(updatedValue);
      }
    }
  }

  onKeyDown(event: KeyboardEvent, element: HTMLInputElement) {
    // Cursor position to determine where the pressed key value would be entered in the
    // previous value's array...
    const _cursorStartPosition = (event.target as HTMLInputElement)
      .selectionStart;
    const _cursorEndPosition = (event.target as HTMLInputElement).selectionEnd;
    const valueArr = element.value.split('');
    // Backspace shouldn't work if it's used to remove '.' and after removing '.' length becomes 5...
    if (event.key === 'Backspace') {
      if (_cursorStartPosition === _cursorEndPosition) {
        // With no text selection...
        if (valueArr[_cursorStartPosition - 1] === '.') {
          valueArr.splice(_cursorStartPosition - 1, 1);
          return valueArr.length < 4;
        } else {
          return true;
        }
      } else {
        // With text selection...
        const textSelected =
          _cursorEndPosition -
          _cursorStartPosition; /* Selected text through cursor */
        valueArr.splice(_cursorStartPosition, textSelected);
        return valueArr.includes('.')
          ? valueArr.length < 5
          : valueArr.length < 4;
      }
    }

    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') return true;

    if (/[0-9]/.test(event.key) || event.key === '.') {
      // Since keypress or keydown event don't reflect the key pressed in the input value that's
      // why creating the reflected(latest) value by taking the previous value and current key pressed...
      const currentKey = event.key;
      if (_cursorStartPosition === _cursorEndPosition) {
        // If no text selected through cursor...
        valueArr.splice(_cursorStartPosition, 0, currentKey);
      } else {
        // If text is selected through cursor...
        valueArr.splice(
          _cursorStartPosition,
          _cursorEndPosition - _cursorStartPosition,
          currentKey
        );
      }
      if (valueArr.includes('.')) {
        if (valueArr.length > 5) return false;
      } else {
        if (valueArr.length > 3) return false;
      }
      const dotIndex = valueArr.findIndex((item) => item === '.');
      if (dotIndex !== -1) {
        // Only 1 item after dot...
        if (valueArr.length - 1 - dotIndex > 1) return false;
        // Only 3 items before dot...
        if (dotIndex > 3) return false;
        // Can't be dot after a dot...
        if (valueArr[dotIndex + 1] === '.') return false;
        return true;
      }
      return true;
    }
    return false;
  }

  async onSaveAndContinue() {
    // Return if form isn't disabled(prevent from changing the existing value) and
    // form isn't valid either...
    if (!this.form.valid && !this.form.disabled) return;

    const value: { sample1: string; sample2: string } = this.form.value;

    // Save the data if form is valid...
    if (this.form.valid) {
      try {
        await this.httpService.updateUserTestData(this.gcf.testID, {
          concentrationTest: { count1: +value.sample1, count2: +value.sample2 },
        });
      } catch (error) {
        console.log(error);
      }
    }

    // Go to next page either way...
    this.router.navigate(['/proficiency/finish']);
  }

  onReviewResult() {
    if (!this.form.valid) return;
    this.popupService.$PopupModal.next({
      isNobackdrop: true,
      heading: 'Review Results',
      icon: {
        name: 'error',
        color: 'red',
      },
      content_1: {
        text: 'Please review the results of your challange',
        style: {},
      },
      lists: [
        { name: 'Sample1', value: `${this.form.value.sample1} M/ml` },
        { name: 'Sample2', value: `${this.form.value.sample2} M/ml` },
      ],
      buttons: [
        {
          text: 'Back',
          key: 'reviewBack',
          class: 'btn-primary',
        },
        {
          text: 'Save And Continue',
          key: 'reviewSave',
          class: 'btn-primary',
        },
      ],
    });
  }

  onBack() {
    this.router.navigate(['/proficiency/morphology-challenge/2']);
  }

  ngOnDestroy(): void {
    this.popupClickSubsciption.unsubscribe();
  }
}
