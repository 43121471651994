import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { AuthGuardService } from './guard/route/jwtAuth/jwtAuthGuard.service';
import { isUserExistInBatchGuardService } from './guard/route/isExistInBatch/isExistInBatch.service';
import { ValidateMotilityInputsService } from './services/validateMotiityInputs/validate-motility-inputs.service';
import { isBatchExpiredGuardService } from './guard/route/isBatchExpired/isBatchExpired.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PipesModule,
    ComponentsModule,
  ],
  exports:[
    PipesModule,
    ComponentsModule
  ],

  providers: [
    DecimalPipe,
    AuthGuardService, ValidateMotilityInputsService, isUserExistInBatchGuardService, isBatchExpiredGuardService
  ]

})
export class SharedModule { }
