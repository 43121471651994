

export interface FirestoreTimestamp{
    seconds: number;
    nanoseconds: number;
}


export enum testLevelTestNameEnum {
    "01" = "intro",
    "02" = "motilityTest",
    "03" = "morphologyTest1",
    "04" = "morphologyTest2"
}

export interface UserInfo{
    id: string;
    data: {
        authID: string;
        firstName: string;
        lastname: string;
        email: string;
        phoneNumber: string;
        position: string;
        facilityName: string;
        // facility:{
        //     facilityName: string;
        //     address?: string;
        // },
        address: string;
        createdAt: any;
        updatedAt?: any;
    }
}


export interface UserTestInfo{
    id: string;
    data: {
        userID: string;
        systemConfiguration: SystemConfiguration;
        testConfiguration: TestConfiguration;
        testLevel: string;
        skippedTestLevels: string[];
        currentVideoIndex?: number;
        concentrationTest?: ConcentrationTest;
        submissionDate?: any;
        createdAt: any;
        updatedAt?: any;
    }
}


export interface UserTestData{

    id: string;
    data: {
        userID: string;
        systemConfiguration: SystemConfiguration;
        testConfiguration: TestConfiguration;
        testLevel: string;
        skippedTestLevels: string[];
        currentVideoIndex?: number;
        concentrationTest?: ConcentrationTest;
        submissionDate?: any;
        createdAt: any;
        updatedAt?: any;
        motilityTest?: [MotilityUserTestData],
        morphologyTest?: [MorphologyUserTestData]
    },
    
}

export interface SystemConfiguration{
    browserVersion: string;
    screenWidth: number;
    browserModel: string,
    screenHeight: number;
    operatingSystem: string;
}

export interface TestConfiguration{
    batchNumber: string;
    whoCriteria: string;
    morphologyCriteria: string;
    concentrationDevice: string;
    // stainingMethod: string;
    // deviceType: string;
    // concAssessment: string;
    // manualMethod: string;
    // deviceTypeOther: string;
}



export interface IntroTestDataReq{
    userID?: string;
    systemConfiguration: SystemConfiguration;
    testConfiguration: TestConfiguration;
    updatedDate?: any;
    createdDate?: any;
    testLevel: number;
}

export interface IntroTestDataRes{

    id: string;
    data: {
        userID: string;
        systemConfiguration: SystemConfiguration;
        testConfiguration: TestConfiguration;
        updatedDate?: any;
        createdDate: any;
        testLevel: number;
        concentrationTest?: ConcentrationTest;
        skippedTestLevels?: Array<string>;
    }
}


export interface ConcentrationTest{
    count1: number;
    count2: number;
}


export interface MotilityUserTestData{
    id?: string;
    data:{
        immotileCount: number;
        nonProgCount: number;
        progressiveCount: number;
        rapidProgCount: number;
        replicateNumber: number;
        slowProgCount: number;
        totalpermCount: number;
        videoID: string;
        videoIndex: number;
    }
}

export interface motilityReplicate{
    1:{
        nonProgressiveCount: number;
        immotileCount: number;
        progressiveCount: number;
        rapidProgressiveCount: number;
        slowProgressiveCount: number;
        nonProgressivePercentage: number;
        motilePercentage: number;
        immotilePercentage: number;
        progressivePercentage: number;
        rapidProgressivePercentage: number;
        slowProgressivePercentage: number;
    },
    2:{
        nonProgressiveCount: number;
        immotileCount: number;
        progressiveCount: number;
        rapidProgressiveCount: number;
        slowProgressiveCount: number;
        nonProgressivePercentage: number;
        motilePercentage: number;
        immotilePercentage: number;
        progressivePercentage: number;
        rapidProgressivePercentage: number;
        slowProgressivePercentage: number;
    },
}

export enum MotilityParamName{
    "total" = "total",
    "immotile" = "immotile",
    "slowProg" = "slow prog",
    "nonProg"   = "non prog"
}

export interface ReplicateCounts {
    total: number,
    immotile: number,
    nonProg: number,
    slowProg?: number
}


export interface MorphologyUserTestData{
    id?: string;
    data:{
        imageID: string;
        imageIndex: number;
        abnormalCount: number;
        normalCount: number;
        replicateNum: number;
    }
}

export interface EmailInfo {
    emailto: string;
    subject: string;
    attachments?: object;
    html: string;
}

// export interface MorphReplicateCount{
//     normalCount: number;
//     abnormalCount: number;
//     normalPercentage: number;
//     abnormalPercentage: number
// }