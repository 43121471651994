import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, OnInit, AfterViewInit, OnDestroy, } from '@angular/core';
import { GlobalUser } from './../environments/environment';
import { globalConfig } from './globalConfig';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopupService } from './shared/components/popup/popup.service';

// import { version } from 'package.json';
import { take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ValidateMotilityInputsService } from './shared/services/validateMotiityInputs/validate-motility-inputs.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [slideInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'iPRO';
  UserName = GlobalUser.UserName;

  observeCloseModal: Observable<any>| undefined;
  modalSubscribe: Subscription;

  routerUrl: string;

  motilityInputValidStatus: boolean = false;
  
  constructor(
    public _globalConfig: globalConfig,
    private _router:Router,
    public _jwtHelper: JwtHelperService,
    private ref: ChangeDetectorRef,
    public popupService: PopupService,
    private _validateMotilityInputsService: ValidateMotilityInputsService,
    ) {
      this.observeCloseModal = this.popupService.observeCloseModal();
    }

  ngOnInit(): void{
    console.log(screen.width," --width---");
    console.log(screen.height," --height---");

    console.log(window.innerWidth," --innerWidth---");
    console.log(window.innerHeight," --innerHeight---");

    this.ref.detectChanges();
    this.observeInput();

    this._router.events.subscribe(res => {
      this.routerUrl  = this._router.url;
    });
  }

  ngAfterViewChecked(): void{
    if (!this.ref['destroyed']) {
      this.ref.detectChanges();
    }
  }

  ngOnDestroy(): void{}

  observeInput(): void{
    this._validateMotilityInputsService.observeInput().subscribe((res: boolean) => {this.motilityInputValidStatus = res});
  }


  async logout(): Promise<void>{

    if(this.routerUrl == '/proficiency/motility'){
      this._validateMotilityInputsService.triggerValidateMotlityInputs();
      if(this.motilityInputValidStatus) return;
    }

    this.logoutPopup();

    const key = await this.modalSubscription();
    if(key === "ok"){

      this.modalUnsubscriptoin();

      this._router.navigate(['/signin']);

    }

  }


  logoutPopup():void {
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "SAVED CHANGES",
        showCloseIcon: false,
        icon: {
          name: 'done',
          color: '#f39e00',
        },
        content_1: {
          text: "YOUR CHANGES WERE SAVED!",
          style: {}
        },
        buttons: [
          {
            key: "ok",
            text: "OK",
            class: "btn-primary"
          },
        ],
      }
    );
  }



  modalSubscription(){

    return new Promise((resolve, reject) => {
      this.modalSubscribe  = this.observeCloseModal.pipe(take(1)).subscribe(res => {
        // console.log(res);
        resolve(res.text);
      });

    });

  }

  modalUnsubscriptoin(): void{
    this.modalSubscribe.unsubscribe();
  }

  getRouteAnimationData(outlet) {
    if ((outlet.activatedRoute as ActivatedRoute).snapshot.params['challangeId']) {
      // For morphology challange 1 and 2 instead of returning the state
      // return the challangeId...
      return (outlet.activatedRoute as ActivatedRoute).snapshot.params['challangeId'];
    }
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
  }
  
}
