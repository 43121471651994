import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalUser } from '../../environments/environment';
import { globalAlert } from './../globalAlert';
import { Router } from '@angular/router';
import { globalConfig } from './../globalConfig';
import { HttpService } from '../shared/services/http.service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  outputs: ['onNewEntryAdded']
})
export class SigninComponent implements OnDestroy {

  name: string;

  // Login form validation
  loginForm = this.fb.group({
    loginEmail: [null, Validators.compose([Validators.required, Validators.email])],
    loginPassword: [null, Validators.compose([Validators.required])],
  });


  @Output() onNewEntryAdded = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    public _globalAlert: globalAlert,
    private router: Router,
    public _globalConfig: globalConfig,
    private _httpService: HttpService
  ) { }

  ngOnInit(): void {
    /*****  Change Background Color *****/
    document.body.className = "signIn";

    /***** Remove Alert Messages *****/
    // this._globalAlert.alerts = [];

    /***** Check login status *****/
    if (this._globalConfig.currentUser !== null) {
      this.router.navigate(['/proficiency/intro']);
    }

    this._globalConfig.resetAllData();

  }

  ngOnDestroy(): void{
    this._globalAlert.alerts = [];
  }

  get loginFormvalidate() {

    let f = this.loginForm.controls;
    return [
      {
        'email': f.loginEmail.dirty && f.loginEmail.invalid || f.loginEmail.invalid && f.loginEmail.touched,
        'emailErrMsg': [
          { type: 'required', message: 'Please enter your email address' },
          { type: 'email', message: 'Please enter a valid email address' },
        ],
      },

    ];
  }

  //----------------------Login form submit----------------------------------------------------------------
  async loginFormSubmit(): Promise<void> {

    try {

      if (this.loginForm.valid) {
        let data = {
          'email': this.loginForm.value.loginEmail,
          'password': this.loginForm.value.loginPassword
        }

        this._globalConfig.spinner(true);

        const uid = await this._httpService.signIn(data);

        if (uid) {

          // console.log(uid);

          var currentUser = await this._httpService.getCurrentUser(uid);

          // console.log(currentUser);
          if (currentUser && currentUser.length > 0) {
            
            this._globalConfig.setisUserExistinCurrentBatch = await this._httpService.isUserExistinCurrentBatch(currentUser[0].id);
            
            // if (isUserExistinCurrentBatch.length == 0) {
            //   this._globalConfig.spinner(false);
            //   this._globalAlert.addDanger("User not available in this batch...", "login");
            //   return;
            // }

            await this._httpService.updateLastLoggedIn(currentUser[0].id);
            await this._httpService.createLoginActivity(currentUser[0].id);


            this._globalConfig.currentUser = currentUser[0];

            this._globalConfig.spinner(false);

            // console.log("-------intro-----");

            this._globalAlert.resetAlerts();

            this.router.navigate(['/proficiency/intro']);

          } else {

            this._globalConfig.spinner(false);

            this._globalAlert.addDanger("Something Wrong!", "login");

          }


        } else {

          this._globalConfig.spinner(false);

          this._globalAlert.addDanger("Login Failed", "login");
        }

      } else {

        //console.log('Invalid form');
      }


    } catch (err) {
      this._globalConfig.spinner(false);
      this._globalAlert.addDanger(err.message, "login");
    }

  }


}
