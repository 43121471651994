import { Injectable } from '@angular/core';
import { globalConfig } from 'src/app/globalConfig';
import { testLevelTestNameEnum } from 'src/app/shared/interface/global.interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _gCf: globalConfig,
    private _httpService: HttpService,
  ) { }

  updateTestLevel(currentTest: number): void{

    this._gCf.setcurrentTest = currentTest;

    if(this._gCf.canUpdateTestLevel){
      this._httpService.updateUserTestData( this._gCf.gettestID , {"testLevel": currentTest}).then(res => {
        this._gCf.settestLevel = currentTest;
      });
    }

  }

  skippedTestfn(type: string, currentTes?: number): void{

    let currentTest = this._gCf.getcurrentTest;
    if(currentTes){
      currentTest = currentTes;
    }
    

    // console.log(currentTest);
    // console.log(type);
    

    if(currentTest == 1) return;
    
    if(type == "add"){
      this._gCf.addskippedTestLevels = testLevelTestNameEnum["0"+currentTest];
    }else if(type == "remove"){
      this._gCf.removeskippedTestLevels = testLevelTestNameEnum["0"+currentTest];
    }

    // console.log("----------getskippedTestLevels-------------",this._gCf.getskippedTestLevels);

    let skippedTestLevels = this._gCf.getskippedTestLevels;

    // if(this._gCf.canUpdateTestLevel){
      this._httpService.updateUserTestData( this._gCf.gettestID , {"skippedTestLevels": skippedTestLevels}).then(res => {
        // this._gCf.settestLevel = currentTest;
      });
    // }
    
  }

}
