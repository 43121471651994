import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { globalConfig } from 'src/app/globalConfig';
import { PopupService } from 'src/app/shared/components/popup/popup.service';
import { MotilityCountingArray } from '../modalClass/motility-counting.model';

import ResizeObserver from 'resize-observer-polyfill';
import { HttpService } from 'src/app/shared/services/http.service';
import { CommonService } from 'src/app/shared/services/common.service';

import { MotilityParamName, ReplicateCounts, UserTestData } from 'src/app/shared/interface/global.interface';
import { ValidateMotilityInputsService } from 'src/app/shared/services/validateMotiityInputs/validate-motility-inputs.service';


@Component({
  selector: 'app-motility-counting',
  templateUrl: './motility-counting.component.html',
  styleUrls: ['./motility-counting.component.scss']
})
export class MotilityCountingComponent implements OnInit, AfterViewInit, OnDestroy {

  motilityParamName:any= MotilityParamName;

  gridlines: any = "";

  maxInput: string = "999";

  instructionFile: string;
  guideLine: string;

  src: string = null;

  replicateIndex: number = null;

  currentVideoIndex: number = 0;

  counterType: 'tally' | 'label' = 'tally';

  motilityCountingData = new MotilityCountingArray();

  @ViewChild('mainVideo', { static: false }) mainVideo: ElementRef;
  @ViewChild('videoColumn', { static: false }) videoColumn: ElementRef;

  mainVideoHeight: string = 'auto';
  mainVideoWidth: string = 'auto';
  videoColumnHeight: string = 'auto';

  isVideoPlaying: boolean = true;
  isVideoLoading: boolean = false;;

  isGrid: boolean = false;

  inputAllowedKeys: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
  inputAllowedNumbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  undoArray: string[] = [];
  redoArray: string[] = [];

  isreplicatecount_1_200PopupShown: boolean = false;
  isreplicatecount_2_200PopupShown: boolean = false;

  observeTriggerInputSubscription: Subscription;

  observeCloseModal: Observable<any>| undefined;
  modalSubscribe: Subscription;

  valueObservable: Observable<any>| undefined;
  valueSubscription: Subscription;

  constructor(
    private _router: Router,
    public gCf: globalConfig,
    private cdf: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private popupService: PopupService,
    private _httpService: HttpService,
    private _commonService: CommonService,
    private _validateMotilityInputsService: ValidateMotilityInputsService
  ) { 
    this.observeCloseModal = this.popupService.observeCloseModal();


    // Load Guidelines PDF
    switch(this.gCf.testConfiguration.whoCriteria){
      
      case "WHO 4":
        this.instructionFile= this.gCf.motility4File.instruction;
        break;

      case "WHO 5":
        this.instructionFile= this.gCf.motility5File.instruction;
        break;

      case "WHO 6":
        this.instructionFile= this.gCf.motility6File.instruction;
        break;

    }
  }


  observeTrigger(): void{
    this.observeTriggerInputSubscription = this._validateMotilityInputsService.observeTrigger().subscribe((_) => {
      let statue: boolean = this.mismatchCountPopup();
      this._validateMotilityInputsService.validateMotlityInputs(statue);
    });
  }

  keyvalueNoSort = (): number => 0;


  ngOnInit(): void{

    this.gCf.spinner(false);

    this._commonService.updateTestLevel(2);

    this.observeTrigger();

    // this._commonService.skippedTestfn("remove");

    //GET USER TEST
    this._httpService.getUserMotilityTestData(this.gCf.gettestID).then(async (res2 : UserTestData) => {
      
      if(res2.data){

        const {data} = res2;
      
        if(data.currentVideoIndex){
          this.currentVideoIndex = data.currentVideoIndex;
        }

        if(data.motilityTest){

          // If test found
          if(res2.data.motilityTest.length > 0){
            const data1 = data.motilityTest.sort((a,b) => a.data.videoIndex - b.data.videoIndex);
            this.motilityCountingData.setdataArray = data1;   
            
            const findReplicateIndex = data1.findIndex( x => x.data.replicateNumber == 2);
            
            if(findReplicateIndex !== -1) {
              this.replicateIndex = findReplicateIndex;
            }

          } else{
            // If test not found
            this.addNewData(0, 1);
          }
        }else{
          // If test not found
          this.addNewData(0, 1);
        } 
      }else{
        // If test not found
        this.addNewData(0, 1);
      }

    });

  }



  ngAfterViewInit(): void{ 

    this.videoColumnHeight = ((this.videoColumn.nativeElement.clientWidth / 5) * 4) + 'px';

    //Responsive Observer for Grid Lines
    setTimeout(() => { 
      const obs = new ResizeObserver(entries => {

        this.videoColumnHeight = 'auto';
        this.videoColumnHeight = ((this.videoColumn.nativeElement.clientWidth / 5) * 4) + 'px';

        if(this.isGrid) this.generategridLines();   
        
        if(!this.cdf['destroyed']) {
          this.cdf.detectChanges();
        }

      });
      obs.observe(this.mainVideo.nativeElement);

    },1000);

  }



  ngOnDestroy(): void{
    if(this.observeTriggerInputSubscription){
      this.observeTriggerInputSubscription.unsubscribe();
    }
  }


  async canExit() :  Promise<Observable<boolean> | boolean> {
    
    this.gCf.spinner(true);
    
    if(!this.isAnythingCounted){
      this._commonService.skippedTestfn("add", 2);
    } else {
      this._commonService.skippedTestfn("remove", 2);
    }

    if(this.mismatchCountPopup()){
      this.gCf.spinner(false);
      this.gCf.setcurrentTest = 2;
      return false;
    }

    await this.saveData();    

    this.gCf.spinner(false);

    return true;
  }

  
  

  get replicateCounts(): ReplicateCounts[]{

    let replicateCounts: ReplicateCounts[] = [
      {
        total: this.totalSpermReplicate1,
        immotile: this.totalImmotileReplicate1,
        nonProg: this.totalNonProgReplicate1
      }
    ];

    if(this.gCf.testConfiguration.whoCriteria == "WHO 4" || this.gCf.testConfiguration.whoCriteria == "WHO 6"){
      
      replicateCounts[0]["slowProg"] = this.totalSlowReplicate1;

      if(this.isreplicateAdded){
        replicateCounts.push({
          total: this.totalSpermReplicate2,
          immotile: this.totalImmotileReplicate2,
          nonProg: this.totalNonProgReplicate2,
          slowProg: this.totalSlowReplicate2
        });
      }

    }else {
      if(this.isreplicateAdded){
        replicateCounts.push({
          total: this.totalSpermReplicate2,
          immotile: this.totalImmotileReplicate2,
          nonProg: this.totalNonProgReplicate2
        })
      }
    }
    return replicateCounts;
  }


  // Check is opacity applicable
  getOpacity(index: number): boolean{

    if(this.isreplicateAdded){
      if(this.motilityCountingData.dataArray[index]){
        if(this.motilityCountingData.dataArray[index].data.replicateNumber == 1){
          return true;
        }else{
          return false;
        }
      } else {
        return true;
      }
    }else{
      return index  > this.motilityCountingData.dataArray.length -1;
    }
  }

  showingReplicateNumber(index: number): boolean{
    return index  <= this.motilityCountingData.dataArray.length -1;
  }


  // Add new data
  addNewData(index: number, replicateNumber: number): void{

    this.motilityCountingData.addNewData({
      totalSpermCount : 0,
      motileCount : 0,
      immotileCount : 0,
      nonProgCount : 0,
      slowProgCount : 0,
      rapidProgCount : 0,
      replicateNumber : replicateNumber,
      progressiveCount : 0,
      videoID: this.gCf.getmotilitySystemData[index].id,
      videoIndex: index+1
    });
    
  }

  
  get getWhoCtriteriaType(): string{
    if(this.gCf.testConfiguration.whoCriteria == "WHO 4" || this.gCf.testConfiguration.whoCriteria == "WHO 6"){
      return "who46"
    }
    return "who5";
  }

  
  /*************************** Video Section *************************/
  get mainVideoSrc(): string{
    if(this.gCf.getmotilitySystemData[this.currentVideoIndex]){
      return this.gCf.getmotilitySystemData[this.currentVideoIndex].data.fileName;
    }
    return "";
    
  }

  get getvideoID(): string{
    return this.gCf.motilitySystemData[this.currentVideoIndex].id;
  }

  set changeVideoSrc(currentVideoIndex: number){
    const videoElement: HTMLVideoElement = this.mainVideo.nativeElement;
    videoElement.src = this.gCf.motilitySystemData[currentVideoIndex].data.fileName;
  }

  playVideo(): void{
    if(this.mainVideo.nativeElement){
      const videoElement: HTMLVideoElement = this.mainVideo.nativeElement;
      videoElement.play();

      // var handlerFunction = (event) => {
      //   this.isVideoPlaying = true;
      //   videoElement.removeEventListener('playing', handlerFunction);
      // }

      // videoElement.addEventListener('playing', handlerFunction);
      
    }
  }

  freezeVideo(): void{
    if(this.mainVideo.nativeElement){
      const videoElement: HTMLVideoElement = this.mainVideo.nativeElement;
      videoElement.pause();
      this.isVideoPlaying = false;
    }
  }


  selectVideo(index): void{

    if(
      (index > this.motilityCountingData.dataArray.length -1) ||
      (this.replicateIndex && index < this.replicateIndex) ||
      (this.currentVideoIndex == index) ||
      this.mismatchCountPopup()
    ) return;
    
    //Grid off
    this.isGrid = false;

    this.currentVideoIndex = index;
    this.changeVideoSrc = this.currentVideoIndex;

    this.setUndoRedoArrayEmpty();
  }


  mainVideoLoading(event): void{
    this.isVideoLoading = true;

    const videoElement: HTMLVideoElement = this.mainVideo.nativeElement;
    videoElement.onplaying = (event: any) => {

      setTimeout(() => {
        if(videoElement.clientHeight != 0){
          this.mainVideoHeight = videoElement.clientHeight + 'px';
          this.mainVideoWidth = videoElement.clientWidth + 'px';

          // console.log("--------mainVideoHeight--------", this.mainVideoHeight);
          // console.log("--------mainVideoWidth--------", this.mainVideoHeight);
        }
      },0);

      this.isVideoPlaying = true;
      this.isVideoLoading = false;

      this.cdf.detectChanges();

    };    
    
  }


  /******************************** Counting Section **************************/


  get getCountingBtnClass(): ('pointer' | '') {
    return this.counterType == 'tally' ? 'pointer': '';
  }

  // Change Counter Type
  changeCounterType(counterName): void{
    if(this.counterType == counterName) return;
    this.counterType = counterName;
    this.setUndoRedoArrayEmpty();
  }

  // Label Counter
  labelCounter(event, key): void{ 
    let count =  event.srcElement.value ? parseInt(event.srcElement.value) : 0;
    this.setCounts(count, key);
  }


  async setCounts(count, key): Promise<void>{

    // console.log(count);

    // let cond = 200;
    
    // if(this.counterType == 'label'){
    //   cond = 200;
    // } else if( this.counterType == 'tally'){
    //   cond = 199;
    // }
    
    
    if(this.counterType == 'label'){
      this.motilityCountingData.setCounts(this.currentVideoIndex,key, count);
    } else if( this.counterType == 'tally'){
      if(count > 998){
        this.maximumDigitsPopup();
        return;
      }
      this.motilityCountingData.setCounts(this.currentVideoIndex,key, count+1);
      this.undoArray.push(key);
    }


    // if(text == "add-replicate"){
    //   this.modalUnsubscriptoin();
    //   this.addReplicate();
    // }

  }



  keydown(event: any): void{
    
    if(event.key == "Enter"){ event.target.blur(); return; }

    if(!this.inputAllowedKeys.includes(event.key)){
      event.preventDefault();
      return;
    }
    
    let count = parseInt(event.srcElement.value); 
    
    if(count > 99){
      
      if(this.inputAllowedNumbers.includes(event.key)){

        this.maximumDigitsPopup();

        event.preventDefault();
        return;
      }
    }

  }


  /************************* Next field, Add to Replicate ********************/


  get nextFieldStatus(): boolean{
    return this.currentVideoIndex == this.gCf.motilitySystemData.length-1;
  }

  get FOVRelatedtoFirststReplicate(): boolean{
    let replicate1Data = this.motilityCountingData.dataArray.filter( (val: any) => val.data.replicateNumber == 1);
    if(this.gCf.motilitySystemData.length == replicate1Data.length) return true;
    return false;
  }

  get replicateBtnStatus(): boolean{
    if(this.isreplicateAdded || this.nextFieldStatus || !this.isAnythingCounted || this.FOVRelatedtoFirststReplicate) return true;
    return false;
  }


  async nextField() : Promise<void>{
    if(this.nextFieldStatus) return;

    if(this.mismatchCountPopup()) return;

    // Replicate popup
    if(this.canShowReplicatePopup1 && this.totalSperm > 199){
      this.replicatecount_1_200Popup();
      await this.modalSubscription();
      this.modalUnsubscriptoin();
      this.isreplicatecount_1_200PopupShown = true;
      return;
    }  else if(this.canShowReplicatePopup2 && this.totalSperm > 199){
      this.replicatecount_2_200Popup();
      await this.modalSubscription();
      this.modalUnsubscriptoin();
      this.isreplicatecount_2_200PopupShown = true;
      return;
    }


    

    //Grid off
    this.isGrid = false;

    this.currentVideoIndex += 1;

    this.changeVideoSrc = this.currentVideoIndex;

    let index = this.motilityCountingData.dataArray.findIndex(val => val.data.videoID == this.getvideoID);
    if(index == -1) this.addNewData(this.currentVideoIndex, this.getReplicateNumber);
    
    this.setUndoRedoArrayEmpty();
  }



  async addReplicate(): Promise<void>{
    if(this.replicateBtnStatus) return;

    if(this.mismatchCountPopup()) return;

    this.addReplicatePopup();
    
    const text = await this.modalSubscription();
    
    if(text === "yes"){

      //Grid off
      this.isGrid = false;

      this.currentVideoIndex += 1;
      this.replicateIndex = this.currentVideoIndex;
      this.changeVideoSrc = this.currentVideoIndex; 

      this.addNewData(this.currentVideoIndex, this.getReplicateNumber);

      this.setUndoRedoArrayEmpty();
      
    }
    this.modalUnsubscriptoin();

  }

  

  mismatchCountPopup(): boolean{
    if(this.getWhoCtriteriaType == "who46"){
      const isNotEqual = this.motilityCountingData.getReenterResultsWHO46;
      // console.log(isNotEqual);
      
      if(isNotEqual){
        this.who46ReenterResultsPopup();
        return true;
      }
      return false;
    } else if(this.getWhoCtriteriaType == "who5"){
      const isNotEqual = this.motilityCountingData.getReenterResultsWHO5;
      
      if(isNotEqual){
        this.who5ReenterResultsPopup();
        return true;
      }

      return false;
    }
  }

  onInput(event): void{
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }
  

  /*************************** Undo, Redo, Clear All, Reset Count **************************/

  setUndoRedoArrayEmpty(): void{
    this.undoArray = [];
    this.redoArray = [];
  }

  get getisUndoDisabled(): boolean{
    return this.undoArray.length == 0;
  }

  get getisRedoDisabled(): boolean{
    return this.redoArray.length == 0;
  }

  get getisClearAllBtnDisabled(): boolean{
    return !this.isAnyDataInCurrentFOV;
  }

  get getisResetBtnDisabled(): boolean{
    return !this.isAnythingCounted;
  }

  undo(): void{
    let removedJKey = this.undoArray.pop();
    this.redoArray.push(removedJKey);

    this.undoRedoCount(removedJKey, -1);
  }

  redo(): void{
    let removedJKey = this.redoArray.pop();
    this.undoArray.push(removedJKey);

    this.undoRedoCount(removedJKey, +1);
  }

  undoRedoCount(key: string, type: number): void{

    let data = this.motilityCountingData.getCurrentVideoData(this.currentVideoIndex);
    let count = data["data"][key] += type;

    this.motilityCountingData.setCounts(this.currentVideoIndex,key, count);
  }

  async clearAll(): Promise<void>{

    this.clearAllPopup();
    
    const text = await this.modalSubscription();

    if(text == "yes"){

      this.addNewData(this.currentVideoIndex, this.getReplicateNumber);
      this.setUndoRedoArrayEmpty();
    }
    this.modalUnsubscriptoin();
  }


  async resetCount(): Promise<void>{

    this.resetCountPopup();

    const text = await this.modalSubscription();

    if(text == "yes"){
      
      this.gCf.spinner(true);
      this._httpService.resetCount(this.gCf.gettestID, "motilityTest").then( (res) => {

        this.gCf.spinner(false);

        this.motilityCountingData.setdataArray = [];

        this.replicateIndex = null;
        this.currentVideoIndex = 0;

        this.addNewData(this.currentVideoIndex, 1);

        this.setUndoRedoArrayEmpty();

        this.isreplicatecount_1_200PopupShown = false;
        this.isreplicatecount_2_200PopupShown = false;

        // console.log(this.motilityCountingData.dataArray);

      });


    }
    this.modalUnsubscriptoin();
  }

  get isAnyDataInCurrentFOV(): boolean{

    const val = this.motilityCountingData.getdataArray[this.currentVideoIndex];

    if(val){
      let count = Object.keys(val.data).filter(val1 => {
        return val1.includes("Count")
      }).reduce((total, key ) => {
        // console.log(key);
        return total +=val.data[key];
      }, 0);
      
      if(count && count > 0){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }


  /******************************** Replicate Section ***************************/

  get getReplicateNumber(): number{
    if(!this.replicateIndex) return 1;
    return this.currentVideoIndex < this.replicateIndex ? 1 : 2;
  }

  // set setReplicateNumber(num){
  //   this.replicateNumber = num;
  // }

  get isreplicateAdded(): boolean{
    return this.replicateIndex === null ? false : true;
  }


  get canShowReplicatePopup1(): boolean{
    return this.getReplicateNumber == 1 && !this.isreplicatecount_1_200PopupShown;
  }

  get canShowReplicatePopup2(): boolean{
    return this.getReplicateNumber == 2 && !this.isreplicatecount_2_200PopupShown;
  }





  /**************************** Total Calculation  *******************/

  // get totalSpermCounted(){
  //   return  this.motilityCountingData.totalSpermCounted();
  // }

  get totalSperm(){
    return this.motilityCountingData.getReplicatetotalCount(this.getReplicateNumber, "totalSpermCount");
  }


  get totalSpermReplicate1(){
    return this.motilityCountingData.getReplicatetotalCount(1, "totalSpermCount");
  }
  get totalImmotileReplicate1(){
    return this.motilityCountingData.getReplicatetotalCount(1, "immotileCount");
  }

  get totalNonProgReplicate1(){
    return this.motilityCountingData.getReplicatetotalCount(1, "nonProgCount");
  }

  get totalSlowReplicate1(){
    return this.motilityCountingData.getReplicatetotalCount(1, "slowProgCount");
  }


  get totalSpermReplicate2(){
    return this.motilityCountingData.getReplicatetotalCount(2, "totalSpermCount");
  }

  get totalImmotileReplicate2(){
    return this.motilityCountingData.getReplicatetotalCount(2, "immotileCount");
  }

  get totalNonProgReplicate2(){
    return this.motilityCountingData.getReplicatetotalCount(2, "nonProgCount");
  }

  get totalSlowReplicate2(){
    return this.motilityCountingData.getReplicatetotalCount(2, "slowProgCount");
  }

  /****************************************************************** */


  //Image field calculation
  get gettotalSpermCount(){
    return this.motilityCountingData.gettotalSpermCount(this.currentVideoIndex);
  }

  get getmotileCount(){
    return this.motilityCountingData.getmotileCount(this.currentVideoIndex);
  }

  get getimmotileCount(){
    return this.motilityCountingData.getimmotileCount(this.currentVideoIndex);
  }

  get getnonProgCount(){
    return this.motilityCountingData.getnonProgCount(this.currentVideoIndex);
  }

  get getslowProgCount(){
    return this.motilityCountingData.getslowProgCount(this.currentVideoIndex);
  }

  get getrapidProgCount(){
    return this.motilityCountingData.getrapidProgCount(this.currentVideoIndex);
  }



  
  //***************************************** Replicate Calculation ******************************//

  //Count
  getReplicatetotalSpermCount(replicateNumber){
    return this.motilityCountingData.getReplicatetotalCount(replicateNumber, "totalSpermCount");
  }

  getReplicatemotileCount(replicateNumber){
    return this.getReplicatetotalSpermCount(replicateNumber) - this.getReplicateimmotileCount(replicateNumber);
  }


  getReplicaterapidProgCount(replicateNumber){
    return this.getReplicatemotileCount(replicateNumber) - this.getReplicatenonProgCount(replicateNumber) - this.getReplicateslowProgCount(replicateNumber);
  }

  getReplicateslowProgCount(replicateNumber){
    return this.motilityCountingData.getReplicatetotalCount(replicateNumber, "slowProgCount");
  }

  getReplicateprogCount(replicateNumber){
    return this.getReplicatemotileCount(replicateNumber) - this.getReplicatenonProgCount(replicateNumber);
  }

  getReplicatenonProgCount(replicateNumber){
    return this.motilityCountingData.getReplicatetotalCount(replicateNumber, "nonProgCount");
  }


  getReplicateimmotileCount(replicateNumber){
    return this.motilityCountingData.getReplicatetotalCount(replicateNumber, "immotileCount");
  }



  //Percentage
  getReplicatemotilePercentage(replicateNumber){
    if(this.getReplicateimmotilePercentage(replicateNumber) != 0){
      return 100 - this.getReplicateimmotilePercentage(replicateNumber);
    }
    return 0;
  }

  getReplicaterapidProgPercentage(replicateNumber){
    return this.getReplicatemotilePercentage(replicateNumber) - ( this.getReplicatenonProgPercentage(replicateNumber) + this.getReplicateslowProgPercentage(replicateNumber));
  }

  getReplicateslowProgPercentage(replicateNumber){
    return this.motilityCountingData.getreplicatePercentage(replicateNumber, "slowProgCount");
  }

  getReplicatenonProgPercentage(replicateNumber){
    return this.motilityCountingData.getreplicatePercentage(replicateNumber, "nonProgCount");
  }

  getReplicateimmotilePercentage(replicateNumber){
    return this.motilityCountingData.getreplicatePercentage(replicateNumber, "immotileCount");
  }

  getReplicateprogPercentage(replicateNumber){
    return this.getReplicatemotilePercentage(replicateNumber) - this.getReplicatenonProgPercentage(replicateNumber);
  }



  /************************************** Final Calculation **********************************************/

  // Average Percebtage
  get averageMotility(): number{
    return (this.getReplicatemotilePercentage(1) + this.getReplicatemotilePercentage(2)) / 2;
  }

  get averageRapidProg(): number{
    return (this.getReplicaterapidProgPercentage(1) + this.getReplicaterapidProgPercentage(2)) / 2;
  }

  get averageSlowProg(): number{
    return (this.getReplicateslowProgPercentage(1) + this.getReplicateslowProgPercentage(2)) / 2;
  }

  get averageProPercentage(): number {
    return (this.getReplicateprogPercentage(1) + this.getReplicateprogPercentage(2)) / 2;
  }

  get averageNonProg(): number{
    return (this.getReplicatenonProgPercentage(1) + this.getReplicatenonProgPercentage(2)) / 2;
  }

  get averageImmotile(): number{
    return (this.getReplicateimmotilePercentage(1) + this.getReplicateimmotilePercentage(2)) / 2;
  }

  get totalSPermCount(): number{
    return this.getReplicatetotalSpermCount(1) + this.getReplicatetotalSpermCount(2);
  }


  calcMax(): number[]{

    if(this.getWhoCtriteriaType == "who46"){

      return [
        this.averageMotility,

        this.averageRapidProg,

        this.averageSlowProg,

        this.averageNonProg,

        this.averageImmotile
      ];

    } else if(this.getWhoCtriteriaType == "who5"){

      return [
        this.averageMotility,

        this.averageProPercentage,

        this.averageNonProg,

        this.averageImmotile
      ];

    }

  }


  get getMin(): Promise<number>{
    
    return new Promise((resolve, reject) => {

      let arr = this.calcMax();

      let max: number = Math.max.apply(null, arr);

      let calculatedValue: number = max - 1.96 * ( Math.sqrt(

        max * (100% - max) / (this.totalSPermCount)

      ) );

      // console.log(calculatedValue);

      if(calculatedValue < 0) return resolve(0);
      return resolve(calculatedValue);

    });

  }



  get getMax(): Promise<number>{

    return new Promise((resolve, reject) => {
    
      let arr = this.calcMax();

      let max: number = Math.max.apply(null, arr);

      let calculatedValue: number = max + 1.96 * ( Math.sqrt(

        max * (100% - max) / (this.totalSPermCount)

      ) );

      // console.log(calculatedValue);
      
      resolve(calculatedValue);

    });

  }

  async finalDifference(): Promise<Boolean>{

    const finalDifference = await this.getMax - await this.getMin;

    const mostCommon = Math.max.apply(null, [this.averageMotility, this.averageImmotile]);

    const motilityDiff = this.getReplicatemotilePercentage(1) - this.getReplicatemotilePercentage(2);

    const immotileDiff = this.getReplicateimmotilePercentage(1) - this.getReplicateimmotilePercentage(2);

    const cond1 = (mostCommon == this.averageMotility) && (Math.abs(motilityDiff) > finalDifference);

    const cond2 = (mostCommon == this.averageImmotile) && (Math.abs(immotileDiff) > finalDifference);
    
    if( cond1 || cond2 ){
      return true;
    }
    return false;

  }
    

  
  /************************* Back, Review Results, Save and Continue ******************/

  totalCount(dataArray): boolean{

    let counts = dataArray.reduce((acc, currval) => {

      acc += Object.keys(currval.data).filter(val1 => {
        return val1.includes("Count")
      }).reduce((acc1, currval1) => { 
        return acc1 + currval.data[currval1];
      },0);
      
      return acc;

    }, 0);

    
    return counts>0;

  }

  get isAnythingCounted(): boolean{
    return this.totalCount(this.motilityCountingData.getdataArray);
  }


  get isAnythingCountedInReplicate2() : boolean{

    if(this.replicateIndex == null) return false;
    
    let replicate2Data = this.motilityCountingData.getdataArray.filter((val, index) => index >= this.replicateIndex );
    
    return this.totalCount(replicate2Data);
  
  }


  // async reviewResults(){

  //   this.reviewResultsPopup();

  //   const text = await this.modalSubscription();
  //   if(text == "save-and-continue"){
  //     this.checkDataBeforeSaving();
  //   }
  //   this.modalUnsubscriptoin();
    
  // }


  async checkDataBeforeSaving(): Promise<void> {

    if(this.mismatchCountPopup()) return;

    // No Data Entered
    if(!this.isAnythingCounted){
      this.missingResultsPopup();

      const text = await this.modalSubscription();
      if(text === "no"){
        this.modalUnsubscriptoin();
        return;
      }
      this.modalUnsubscriptoin();

      this._commonService.skippedTestfn("add", 2);
    
    }else{

      this._commonService.skippedTestfn("remove", 2);

      // Replicate 2 added but Data not entered
      if( !this.isreplicateAdded || !this.isAnythingCountedInReplicate2){

        this.replicate2NoDataPopup();

        const text = await this.modalSubscription();
        if(text === "no"){
          this.modalUnsubscriptoin();
          return;
        }

      } else {

        // Replicate 1 & Replicate 2 have mismach data

        let fd = await this.finalDifference();
        if(fd){
          this.replicateMismatchPopup();

          const text = await this.modalSubscription();
          if(text === "redo"){
            this.modalUnsubscriptoin();
            return;
          }
          this.modalUnsubscriptoin();

        }

      }
    }


    await this.saveData();
    this.gotoMorphology();

  }


  async saveData(): Promise<boolean>{

    // console.log("-----------saveData--------------");

    return new Promise((resolve, reject) => {

      const data = this.motilityCountingData.getdataArray;

      // console.log(this.gCf.testID);
      // console.log(data);
      
      // this.gCf.spinner(true);
      this._httpService.updateUserTest( this.gCf.gettestID ,"motilityTest",data).then(async res => {

        // console.log(res);
        
        // this.gCf.spinner(false);

        let data1 = {
          "currentVideoIndex": this.currentVideoIndex
        };

        await this._httpService.updateUserTestData( this.gCf.gettestID , data1);

        resolve(true);

        // console.log(res);
        // this.gotoMorphology();
      }).catch(err => {

        resolve(false);
        
      });
    
    });

  }

  async backToIntro(){
    if(this.mismatchCountPopup()) return;
    
    await this.saveData();
    this._router.navigate(["/proficiency/intro"]);
  }

  
  async gotoMorphology(){
    this._router.navigate(["/proficiency/morphology-challenge/1"]);
  }


  /******************************************************************************************************/


  @HostListener('window:resize', ['$event'])
  onResize(event) {   
    if(this.isGrid) this.generategridLines();
  }
  
  gridToggle(){
    this.isGrid = !this.isGrid;
    
    if(this.isGrid) {
      this.generategridLines();
    }
  }


  generategridLines(){

    this.gridlines = "";
    let gridline = "";

    let element = this.mainVideo.nativeElement;

    // console.log(element);

    const horizontalLineWidth = element.offsetWidth;
    const verticalLineHeight = element.offsetHeight;

    let widthMeasure = element.offsetWidth / 5;
    let heightMeasure = element.offsetHeight / 4;

    for(let i=1; i<= 3; i++){

      gridline += `<div style="top:${(heightMeasure*i)}px; width: ${horizontalLineWidth}px; position: absolute;
      height: 2px;
      background-color: #000;"></div>`;

    }

    for(let i=1; i<= 4; i++){
      gridline += `<div style="height:${verticalLineHeight}px; left:${(widthMeasure*i)}px; top: 0px;
      width: 2px;
      position: absolute;
      background-color: #000;" ></div>`;
    }

    this.gridlines = this.sanitizer.bypassSecurityTrustHtml(gridline);
    
    // console.log(this.gridlines);
    
  }


  /**************************************** Pop up *********************************************/


  modalSubscription(){

    return new Promise((resolve, reject) => {
      this.modalSubscribe  = this.observeCloseModal.pipe(take(1)).subscribe(res => {
        // console.log(res);
        resolve(res.text);
      });

    });

  }

  modalUnsubscriptoin(){
    this.modalSubscribe.unsubscribe();
  }


  maximumDigitsPopup(){

    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "MAXIMUM DIGITS",
        icon: {
          name: "error",
          color: "red",
        },
        content_1: {
          text: "PLEASE ENTER A MAXIMUM OF 3 DIGITS OR LESS",
          style: {}
        },
        buttons: [
          {
            key: "ok",
            text: "OK",
            class: "btn-primary"
          },
        ],
      }
    );

  }

  clearAllPopup(){

    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "CLEAR ALL",
        icon: {
          name: "error",
          color: "red",
        },
        content_1: {
          text: "DO YOU WANT TO USE CLEAR ALL TO REMOVE ALL OF THE RESULTS IN THE CURRENT FIELD OF VIEW (FOV)?",
          style: {}
        },
        buttons: [
          {
            key: "yes",
            text: "YES",
            class: "btn-primary"
          },
          {
            key: "no",
            text: "NO",
            class: "btn-primary"
          },
        ],
      }
    );

  }

  resetCountPopup(){

    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "RESET COUNT",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "DO YOU WANT TO USE RESET TO DELETE ALL OF YOUR MOTILITY RESULTS?",
          style: {}
        },
        buttons: [
          {
            key: "yes",
            text: "YES",
            class: "btn-primary"
          },
          {
            key: "no",
            text: "NO",
            class: "btn-primary"
          },
        ],
      }
    );

  }


  addReplicatePopup(){

    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "REPLICATE #1 RESULTS",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "WHEN YOU ADD REPLICATE #2, THE RESULTS FOR REPLICATE #1 CAN NOT BE EDITED",
          style: {}
        },
        content_2: { text: "WOULD YOU LIKE TO CONTINUE?", style: {}},
        buttons: [
          {
            key: "yes",
            text: "YES",
            class: "btn-primary"
          },
          {
            key: "no",
            text: "NO",
            class: "btn-primary"
          },
        ],
      }
    );
    
  }


  replicatecount_1_200Popup(){
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "ADD REPLICATE",
        icon: {
          name: "done",
          color: "#F39E00",
          size: "bigSize"
        },
        content_1: {
          text: "YOU HAVE COUNTED 200 SPERM CELLS",
          style: {'margin-bottom' : '0'}
        },
        content_2: { text: " FOR REPLICATE #1", style: {}},
        content_3: `CLICK "ADD REPLICATE #2" TO ASSESS ANOTHER 200 SPERM CELLS PER WHO GUIDELINES`,
        buttons: [
          {
            key: "ok",
            text: "OK",
            class: "btn-primary"
          },
        ],
      }
    );
  }



  replicatecount_2_200Popup(){
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "COUNTED CELLS",
        icon: {
          name: "done",
          color: "#F39E00",
          size: "bigSize"
        },
        content_1: {
          text: "YOU HAVE COUNTED 200 SPERM CELLS",
          style: {}
        },
        content_2: {text: "COMPLETE THE COUNT OF THE CURRENT FOV AND CONTINUE", style: {}},
        buttons: [
          {
            key: "ok",
            text: "OK",
            class: "btn-primary"
          },
        ],
      }
    );
  }

  replicate2NoDataPopup(){

    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "MISSING REPLICATE #2",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "REPLICATE #2 RESULTS WERE NOT ENTERED. DO YOU WANT TO CONTINUE?",
          style: {}
        },
        buttons: [
          {
            key: "yes",
            text: "YES",
            class: "btn-primary"
          },
          {
            key: "no",
            text: "NO",
            class: "btn-primary"
          },
        ],
      }
    );

  }


  missingResultsPopup(){
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "MISSING RESULTS",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "DO YOU WANT TO CONTINUE WITHOUT ENTERING ANY RESULTS?",
          style: {}
        },
        content_2: {text: "NOTE: RESULTS MUST BE ENTERED BY THE SUBMISSION DEADLINE.", style: {}},
        buttons: [
          {
            key: "yes",
            text: "YES",
            class: "btn-primary"
          },
          {
            key: "no",
            text: "NO",
            class: "btn-primary"
          },
        ],
      }
    );
  }



  who46ReenterResultsPopup(){
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "RE-ENTER RESULTS",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "The SUM of IMMOTILE + NON PROG + SLOW PROG is greater than the #TOTAL sperm counted",
          style: {}
        },
        content_2: {text: "PLEASE RE-ENTER VALUES", style: {}},
        buttons: [
          {
            key: "ok",
            text: "OK",
            class: "btn-primary"
          }
        ],
      }
    );

    this.gCf.popupRemoveUpperCase();
  }


  who5ReenterResultsPopup(){
    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "RE-ENTER RESULTS",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: {
          text: "The SUM of IMMOTILE + NON PROG is greater than the #TOTAL sperm counted",
          style: {}
        },
        content_2: {text: "PLEASE RE-ENTER VALUES", style: {}},
        buttons: [
          {
            key: "ok",
            text: "OK",
            class: "btn-primary"
          }
        ],
      }
    );

    this.gCf.popupRemoveUpperCase();
  }

  replicateMismatchPopup(){

    this.popupService.openModal(
      {
        isNobackdrop: true,
        heading: "OUT OF RANGE",
        icon: {
          name: "error",
          color: "red"
        },
        content_1: { 
          text: "THE DIFFERENCE BETWEEN THE TWO REPLICATES IS OUT OF THE WHO ACCEPTABLE RANGE",
          style: {}
        },
        content_2: { text: "PLEASE REDO YOUR ASSESSMENT", style: {}},
        buttons: [
          {
            key: "redo",
            text: "REDO",
            class: "btn-primary"
          },

          {
            key: "nothanks",
            text: "NO THANKS",
            class: "btn-primary"
          }
        ],
      }
    );

  }

  reviewResultsPopup(){

    let listsdata = [];

    let functions = [
      this.getReplicatemotilePercentage(1),
      this.getReplicateprogPercentage(1),
      this.getReplicatenonProgPercentage(1),

      this.getReplicateslowProgPercentage(1),
      this.getReplicaterapidProgPercentage(1),

      this.getReplicateimmotilePercentage(1),
    ];

    if(this.getReplicateNumber == 2){
      functions.push(

        this.getReplicatemotilePercentage(2),
        this.getReplicateprogPercentage(2),
        this.getReplicatenonProgPercentage(2),

        this.getReplicateslowProgPercentage(2),
        this.getReplicaterapidProgPercentage(2),

        this.getReplicateimmotilePercentage(2),

      )
    }

    Promise.all(functions).then( (res: any) => {

      if(this.getReplicateNumber == 1){

        if(this.getWhoCtriteriaType == "who46"){

          listsdata = [
            { name: "Total Motile", value: res[0]},
            { name: "Slow Progressive", value: res[3]},
            { name: "Non-progressive", value: res[2]},
            { name: "Immotile", value: res[5]},
          ];
    
        }else if(this.getWhoCtriteriaType == "who5"){
    
          listsdata = [
            { name: "Total Motile", value: res[0]},
            { name: "Progressive", value: res[1]},
            { name: "Non-progressive", value: res[2]},
            { name: "Immotile", value: res[5]},
          ];
        }

      } else if( this.getReplicateNumber == 2){

        if(this.getWhoCtriteriaType == "who46"){

          listsdata = [
            { name: "Total Motile", value: Math.round((res[0]+res[6])/2)},
            { name: "Slow Progressive", value:  Math.round((res[3]+res[9])/2)},
            { name: "Non-progressive", value:  Math.round((res[2]+res[8])/2)},
            { name: "Immotile", value:  Math.round((res[5]+res[11])/2)},
          ];
    
        }else if(this.getWhoCtriteriaType == "who5"){
    
          listsdata = [
            { name: "Total Motile", value:  Math.round((res[0]+res[6])/2)},
            { name: "Progressive", value:  Math.round((res[1]+res[7])/2)},
            { name: "Non-progressive", value:  Math.round((res[2]+res[8])/2)},
            { name: "Immotile", value:  Math.round((res[5]+res[11])/2)},
          ];
        }

      }
      

      this.popupService.openModal(
        {
          isNobackdrop: true,
          heading: "REVIEW RESULTS",
          content_1: {
            text: "PLEASE REVIEW THE RESULTS OF YOUR CHALLENGE",
            style: {}
          },
          lists: listsdata,
          buttons: [
            {
              key: "back",
              text: "BACK",
              class: "btn-primary"
            },
  
            {
              key: "save-and-continue",
              text: "SAVE AND CONTINUE",
              class: "btn-primary"
            },
  
          ],
        }
      );


    });
  
    
  }

  

}
