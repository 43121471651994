import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, RouteReuseStrategy } from '@angular/router';
import { HomeComponent } from './home/home.component'; // Add this
// import { ArchiveComponent } from './archive/archive.component'; // Add this
import { ContactComponent } from './contact/contact.component'; // Add this
import { SigninComponent } from './signin/signin.component';
import { ProficiencyComponent } from './proficiency/proficiency.component';
import { IntroComponent } from './proficiency/intro/intro.component';
import { MotilityCountingComponent } from './proficiency/motility-counting/motility-counting.component';
import { FinalResultsComponent } from './proficiency/final-results/final-results.component';
import { MorphologyComponent } from './proficiency/morphology/morphology.component';
import { ConcentrationComponent } from './proficiency/concentration/concentration.component';
import { AboutProficiencyTestComponent } from './about-proficiency-test/about-proficiency-test.component';

import { CustomRouteUse } from './custom-route-strategy';
import { RegistrationFormComponent } from './registration-form/registration-form.component';

import { AuthGuardService as AuthGuard } from './shared/guard/route/jwtAuth/jwtAuthGuard.service';
import { LoadingGuardService as LoadingGuard } from './shared/guard/route/loading/loading-guard.service';

import { isUserExistInBatchGuardService as isUserExistInBatchGuard } from './shared/guard/route/isExistInBatch/isExistInBatch.service';
import { isBatchExpiredGuardService as isBatchExpiredGuard } from './shared/guard/route/isBatchExpired/isBatchExpired.service';

const routes: Routes = [
    { 
        path: '',
        redirectTo: 'home',
        pathMatch: 'full' ,
        canActivate: [LoadingGuard]
    },
    { 
        path: 'home',
        component: HomeComponent,
        canActivate: [LoadingGuard]
    },
    // { path: 'archive', component: ArchiveComponent },
    { 
        path: 'contact',
        component: ContactComponent,
        canActivate: [LoadingGuard]
    },
    { 
        path: 'aboutProficiency',
        component: AboutProficiencyTestComponent,
        canActivate: [LoadingGuard]
    },

    { 
        path: 'signin',
        component: SigninComponent,
        canActivate: [LoadingGuard]
    },
    {
        path: 'registration',
        component: RegistrationFormComponent,
        canActivate: [LoadingGuard]
    },

    {
        path: 'proficiency',
        component: ProficiencyComponent,
        // canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'intro',
                pathMatch: 'full',
                canDeactivate: [LoadingGuard],
            },
            {
                path: 'intro',
                component: IntroComponent,
                canActivate: [AuthGuard],
                canDeactivate: [LoadingGuard],
                data: { state: 'one'}
                //outlet: 'intro',
            },

            {
                path: 'motility',
                component: MotilityCountingComponent,
                canActivate: [AuthGuard, isUserExistInBatchGuard , isBatchExpiredGuard],
                canDeactivate: [LoadingGuard],
                data: { state: 'two'}
                //outlet: 'motility'
            },
            {
                path: 'morphology-challenge/:challangeId',
                component: MorphologyComponent,
                canActivate: [AuthGuard, isUserExistInBatchGuard, isBatchExpiredGuard],
                canDeactivate: [LoadingGuard],
                data: { state: 'three'}
            },
            {
                path: 'concentration',
                component: ConcentrationComponent,
                canActivate: [AuthGuard, isUserExistInBatchGuard, isBatchExpiredGuard],
                canDeactivate: [LoadingGuard],
                data: { state: 'four'}
            }, {
                path: 'finish',
                component: FinalResultsComponent,
                canActivate: [AuthGuard, isUserExistInBatchGuard, isBatchExpiredGuard],
                data: { state: 'five'}
            }
        ]
    }, 

    {
        path: "**",
        component: HomeComponent,
        canActivate: [LoadingGuard]
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteUse,
        }
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }

