import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EmailInfo } from 'src/app/shared/interface/global.interface';
import { EmailInput } from '../interface/email.interface';

@Injectable({ providedIn: 'root' })
export class EmailService {
  private _password: string;
  private _url =
    'https://us-central1-i-pro-7ecfe.cloudfunctions.net/HandleEmailNotification';
  // Default MES logo image url to use as embedded in email html...
  private defaultAttachment = {
    filename: 'iPRO_EmailFooter.jpg',
    path: 'https://firebasestorage.googleapis.com/v0/b/i-pro-7ecfe.appspot.com/o/iPROInstructionDocs%2FiPRO_EmailFooter.jpg?alt=media&token=7e31d79e-ecf1-4ce0-a9d1-187805811b4e',
    cid: 'MailFooter',
  };

  constructor(private _http: HttpClient) {}

  private createVerificationTemplate(password: string) {
    return `
    <body>
        <p>Hello,<br><br>Thank you for registering to our Proficiency test</p>
        <p>Please enter the <span style="font-weight: bold;">verification code</span> below to complete your registration and activate your account:</p>
        <p style="font-size: 150%; font-weight: bold; color:#057f8f;">${password}</p>
        <p>If you didn’t ask to verify an account for the iPro Proficiency Test, you can ignore this email.<br>Thanks,<br><br>iPro Team</p>
        <br><br>
        <img src="cid:MailFooter" style="height: auto; width: 100%; max-width: 600px">
    </body>
    `;
  }

  private createRegCompletedTemplate(details: {
    labName: string;
    regDate: string;
  }) {
    return `
        <!DOCTYPE html>
        <html lang="en">
            <body>
                <p style="text-align: left;font-size: 20px;"><b>  Participating Lab: <u style="width: 0.2em;color: rgb(0, 0, 0);text-decoration-color: rgba(62, 94, 190, 0.373);">${details.labName}</u></b></p>
                <p style="text-align: left;font-size: 15px;">Date of registration: ${details.regDate}</p>
                <p style="text-align: left;font-size: 15px;">We have recived your registration for the</p>
                <p style="text-align: left;font-size: 15px;"><b><u>Proficiency Challenge, powered by Medical Electronic Systems.</u></b></p>
                <p style="text-align: left;font-size: 15px;">This includes: 2x iPRO Challenges, 2x iPRO Test Kits, comprehensive result analysis, and a MES iPRO Certificate of Participation.</p>
                <p style="font-size: 15px;">Please take note of the following next steps:</p>
                <p style="font-size: 15px;"><b>STEP 1: Confirmation of Payment</b><br>&emsp;Once payment is confirmed, you will receive:<ul style="margin:-25px 0;"><li style="font-size: 15px; color: orange"><span style="color: black;">Login information</span></li></ul></p>
                <p style="font-size: 15px;"><b>STEP 2: Receive Your Test Kit</b><br>&emsp;When your iPRO Test Kit is shipped, you will be notified.</p>
                <p style="font-size: 15px;"><b>STEP 3: Complete the iPRO Challenge</b><br>&emsp;Upon receiving your iPRO Test Kit, you may begin the Challenge.<br>&emsp;Remember, you must submit your test results prior to the submission deadline!</p>
                <p style="font-size: 15px;"><b>STEP 4: Confirmation of Submission</b><br>&emsp;You’ll receive confirmation once your final results are submitted.</p>
                <p style="font-size: 15px;"><b>STEP 5: Final Test Results</b><br>&emsp;You’ll receive the results of your test.<br>&emsp;A Certificate of Participation will be sent to you.</p>
                <br><br>
                <p style="font-size: 15px;"><b>Good luck!</b><br>iPro Team</p>
                <br><br>
                <img src="cid:MailFooter" style="height: auto; width: 100%; max-width: 600px">
            </body>
        </html>
    `;
  }

  private createSubmittedTample(labN: string) {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">    
    <body>

      <p style="text-align: left;font-size: 25px;"><b>  Participating Lab: <u style="width: 0.2em;color: rgb(0, 0, 0);text-decoration-color: rgba(62, 94, 190, 0.373);">${labN}</u></b></p>  
      <p>We have received the final results submission of your iPRO Proficiency Test.<br>You will receive your iPRO Results and Score after the submission deadline.</p> 
      <p><b>Please click the button below to complete our User Feedback Survey.</b></p><br><br>
      <table width="100%" cellspacing="0" cellpadding="0">
          <tr>
              <td>
                  <table cellspacing="0" cellpadding="0">
                      <tr>
                          <td style="border-radius: 10px;" bgcolor="#ffa500">
                              <a href="https://mesltd.fra1.qualtrics.com/jfe/form/SV_6YHaKkcGy3nSpkq" target="_blank" style="padding: 15px 18px;border: 2px solid #ffa500;border-radius: 10px;font-family: Helvetica, Arial, sans-serif;font-size: 18px; color: #ffffff;text-decoration: none;font-weight:lighter;display: inline-block;">
                                  iPRO SURVEY            
                              </a>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
      <br><br>
      <img src="cid:MailFooter" style="height: auto; width: 100%; max-width: 600px">
    </body>
    </html>`;
  }
  private generatePassword(length: number) {
    const passwordArr = [];

    while (passwordArr.length !== length) {
      const randomNum = Math.round(Math.random() * 9);
      passwordArr.push(randomNum);
    }

    return passwordArr.join('');
  }

  public checkPassword(password: string) {
    if (!this._password) return false;
    return password === this._password;
  }

  public sendMail(input: EmailInput) {
    let template: string;
    let subject: string;

    switch (input.type) {
      case 'verification':
        this._password = this.generatePassword(6);
        subject = 'Verification Code';
        template = this.createVerificationTemplate(this._password);
        break;
      case 'regComplete':
        subject = 'Registration completed';
        template = this.createRegCompletedTemplate({
          labName: input.labName,
          regDate: input.regDate,
        });
        break;
      case 'submitted':
        subject = 'Test Submitted';
        template = this.createSubmittedTample(input.labName);
        break;
    }

    const data: EmailInfo = {
      emailto: input.emailto,
      subject,
      attachments: input.attachments
        ? input.attachments
        : this.defaultAttachment,
      html: template,
    };

    return this._http.post(this._url, data, { responseType: 'text' });
  }
}
